<div role="document" class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h2 id="prep-file-heading">attacher une facture</h2>
      </div>
      <div class="modal-body">
        <div class="modal-body">
          <form class="col-10" name="userUpdateForm" role="form" novalidate (ngSubmit)="onSubmit()" #f="ngForm"
            [formGroup]="userUpdateForm">
            <div class="form-group row">
              <label class="form-control-label" for="numBill">N° Facture :</label>
              <input type="text" class="form-control" id="numBill" name="numBill"
                [ngClass]="{ 'is-invalid': f.submitted && userUpdateForm?.controls?.numBill?.errors }"
                formControlName="numBill" />
              <div *ngIf="f.submitted && userUpdateForm?.controls?.numBill?.errors " class="text-danger"> Champ
                obligatoire
              </div>
              <div class="m-2">
                <button type="button" id="cancel-save" class="btn btn-secondary" data-dismiss="modal" (click)="close();">
                  &nbsp;<span>Annuler</span>
                </button>
  
                &nbsp;&nbsp;
                <button type="submit" id="save-entity" class="btn btn-primary button titre">
                  &nbsp;<span>Valider</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>