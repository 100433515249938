<app-form-min-vehicule></app-form-min-vehicule>
<div class="d-flex">
  <div class="col-12 my-1">
    <button id="create-variable" class="btn btn-primary float-right" type="submit" (click)="create()">
      <span>
        Ajouter un Vehicule
      </span>
    </button>
  </div>
</div>
<div class="basic-container" style="margin-top: 10px;">
  <table mat-table matSort [dataSource]="lstVehicules" multiTemplateDataRows class="mat-elevation-z8">
    <ng-container matColumnDef="expandIcon">
      <th mat-header-cell *matHeaderCellDef> </th>
      <td mat-cell *matCellDef="let element">
        <mat-icon (click)="expandedElement = expandedElement === element ? null : element">
          {{expandedElement === element ? 'expand_less' : 'expand_more'}}
        </mat-icon>
      </td>
    </ng-container>
    <ng-container matColumnDef="holder">
      <th mat-header-cell *matHeaderCellDef> Propriètaire </th>
      <td mat-cell *matCellDef="let element">
      <span >
          {{element.holderFirstName +' '+element.holderName}}
      </span>
    </td>
    </ng-container>

    <ng-container matColumnDef="immatriculation">
      <th mat-header-cell *matHeaderCellDef> Imatriculation </th>
      <td mat-cell *matCellDef="let element"> {{element.immatriculation}} </td>
    </ng-container>
    <ng-container matColumnDef="label">
      <th mat-header-cell *matHeaderCellDef> Label </th>
      <td mat-cell *matCellDef="let element"> {{element.label}} </td>
    </ng-container>
    <ng-container matColumnDef="desc">
      <th mat-header-cell *matHeaderCellDef> Description </th>
      <td mat-cell *matCellDef="let element"> {{element.desc}} </td>
    </ng-container>

    <ng-container matColumnDef="Actions">
      <th mat-header-cell *matHeaderCellDef> </th>
      <td mat-cell *matCellDef="let element">
        <ng-container *ngIf="element.creationBy">

        <div class="buttons">
          <div class="m-2">
            <span (click)="edit(element)">
              <i class="fa fa-pencil-square-o text-primary" style="cursor: pointer;" aria-hidden="true"
                title="Editer"></i>
            </span>
          </div>
          <div class="m-2">
            <span (click)="openDeleteConfirm(element.id)">
              <i class="fa fa-trash-o text-danger" style="cursor: pointer;" aria-hidden="true" title="Supprimer"></i>
            </span>
          </div>
        </div>
      </ng-container>
      </td>

    </ng-container>

    <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
    <ng-container matColumnDef="expandedDetail">
      <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
        <div class="example-element-detail"
        [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
          <div class="inner-table mat-elevation-z8" *ngIf="expandedElement">
          </div>
        </div>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let element; columns: displayedColumns;" class="example-element-row "
      [class.example-expanded-row]="expandedElement === element"
      (click)="expandedElement = expandedElement === element ? null : element">
    </tr>
  </table>
</div>

