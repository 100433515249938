<div role="document" class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h2 id="prep-file-heading">Modifiez une Camera</h2>
      </div>
  
      <div class="modal-body">
        <div class="modal-body">
          <form class="col-10" name="userUpdateForm" role="form" novalidate (ngSubmit)="onSubmit()"
            #f="ngForm" [formGroup]="userUpdateForm">
            <div class="form-group row">
              <label class="form-control-label" for="label"
                >Label</label>
                <input type="text" class="form-control" id="label" name="label"
                      [ngClass]="{ 'is-invalid': f.submitted && userUpdateForm?.controls?.label?.errors }"
                       formControlName="label" />
                      <div *ngIf="f.submitted && userUpdateForm?.controls?.label?.errors " class="text-danger"> champ
                          obligatoire
                      </div>
            </div>
  
            <div  class="form-group row">
              <label class="form-control-label" for="ip"
                  >Ip :</label>
              <input type="text" class="form-control" id="ip" name="ip"
              [ngClass]="{ 'is-invalid': f.submitted && userUpdateForm?.controls?.ip?.errors }"
               formControlName="ip" />
              <div *ngIf="f.submitted && userUpdateForm?.controls?.ip?.errors " class="text-danger"> champ
                  obligatoire
              </div>
          </div>

          <div  class="form-group row">
              <label class="form-control-label" for="username"
                >Login</label>
              <input type="text" class="form-control" id="username" name="username"
              [ngClass]="{ 'is-invalid': f.submitted && userUpdateForm?.controls?.username?.errors }"
               formControlName="username" />
              <div *ngIf="f.submitted && userUpdateForm?.controls?.username?.errors " class="text-danger"> champ
                obligatoire
            </div>
          </div>

           <div  class="form-group row">

              <label class="form-control-label">Password</label>
              <input type="password" formControlName="pwd" class="form-control"
                  [ngClass]="{ 'is-invalid': f.submitted && userUpdateForm?.controls?.pwd?.errors }" />
              <div *ngIf="f.submitted && userUpdateForm?.controls?.pwd?.errors" class="invalid-feedback">
                  <div *ngIf="f.submitted && userUpdateForm?.controls?.pwd?.errors">champ obligatoire</div>
              </div>

          </div>
          <div class="form-group row align-items-center">
            <div class="custom-control custom-switch">
             <input type="checkbox" class="custom-control-input" id="switch1" formControlName="visible">
             <label class="custom-control-label" for="switch1" >Visible</label>
           </div>
         </div>
  
            <div class="m-2">
              <button type="button" id="cancel-save" class="btn btn-secondary" data-dismiss="modal" (click)="close();">
                &nbsp;<span>Annuler</span>
              </button>
  
              &nbsp;&nbsp;
              <button type="submit" id="save-entity" class="btn btn-primary button titre">
                &nbsp;<span>Valider</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  