import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { AuthGuard } from './Guard/AuthGuard';
import { Role } from './class/role';
import { RessetLoginComponent } from './resset-login/resset-login.component';
import { ChangePassComponent } from './change-pass/change-pass.component';
import { MonProfileComponent } from './mon-profile/mon-profile.component';
import { DigicodsComponent } from './digicods/digicods.component';
import { NotifsDigicodComponent } from './digicods/notifs-digicod/notifs-digicod.component';
import { LogsDigicodComponent } from './digicods/logs-digicod/logs-digicod.component';
import { ReadersComponent } from './readers/readers.component';
import { BadgesComponent } from './badges/badges.component';
import { DigicodCreateComponent } from './digicods/digicod-create/digicod-create.component';
import { DigicodUpdateComponent } from './digicods/digicod-update/digicod-update.component';
import { BadgeCreateComponent } from './badges/badge-create/badge-create.component';
import { BadgeUpdateComponent } from './badges/badge-update/badge-update.component';
import { EquipementCreateComponent } from './equipments/equipement-create/equipement-create.component';
import { EquipementUpdateComponent } from './equipments/equipement-update/equipement-update.component';
import { ManageEquipementComponent } from './equipments/manage-equipement/manage-equipement.component';
import { EquipmentsComponent } from './equipments/equipments.component';
import { AccessGenerationComponent } from './access-generation/access-generation.component';
import { AccesLogComponent } from './services/acces-log/acces-log.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { ManageAccessComponent } from './manage-access/manage-access.component';
import { CheckAccessComponent } from './manage-access/check-access/check-access.component';
import { NotificationUpdateComponent } from './notifications/notification-update/notification-update.component';
import { NotificationCreateComponent } from './notifications/notification-create/notification-create.component';
import { AddCardModalComponent } from './credit-card-form/add-card-modal/add-card-modal.component';
import { UserComponent } from './user/user.component';
import { TenantsComponent } from './tenants/tenants.component';
import { InterphoneComponent } from './interphone/interphone.component';
import { InterphoneCreateComponent } from './interphone/interphone-create/interphone-create.component';
import { InterphoneUpdateComponent } from './interphone/interphone-update/interphone-update.component';
import { CreateTenantComponent } from './tenants/create-tenant/create-tenant.component';
import { UpdateTentantComponent } from './tenants/update-tenant/update-tentant.component';
import { InstallatationComponent } from './installatation/installatation.component';
import { VehiculesComponent } from './vehicules/vehicules.component';
import { CamerasComponent } from './cameras/cameras.component';
import { DeleveryNoteComponent } from './delevery-note/delevery-note.component';
import { MapComponent } from './map/map.component';


const routes: Routes = [
  {
    path: 'home', component: HomeComponent,
    canActivate: [AuthGuard]
  },
  { path: 'login', component: LoginComponent },

  {
    path: 'profil', component: MonProfileComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'interphones', component: InterphoneComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Interphone.toString()] }
  },
  {
    path: 'create-ineterphone', component: InterphoneCreateComponent
  },
  {
    path: 'update-ineterphone', component: InterphoneUpdateComponent
  },
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full'
  },
  {
    path: 'loginResset', component: RessetLoginComponent
  },
  {
    path: 'loginChange', component: ChangePassComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'digicods', component: DigicodsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'create-digicod', component: DigicodCreateComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'update-digicod', component: DigicodUpdateComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'notifsDigicod', component: NotifsDigicodComponent,
    canActivate:[AuthGuard]
  },
  {
    path: 'logsDigicod', component: LogsDigicodComponent,
    canActivate:[AuthGuard]
  },
  {
    path: 'readers', component: ReadersComponent,
    canActivate:[AuthGuard]

  },
  {
    path: 'badges', component: BadgesComponent,
    canActivate:[AuthGuard]

  },
  {
    path: 'create-badge', component: BadgeCreateComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'update-badge', component: BadgeUpdateComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'equipements', component: EquipmentsComponent,
    canActivate:[AuthGuard]

  },
  {
    path: 'create-equipement', component: EquipementCreateComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'update-equipement', component: EquipementUpdateComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'manageEquipement', component: ManageEquipementComponent,
    canActivate:[AuthGuard]
  },
  {
    path: 'generate', component:  AccessGenerationComponent,
    canActivate:[AuthGuard]
  },
  {
    path: 'vehicules', component:  VehiculesComponent,
    canActivate:[AuthGuard]
  },
  {
    path: 'cameras', component:  CamerasComponent,
    canActivate:[AuthGuard]
  },
  {
    path: 'notes', component:  DeleveryNoteComponent,
    canActivate:[AuthGuard]
  },
  {
    path: 'accessLogs/:id',
    component: AccesLogComponent
    ,canLoad: [AuthGuard]
  },
  {
    path: 'accessLogs', component:  AccesLogComponent,
    canActivate:[AuthGuard]
  },
  {
    path: 'add-card', component: AddCardModalComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'notifications',
    component: NotificationsComponent
    ,canLoad: [AuthGuard]
  },
  {
    path: 'update-notification', component: NotificationUpdateComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'create-notification', component: NotificationCreateComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'installations', component: InstallatationComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'editAccess',
    component: ManageAccessComponent
    ,canLoad: [AuthGuard]
  },
  {
    path: 'map',
    component: MapComponent
    ,canLoad: [AuthGuard]
  },
  {
    path: 'tenants', component: TenantsComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.SupperAdmin.toString()] }
  },
  {
    path: 'create-tenant', component: CreateTenantComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.SupperAdmin.toString()] }
  },
  {
    path: 'update-tenant', component: UpdateTentantComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.SupperAdmin.toString()] }
  },
  {
    path: 'users', component: UserComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Admin.toString(),Role.SupperAdmin.toString()] }
  },
  {
    path: 'checkAccess',
    component: CheckAccessComponent
    ,canLoad: [AuthGuard]
  },
  {
    path: 'notifications/:id',
    component: NotificationsComponent
    ,canLoad: [AuthGuard]
  },
  { path: '**', component: LoginComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
