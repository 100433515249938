import { HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { AlertService } from 'src/app/services/alert-service.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { DigiCodService } from 'src/app/services/digicod.service';
import { EquipmentService } from 'src/app/services/equipement.service';
import { FormChangeService } from 'src/app/services/form-change.service';
import { UserService } from 'src/app/services/user-service';

@Component({
  selector: 'app-equipement-update',
  templateUrl: './equipement-update.component.html',
  styleUrls: ['./equipement-update.component.scss']
})
export class EquipementUpdateComponent implements OnInit {

  equipementUpdateForm: FormGroup;
  submitted = false;
  userForm: any;
  digicode: any;
  userIds: any;
  constructor(
    protected equipementServ: EquipmentService,
    private fb: FormBuilder,
    protected alertService: AlertService,
    private formService: FormChangeService,
    public authenSer: AuthenticationService,
    public activeModal: NgbActiveModal,
    private userServ: UserService) { }

  ngOnInit(): void {
    this.initForm();
    this.userServ.getUser().subscribe(
      data =>{
        this.userIds = data;
        debugger;
        this.userIds.forEach(element => {
          element['fullName'] = element.firstname + ' ' + element.name;
        });
      }
    );
    
    this.updateForm(this.digicode);
  }

  initForm() {
    this.equipementUpdateForm = this.fb.group({
      id: [],
      label: [],
      active: [],
      gatewayId: [],
      accessDuration: [],
      status: [],
      userIds: [],
      latitude: [],
      longitude: [],
    });

  }

  get f() { return this.equipementUpdateForm.controls; }

  private createFromForm(): any {
    return {
      label: this.equipementUpdateForm.get(['label']).value,
      active: this.equipementUpdateForm.get(['active']).value,
      gatewayId: this.digicode.gatewayId,
      id: this.digicode.id,
      accessDuration: this.equipementUpdateForm.get(['accessDuration']).value,
      userIds: this.equipementUpdateForm.get(['userIds']).value,
      latitude: this.equipementUpdateForm.get(['latitude']).value,
      longitude: this.equipementUpdateForm.get(['longitude']).value,
    };
  }
  updateForm(digic: any): void {
    this.equipementUpdateForm.patchValue({
      id: digic.id,
      label: digic.label,
      active: digic.active,
      gatewayId: digic.gatewayId,
      accessDuration: digic.accessDuration,
      userIds: digic.userIds,
      latitude: digic.latitude,
      longitude: digic.longitude,
    });
    debugger;
  }


  onSubmit(): void {

      this.userForm = this.createFromForm();
      this.subscribeToSaveResponse(this.equipementServ.saveEquipment(this.userForm));

  }


  close(): void {
    this.activeModal.close();
  }

  protected subscribeToSaveResponse(result: Observable<string>): void {
    result.subscribe(
      (result) => this.onSaveSuccess(result),
      (result) => this.onSaveError(result)
    );
  }



  protected onSaveSuccess(result): void {
    this.alertService.success('Lecteur sauvegardé !', true);
    this.formService.raiseReloadEquipementsSearchRequestEvent();
    this.close();
  }

  protected onSaveError(result): void {
    this.alertService.error('Erreur lors de la modification lecteur !', true);
  }
}
