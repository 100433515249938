<div class="wrapperDiv">
    <div class="col-md-6 col-10 login-form">
      <form name="form" [formGroup]="loginForm" (ngSubmit)="f.form.valid && login()" #f="ngForm" >
      <div class="avatar" style="text-align: center;">
        <i class="fa fa-4x fa-user-circle" aria-hidden="true"></i>
      </div>
          <div class="form-group titre center" >S'identifier</div>
          <div class="form-group titre">
            <input
            class="form-control"
            [ngClass] = "{ 'forminput-invalid':f.submitted &&
            loginForm['controls']?.username?.errors != null}" formControlName="username" placeholder="User" required>
            <div *ngIf="f.submitted && loginForm['controls']?.username?.errors != null" class="text-danger" >Champ obligatoire</div>
          </div>
            <div class="form-group titre">
              <input type="password"
              class="form-control "
              [ngClass] = "{ 'forminput-invalid':f.submitted &&
              loginForm['controls']?.password?.errors != null}"
            [(ngModel)]="model.password" required formControlName="password"  placeholder="Mot de passe">
              <div *ngIf="f.submitted && loginForm['controls']?.password?.errors != null" class="text-danger" >Champ obligatoire</div>
          </div>
          <div class="text-center">
            <div class="text-center titre"></div>
               <button  type="submit" class="btn btn-primary titre">Se connecter</button><br/><br/>
               <a [routerLink]="['/loginResset']" >
                <button class="btn btn-info titre">Mot de passe oublié ?</button></a>
          </div>

      </form>
    </div>
  </div>
