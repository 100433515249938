import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalRASComponent } from '../modal/modalRAS.component';
import { AlertService } from '../services/alert-service.service';
import { AuthenticationService } from '../services/authentication.service';
import { FormChangeService } from '../services/form-change.service';
import { VehiculeService } from '../services/vehicule.service';
import { CameraCreateComponent } from './camera-create/camera-create.component';
import { CameraStreamComponent } from './camera-stream/camera-stream.component';
import { CameraUpdateComponent } from './camera-update/camera-update.component';

@Component({
  selector: 'app-cameras',
  templateUrl: './cameras.component.html',
  styleUrls: ['./cameras.component.scss']
})
export class CamerasComponent implements OnInit {
  lstVehicules = new MatTableDataSource(new Array());
  displayedColumns = ['expandIcon','label','ip','status','visible','Actions'
  ];
  expandedElement: {} | null;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(private vehiculeService: VehiculeService,
    public alertService: AlertService,
    private formService: FormChangeService,
    public authenSer: AuthenticationService,
    private modalService: NgbModal,
    private router: Router) { }

  ngOnInit() {
    this.subscribeformChange();
    this.lstVehicules.sort = this.sort;
  }
  subscribeformChange() {
    this.formService.formCamerasSearchChanged.subscribe(
      (param) => {
        this.lstVehicules = new MatTableDataSource(param);

      });
  }

  create(): void {
    const modalRef = this.modalService.open(CameraCreateComponent, { backdrop: 'static' });
  }
  edit(veh) {
    const modalRef = this.modalService.open(CameraUpdateComponent, { backdrop: 'static' });
    modalRef.componentInstance.vehicule = veh;
  }
  stream(cam){
    const modalRef = this.modalService.open(CameraStreamComponent, { backdrop: 'static' });
    modalRef.componentInstance.camera = cam;
  }
  openDeleteConfirm(id): void {
    const modalRef = this.modalService.open(ModalRASComponent, { backdrop: 'static' });
    modalRef.componentInstance.title = "Suppresion d'une Camera";
    modalRef.componentInstance.message = "Confirmer la suppression ?";
    modalRef.componentInstance.withCancel = true;
    modalRef.result.then(result => {
      if (result == true) {
        this.delete(id);
      }
    });
  }
  delete(id) {
    this.vehiculeService.delete(id).subscribe(
      () => this.onDeleteSuccess(),
      () => this.onDeleteError()
    );

  }
  protected onDeleteSuccess(): void {
    this.alertService.success('Suppression effectuée.', false);
    this.formService.raiseReloadCamerasSearchRequestEvent();
  }


  protected onDeleteError(): void {
    this.alertService.warn('Erreur de suppression.', false);
  }

  onChange() {
    this.formService.raiseReloadCamerasSearchRequestEvent();
  }

}
