
<h2 id="page-heading">
  <span>Historique d'accès : </span>
</h2>
<div class="searchForm flex-row" #myForm id="formMinAnnonce">
    <div class="flex-row">
        <div class="form-row">
            <div class="col-5">
                <label class="sr-only" for="text">Rechercher</label>
                <input (input)="onFormChange();" type="text" [(ngModel)]="searchText" class="form-control mb-2" id="text"
                    placeholder="Rechercher ">
            </div>
          <mat-form-field class="form-control col-3  mb-2" >
            <mat-date-range-input  [formGroup]="dateRange" [rangePicker]="picker" >
              <input matStartDate formControlName="start" placeholder="Date début" (dateChange)="onFormChange()">
              <input matEndDate formControlName="end" placeholder="Date fin" (dateChange)="onFormChange()">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
          
            <mat-error *ngIf="dateRange.controls.start.hasError('matStartDateInvalid')">date début invalide</mat-error>
            <mat-error *ngIf="dateRange.controls.end.hasError('matEndDateInvalid')">date fin invalide</mat-error>
          </mat-form-field>
          <div class="col-3">
            <select class="custom-select" [(ngModel)]="typeFilter" (ngModelChange)="onFormChange()">
              <option *ngFor=" let typeCnt of typeEvent" [ngValue]="typeCnt.code" >
                {{typeCnt.name}}
              </option>
            </select>
          </div>
        </div>
        <div class="d-flex">
          <div class="col-12 my-1">
            <button id="create-variable" class="btn btn-primary float-right" type="submit" (click)="export()">
              <span>
                Exporter
              </span>
            </button>
          </div>
        </div>
    </div>
</div>
