import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription, timer } from 'rxjs';
import { map } from 'rxjs/operators';
import { CameraCreateComponent } from '../cameras/camera-create/camera-create.component';
import { CameraUpdateComponent } from '../cameras/camera-update/camera-update.component';
import { ModalRASComponent } from '../modal/modalRAS.component';
import { AlertService } from '../services/alert-service.service';
import { AuthenticationService } from '../services/authentication.service';
import { DeleveryNoteService } from '../services/delevery-note.service';
import { FormChangeService } from '../services/form-change.service';
import { VehiculeService } from '../services/vehicule.service';
import { DeleveryNoteCreateComponent } from './delevery-note-create/delevery-note-create.component';
import { DeleveryNoteFactureComponent } from './delevery-note-facture/delevery-note-facture.component';
import { DeleveryNoteUpdateComponent } from './delevery-note-update/delevery-note-update.component';
export interface GroupBy {
  name: string;
  isGroupBy: boolean;
}
export interface DelevryNote {
  name: string;
  desc: string;
  company: string;
  numBill: string;
  immatriculation: string; 
}
@Component({
  selector: 'app-delevery-note',
  templateUrl: './delevery-note.component.html',
  styleUrls: ['./delevery-note.component.scss']
})

export class DeleveryNoteComponent implements OnInit {
  lstVehicules = new MatTableDataSource(new Array<(DelevryNote | GroupBy)>());
  lstNoteAtraiter = new MatTableDataSource(new Array());
  lstNoteTraiter = new MatTableDataSource(new Array());
  displayedColumns = ['name','desc','company', 'numBill','immatriculation','Actions'];
  expandedElement: {} | null;
  timerSubscription: Subscription; 
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(private delevryService: DeleveryNoteService,
    public alertService: AlertService,
    private formService: FormChangeService,
    public authenSer: AuthenticationService,
    private modalService: NgbModal,
    private router: Router) { }

  ngOnInit() {
    
    // timer(0, 10000) call the function immediately and every 30 seconds 
    this.timerSubscription = timer(0, 30000).pipe( 
      map(() => { 
        this.subscribeformChange();; // load data contains the http request 
      }) 
    ).subscribe(); 

    this.lstVehicules.sort = this.sort;
  }
  ngOnDestroy(): void { 
    this.timerSubscription.unsubscribe(); 
  } 
  subscribeformChange() {
    this.formService.formDelevryNoteSearchChanged.subscribe(
      (param) => {
        let tmp = param as Array<any>;
        let finalList= new Array<any>();
        tmp = param;
        let traite = tmp.filter(el => el['numBill'] != null);
        let atraite = tmp.filter(el => el['numBill'] == null);
        
        if(atraite.length > 0){
          finalList.push({ groupName: 'A traiter', isGroupBy: true});
          finalList = finalList.concat(atraite);
        }

        if(traite.length > 0){
          finalList.push({ groupName: 'Traité', isGroupBy: true});
          finalList =  finalList.concat(traite);
        }
        this.lstVehicules = new MatTableDataSource(finalList);

      });
  }
  isGroup(index, item): boolean{
    return item && item.isGroupBy;
  }
  create(): void {
    const modalRef = this.modalService.open(DeleveryNoteCreateComponent, { backdrop: 'static' });
  }
  edit(veh) {
    const modalRef = this.modalService.open(DeleveryNoteUpdateComponent, { backdrop: 'static' });
    modalRef.componentInstance.vehicule = veh;
  }
  invoice(ele){
    const modalRef = this.modalService.open(DeleveryNoteFactureComponent, { backdrop: 'static' });
    modalRef.componentInstance.delevryNote = ele;
  }
  openDeleteConfirm(id): void {
    const modalRef = this.modalService.open(ModalRASComponent, { backdrop: 'static' });
    modalRef.componentInstance.title = "Suppresion d'une Camera";
    modalRef.componentInstance.message = "Confirmer la suppression ?";
    modalRef.componentInstance.withCancel = true;
    modalRef.result.then(result => {
      if (result == true) {
        this.delete(id);
      }
    });
  }
  delete(id) {
    this.delevryService.delete(id).subscribe(
      () => this.onDeleteSuccess(),
      () => this.onDeleteError()
    );

  }
  protected onDeleteSuccess(): void {
    this.alertService.success('Suppression effectuée.', false);
    this.formService.raiseReloadDelevryNoteSearchRequestEvent();
  }


  protected onDeleteError(): void {
    this.alertService.warn('Erreur de suppression.', false);
  }

  onChange() {
    this.formService.raiseReloadDelevryNoteSearchRequestEvent();
  }

}
