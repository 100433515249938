import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, NgModel } from '@angular/forms';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { AlertService } from 'src/app/services/alert-service.service';
import { BadgeService } from 'src/app/services/badge.service';
import { FormChangeService } from 'src/app/services/form-change.service';
import { VehiculeService } from 'src/app/services/vehicule.service';

@Component({
  selector: 'app-form-min-vehicule',
  templateUrl: './form-min-vehicule.component.html',
  styleUrls: ['./form-min-vehicule.component.scss']
})
export class FormMinVehiculeComponent implements OnInit {
  dateRange = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });
  
  sites: {};
  selectedCustomer: string;
  prospects;
  selectedSite: any[];

  selectAll: boolean;
  searchText: string;
  searchTextCust: string;
  newSearched = true;
  numeroMin: number;
  numeroMax: number;
  model: NgModel;
  countAnnonces;
  @Input() withPeriod: boolean;
  period: any;
  selectedPeriod: string;
  periodList: any[];
  constructor(
    private alertService: AlertService,
    private vehiculeServ: VehiculeService,
    private formService: FormChangeService,
    public router: Router) {
  }


  ngOnInit() {
    this.rechercher();
    this.subscribeReloadSearchRequest();
  }

  subscribeReloadSearchRequest() {
    this.formService.reloadVehiculesRequest.subscribe(
      () => {
        this.rechercher();
      });
  }
  // efface le formulaire de rechercher appelé depuis navBar
  clearForm() {
    this.rechercher();
  }
  private findTextInProspect(prospects) {
    let filter = this.getTextFilter();
    let prospectFiltred = prospects;
    if (filter) {
      let text = filter.split(' ');
      prospectFiltred = prospects.filter(function (item) {
        let flt = text.every(function (el) {
          let tmp = (item['label'] && item['label'].toUpperCase().indexOf(el.toUpperCase()) > -1)
            || (item['description'] && item['description'].toUpperCase().indexOf(el.toUpperCase()) > -1)
            || (item['immatriculation'] && item['immatriculation'].toUpperCase().indexOf(el.toUpperCase()) > -1);
            return tmp;
        });

        return flt;
      });

    }
    prospectFiltred = this.filterPrspectDateRange(prospectFiltred);
    return prospectFiltred;
  }
  filterPrspectDateRange(prospectFiltred: Array<any>): any {
    let tmp = prospectFiltred;
    if(this.dateRange.get('start').value){
      let start = moment(this.dateRange.get('start').value).startOf('day');
        tmp = tmp.filter(element => {
          let dateCreation = element.dateCreation?moment(element.dateCreation): null;
          return dateCreation && dateCreation.startOf('day').isSameOrAfter(start);
        });
      }
      if(this.dateRange.get('end').value){
        let end = moment(this.dateRange.get('end').value).startOf('day');
          tmp = tmp.filter(element => {
            let dateCreation = element.dateCreation?moment(element.dateCreation): null;
            return dateCreation && dateCreation.startOf('day').isSameOrBefore(end);
          });
      }
    return tmp;
  }

  private getTextFilter() {
    return this.searchText;
  }

rechercher() {
    this.getVehiculesFromBackEnd()
      .subscribe(
        (data) => {
          let tmpUsers = data as Array<any>;
           tmpUsers = this.findTextInProspect(tmpUsers);
          this.formService.raiseVehiculesListEvent(tmpUsers);
        }
        , (error) => {
          console.log(error);
          this.formService.raiseVehiculesListEvent([]);
        });
  }

  onFormChange() {
    this.rechercher();
  }

  getVehiculesFromBackEnd() {
    return this.vehiculeServ.getVehicules();
  }

}


