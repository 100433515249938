import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, NgModel } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertService } from 'src/app/services/alert-service.service';
import { FormChangeService } from 'src/app/services/form-change.service';
import { ReaderService } from 'src/app/services/reader.service';
import * as moment from 'moment';
import { InterphoneService } from '../interphone.service';

@Component({
  selector: 'app-form-min-interphone',
  templateUrl: './form-min-interphone.component.html',
  styleUrls: ['./form-min-interphone.component.scss']
})
export class FormMinInterphoneComponent implements OnInit {

  dateRange = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });
  searchText: string;
  model: NgModel;
  constructor(
    private alertService: AlertService,
    private interphoneSrv: InterphoneService,
    private formService: FormChangeService,
    public router: Router) {
  }


  ngOnInit() {
    this.rechercher();
    this.subscribeReloadSearchRequest();
  }

  subscribeReloadSearchRequest() {
    this.formService.reloadInterphonesRequest.subscribe(
      () => {

        this.rechercher();
      });
  }
  // efface le formulaire de rechercher appelé depuis navBar
  clearForm() {
    this.rechercher();
  }
  private findTextInProspect(prospects) {
    let filter = this.getTextFilter();
    let resultFiltred = prospects;
    if (filter) {
      let text = filter.split(' ');
      resultFiltred = prospects.filter(function (item) {
        let flt = text.every(function (el) {
          let tmp = (item['label'] && item['label'].toUpperCase().indexOf(el.toUpperCase()) > -1)
            return tmp;
        });

        return flt;
      });

    }
    // resultFiltred = this.filterDigicodDateRange(resultFiltred);
    return resultFiltred;
  }
  filterDigicodDateRange(prospectFiltred: Array<any>): any {
    let tmp = prospectFiltred;
    return tmp;
  }

  private getTextFilter() {
    return this.searchText;
  }

rechercher() {
    this.getInterphonesFromBackEnd()
      .subscribe(
        (data) => {
          let tmpRes = data as Array<any>;
           tmpRes = this.findTextInProspect(tmpRes);

          this.formService.raiseInterphonesListEvent(tmpRes);
        }
        , (error) => {
          console.log(error);
          this.formService.raiseInterphonesListEvent([]);
        });
  }

  onFormChange() {
    this.rechercher();
  }

  getInterphonesFromBackEnd() {
    return this.interphoneSrv.getInterphoneFromBack();
  }
}
