import { Component, ElementRef, Input, NgZone, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import {} from 'googlemaps';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MapService } from '../services/map.service';
@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit {
  constructor(
     private ngZone: NgZone,
      private route: Router,
      private mapServ: MapService) {

  }
  icons = {
    place: {
      icon_camera: '../../assets/camera_full_green.png',
      icon_door: '../../assets/ic_open_door.png',
    },
  };
  placeMarkers = new Array<google.maps.Marker>();
  @ViewChild('map', { static: false }) mapElement: any;
  @ViewChild('search',  { static: false })
  public searchElementRef: ElementRef;
  map: google.maps.Map;
  _destroyed = new Subject();
  mapCenter ;
  lstResources = new Array<any>();

  // getPlaceIcon(place) {
  //   if(place.busy){
  //     const deadline =  moment(place['dateLimitBusy']);
  //     let before = moment(deadline).isBefore(moment());
  //     if(before){//Red
  //       return this.icons.place.icon_red;
  //     } else {//Green
  //       return this.icons.place.icon_green;
  //     }
  //   } else {
  //     return this.icons.place.icon_libre;
  //   }
  // }
  ngOnInit() {
    this.mapServ.getResources()
    .pipe(takeUntil(this._destroyed))
    .subscribe((data) => {
      this.lstResources = (data['cameras']  as Array<any>).filter(cam => cam.longitude && cam.latitude);

      if (this.lstResources) {
        this.lstResources.forEach((el, index) => {
          el.active = false;
          el.type= 'Cam';
          if (index === 0) {
              this.mapCenter = new google.maps.LatLng(el.latitude, el.longitude);
          }

          this.placeMarkers.push( new google.maps.Marker({
            icon: {
              url:this.icons.place.icon_camera,
              scaledSize: new google.maps.Size(20, 20)},
              clickable: true,
              position: { lat: el.latitude, lng: el.longitude },
              title: 'Camera',
          }));
        });

      }
      let lstEquipements = (data['equipments']  as Array<any>).filter(eq => eq.longitude && eq.latitude);
      if (lstEquipements) {
        lstEquipements.forEach((el, index) => {
          el.active = false;
          el.type= 'Equ';
          this.lstResources.push(el);
          if (el.latitude && el.longitude ){
            let marker = new google.maps.Marker({
              position: { lat: el.latitude, lng: el.longitude },
              icon: {
                url:this.icons.place.icon_camera,
                scaledSize: new google.maps.Size(25, 25)},
                title: el.label,
            });
            this.placeMarkers.push( marker);
            // if (this.agent && el.id == this.agent.id) {
              this.mapCenter = new google.maps.LatLng(el.latitude, el.longitude);
            // }
          }
        });
      }
      this.initMap();
    });

  }
  initMap() {
    this.map = new google.maps.Map(
      this.mapElement.nativeElement,
      {
        center: this.mapCenter,
        zoom: 15,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
      }
    );
    this.placeMarkers.forEach(
      (marker, index) => {
        marker.setMap(this.map);
        // if(marker.getIcon() != this.icons.agent.icon) {
          let infoWindow = new google.maps.InfoWindow();
          marker.addListener('click',((tempMarker, map, infoWindow) => {
          return () => {
            let popup = "<div> ";
            popup += ""
            popup +=""
            popup += " </div>"
            infoWindow.setContent('<p><b>' + this.lstResources[index].label + '</b>');
            infoWindow.open(map, tempMarker);
            }
          })(marker, this.map, infoWindow));
        // }
      }
    );

    const autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
    autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          const place: google.maps.places.PlaceResult = autocomplete.getPlace();
          if (place.geometry === undefined || place.geometry === null) {
            return;
          } else {
            this.map.panTo(
              new google.maps.LatLng(
                place.geometry.location.lat(),
                place.geometry.location.lng()
              )
            );
          }
        });
      });
  }
  centerMapTo(place) {
    this.lstResources.forEach((el, index) => {
      if(el == place){
        el.active = true ;
      } else {
        el.active =  false ;
      }
    })
    place.active = !place.active ;
    if(place.latitude && place.longitude ) {
        this.map.panTo(
          new google.maps.LatLng(
            place.latitude,
            place.longitude
          )
        );
      }
  }

}