import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { AlertService } from 'src/app/services/alert-service.service';
import { FormChangeService } from 'src/app/services/form-change.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { User, IUser } from 'src/app/class/user';
import { UserService } from 'src/app/services/user-service';
import { TenantService } from 'src/app/services/tenant.service';
import { roleEnum } from 'src/app/class/unit.enum';
import { Role } from 'src/app/class/role';

@Component({
  selector: 'app-user-create',
  templateUrl: './user-create.component.html',
  styleUrls: ['./user-create.component.scss']
})
export class UserCreateComponent implements OnInit {
  userCreateForm: FormGroup;
  selectedCivilite: string = null;
  emailInvalid: boolean;
  userForm: any;
  roles = new Array();
  selectedRoles = new Array();
  tenants: Object;
  constructor(
    protected userService: UserService,
    private fb: FormBuilder,
    private tenantServ: TenantService,
    protected alertService: AlertService,
    private formService: FormChangeService,
    public authenSer: AuthenticationService,
    public activeModal: NgbActiveModal) {

      Object.keys(Role).forEach(rl =>{
        this.roles.push(Role[rl]);
      });

     }

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.userCreateForm = this.fb.group({
      tenantId: [],
      tel1: [],
      tel2: [],
      firstname: ['', Validators.required],
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
    });
    if (!this.authenSer.isAdmin){
      this.userCreateForm.addControl(
        'login', new FormControl("", [Validators.required])
        );

    }
    this.userCreateForm.addControl(
      'web', new FormControl("")
      );
      this.userCreateForm.addControl(
        'mobile', new FormControl("")
        );
    this.rechercher();
  }
  // convenience getter for easy access to form fields
  get f() { return this.userCreateForm.controls; }



  private createFromForm() {
    let tmp = {
      tenantId: this.authenSer.isSupperAdmin?this.userCreateForm.get(['tenantId']).value:null,
      tel1: this.userCreateForm.get(['tel1'])!.value,
      tel2: this.userCreateForm.get(['tel2'])!.value,
      email: this.userCreateForm.get(['email'])!.value,
      firstname: this.userCreateForm.get(['firstname'])!.value,
      name: this.userCreateForm.get(['name'])!.value,
      roles: this.selectedRoles,
    };
    if (!this.authenSer.isAdmin) {
      tmp['login'] = this.userCreateForm.get(["login"])!.value;
    }
    if (!this.authenSer.isSupperAdmin) {
      let web = this.userCreateForm.get(["web"])!.value;
      if (web && !tmp['roles'].includes(Role.Web.toString())){
        tmp['roles'].push('ROLE_WEB');
      }
      if (!web && tmp['roles'].includes(Role.Web.toString())){
        tmp['roles'] = tmp['roles'].filter( r => r != Role.Web.toString());
      }
      let mobile = this.userCreateForm.get(["mobile"])!.value;
      if (mobile && !tmp['roles'].includes(Role.Mobile.toString())){
        tmp['roles'].push('ROLE_MOBILE');
      }
      if (!mobile && tmp['roles'].includes(Role.Mobile.toString())){
        tmp['roles'] = tmp['roles'].filter( r => r != Role.Mobile.toString());
      }
    }
    return tmp;
  }

  onSubmit(): void {
    if (this.userCreateForm.invalid) {
      return;
    } else {
      this.userForm = this.createFromForm();

      this.subscribeToSaveResponse(this.userService.saveUser(this.userForm));
    }
  }

  rechercher() {
    if(this.authenSer.isSupperAdmin){
      this.getTenantsFromBackEnd()
      .subscribe(
        data => {
          this.tenants = data;
        }
        , error => {
          console.log(error);
        });
    }
  }

  getTenantsFromBackEnd() {
    return this.tenantServ.getTenants();
  }

  close(): void {
    this.activeModal.close();
  }

  protected subscribeToSaveResponse(result: Observable<HttpResponse<any>>): void {
    result.subscribe(
      (result) => this.onSaveSuccess(result),
      (result) => this.onSaveError(result)
    );
  }



  protected onSaveSuccess(result): void {
    this.alertService.success('Utilisateur sauvegardé !', true);
    this.formService.raisereloadSearchRequestUserEvent();
    this.close();
  }

  protected onSaveError(result): void {
    this.alertService.error('Erreur dans le formulaire !', true);
  }
}
