
<h2 id="page-heading">
  <span>Utilisateurs</span>
</h2>
<div class="searchForm flex-row" #myForm id="formMinAnnonce">
    <div class="flex-row">
        <div class="form-row">
            <div class="col-md-6 col-6">
                <label class="sr-only" for="text">Rechercher</label>
                <input (input)="onFormChange();" type="text" [(ngModel)]="searchText" class="form-control mb-2" id="text"
                    placeholder="Rechercher ">
            </div>
            <div *ngIf="this.authenSer.isAdmin" class="col-md-6 col-6">
                <ng-select (change)="onFormChange();" class="mb-2" [ngModelOptions]="{standalone: true}"
                    dropdownPosition='bottom' placeholder="Client" [items]="customers" [multiple]="false"
                    [bindLabel]="'companyName'" [closeOnSelect]="true" bindValue="id" name="selectedCustomer"
                    [(ngModel)]="selectedCustomer">
                    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                        <input id="item-{{index}}" [ngModel]="item$.selected" type="checkbox" /> {{item.companyName}}
                    </ng-template>
                </ng-select>
            </div>
        </div>
    </div>
</div>
