<app-form-min-manage-access ></app-form-min-manage-access>
<!-- <div class="d-flex">
  <div class="col-12 my-1">
    <button id="create-variable" class="btn btn-primary float-right" type="submit" (click)="export()">
      <span>
        Exporter
      </span>
    </button>
  </div>
</div> -->
<div class="basic-container" style="margin-top: 10px;">
  <table mat-table matSort [dataSource]="accessLogs" multiTemplateDataRows class="mat-elevation-z8">
    <ng-container matColumnDef="Actions">
      <th mat-header-cell *matHeaderCellDef> </th>
      <td mat-cell *matCellDef="let element">
        <ng-container >
        <div class="buttons">
          <div class="m-2">
            <span (click)="openDeleteConfirm(element.id)">
              <i class="fa fa-trash-o text-danger" style="cursor: pointer;" aria-hidden="true" title="Supprimer"></i>
            </span>
          </div>
        </div>
      </ng-container>
      </td>

    </ng-container>
    <ng-container matColumnDef="accessCode">
      <th mat-header-cell *matHeaderCellDef> Code </th>
      <td mat-cell *matCellDef="let element">
      <span >
          {{element.accessCode}}
      </span>
    </td>
    </ng-container>

    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef> évenement </th>
      <td mat-cell *matCellDef="let element">
      <span >
          {{typeEventEnum[element.type]}}
      </span>
    </td>
    </ng-container>
    <ng-container matColumnDef="readerId">
      <th mat-header-cell *matHeaderCellDef> Accès </th>
      <td mat-cell *matCellDef="let element"> {{element.readerId}} </td>
    </ng-container>
    <ng-container matColumnDef="reference">
      <th mat-header-cell *matHeaderCellDef> Réf </th>
      <td mat-cell *matCellDef="let element"> {{element.reference}} </td>
    </ng-container>
    <ng-container matColumnDef="digicodeLabels">
      <th mat-header-cell *matHeaderCellDef> Digicode </th>
      <td mat-cell *matCellDef="let element">
        <span *ngFor="let digic of element.digicodeLabels">{{digic }}</span>
       </td>
    </ng-container>
    <ng-container matColumnDef="equipmentLabels">
      <th mat-header-cell *matHeaderCellDef> Ouverture </th>
      <td mat-cell *matCellDef="let element">
        <span *ngFor="let equip of element.equipmentLabels">{{equip }}</span>
       </td>
    </ng-container>
    <!-- <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef> Type </th>
      <td mat-cell *matCellDef="let element"> {{element.type}} </td>
    </ng-container> -->
    <ng-container matColumnDef="date_creation">
      <th mat-header-cell *matHeaderCellDef> Créé </th>
      <td mat-cell *matCellDef="let element">
      <span >
          {{element.dateCreation| momentPipe:'DD/MM/YYYY HH:mm'}}
      </span>
    </td>
    </ng-container>
    <ng-container matColumnDef="lastConsumedDate">
      <th mat-header-cell *matHeaderCellDef> Utilisé </th>
      <td mat-cell *matCellDef="let element"> {{element.lastConsumedDate| momentPipe:'DD/MM/YYYY HH:mm'}} </td>
    </ng-container>
    <ng-container matColumnDef="uniqueUsage">
      <th mat-header-cell *matHeaderCellDef> Usage </th>
      <td mat-cell *matCellDef="let element"> {{element.uniqueUsage?'Unique':'Illimité'}} </td>
    </ng-container>
    <ng-container matColumnDef="dateStartEffect">
      <th mat-header-cell *matHeaderCellDef> Date debut </th>
      <td mat-cell *matCellDef="let element"> {{element.dateStartEffect| momentPipe:'DD/MM/YYYY HH:mm'}} </td>
    </ng-container>
    <ng-container matColumnDef="dateEndEffect">
      <th mat-header-cell *matHeaderCellDef> Date fin </th>
      <td mat-cell *matCellDef="let element"> {{element.dateEndEffect| momentPipe:'DD/MM/YYYY HH:mm'}} </td>
    </ng-container>

    <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
    <ng-container matColumnDef="expandedDetail">
      <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
        <div class="example-element-detail">

        </div>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let element; columns: displayedColumns;" class="example-element-row "

      >
    </tr>
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
  </table>
</div>

