import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormBuilder, FormControl } from '@angular/forms';
import {AccessMode } from 'src/app/class/unit.enum';
import { TenantService } from 'src/app/services/tenant.service';
import { AlertService } from 'src/app/services/alert-service.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormChangeService } from 'src/app/services/form-change.service';
import { Router } from '@angular/router';
import { ModalRASComponent } from 'src/app/modal/modalRAS.component';

@Component({
  selector: 'app-create-tenant',
  templateUrl: './create-tenant.component.html',
  styleUrls: ['./create-tenant.component.scss']
})
export class CreateTenantComponent implements OnInit {

  tenantUpdateForm: FormGroup;
  connectionTestOk;
  testOngoing = false;
  testStated = false;
  testBtnDisable = false;
  keys = Object.keys;
  tenantUpdate: any;

  accessModeEnum = AccessMode;
  constructor(protected tenantServ: TenantService,
    private fb: FormBuilder,
    protected alertService: AlertService,
    public authenSer: AuthenticationService,
    public activeModal: NgbActiveModal,
    protected formService: FormChangeService,
    private route: Router,
    protected modalService: NgbModal,) {

    }

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.tenantUpdateForm = this.fb.group({
      // remindMailActive: new FormControl(""),
      // remindMailDay: new FormControl(""),
      // remindSmsActive: new FormControl(""),
      // remindSmsDay: new FormControl(""),
      // systemPaie: new FormControl(""),
      // systemPaieBddHost: new FormControl(""),
      // systemPaieBddPwd: new FormControl(""),
      // systemPaieBddUser: new FormControl(""),
      // systemPaieVersion: new FormControl(""),
      // systemSync: new FormControl(""),
      // nbBadge: new FormControl(""),
      // nbReader: new FormControl(""),
      activity: new FormControl(""),
      adress: new FormControl(""),
      city: new FormControl(""),
      companyName: new FormControl(""),
      contactEmail: new FormControl(""),
      contactName: new FormControl(""),
      contactPosition: new FormControl(""),
      cp: new FormControl(""),
      methodPaymentId: new FormControl(""),
      siret: new FormControl(""),
      stripeCustomerId: new FormControl(""),
      tel1: new FormControl(""),
      tel2: new FormControl(""),
    });
  }

  updateForm(tenant): void {
    this.tenantUpdateForm.patchValue({
      // remindMailActive: tenant.remindMailActive,
      // remindMailDay: tenant.remindMailDay,
      // remindSmsActive: tenant.remindSmsActive,
      // remindSmsDay: tenant.remindSmsDay,
      // systemPaie: tenant.systemPaie,
      // systemPaieBddHost: tenant.systemPaieBddHost,
      // systemPaieBddPwd: tenant.systemPaieBddPwd,
      // systemPaieBddUser: tenant.systemPaieBddUser,
      // systemPaieVersion: tenant.systemPaieVersion,
      // systemSync: tenant.systemSync,

      activity: tenant.activity,
      adress: tenant.adress,
      city: tenant.city,
      companyName: tenant.companyName,
      contactEmail: tenant.contactEmail,
      contactName: tenant.contactName,
      contactPosition: tenant.contactPosition,
      cp: tenant.cp,
      methodPaymentId: tenant.methodPaymentId,
      siret: tenant.siret,
      stripeCustomerId: tenant.stripeCustomerId,
      tel1: tenant.tel1,
      tel2: tenant.tel2,
    });
  }

  checksRemaindMail() {

    if (this.remidEmailActif()) {
      this.tenantUpdateForm.controls["remindMailDay"].setValidators(
        Validators.required
      );
    } else {
      this.tenantUpdateForm.controls["remindMailDay"].setErrors(null);
      this.tenantUpdateForm.controls["remindMailDay"].setValidators([]);
    }
  }
  checksRemaindSMS() {
    if (this.remidSmsActif()) {
      this.tenantUpdateForm.controls["remindSmsDay"].setValidators(
        Validators.required
      );
    } else {
      this.tenantUpdateForm.controls["remindSmsDay"].setErrors(null);
      this.tenantUpdateForm.controls["remindSmsDay"].setValidators([]);
    }
  }

  openConfirmRemaindSMS(event) {

    if (this.remidSmsActif()){
      const modalRef = this.modalService.open(ModalRASComponent, { backdrop: 'static' });
      modalRef.componentInstance.title = 'Activer le Rappel SMS';
      modalRef.componentInstance.message = 'Cette option engendre des frais à hauteur de 0,50€ / sms. \n\nVoulez-vous activer cette option ?';
      modalRef.componentInstance.withCancel = true;
      modalRef.result.then(result => {

        if (!result){
          this.tenantUpdateForm.get(["remindSmsActive"]).setValue('');
        }
        this.checksRemaindSMS();
      });
  }
  }

  checksSystemSync() {
    if (this.systemSyncActif()) {
      this.tenantUpdateForm.controls["systemPaieBddHost"].setValidators(
        Validators.required
      );
      this.tenantUpdateForm.controls["systemPaieBddPwd"].setValidators(
        Validators.required
      );
      this.tenantUpdateForm.controls["systemPaieBddUser"].setValidators(
        Validators.required
      );
    } else {
      this.tenantUpdateForm.controls["systemPaieBddHost"].setErrors(null);
      this.tenantUpdateForm.controls["systemPaieBddPwd"].setErrors(null);
      this.tenantUpdateForm.controls["systemPaieBddUser"].setErrors(null);
      this.tenantUpdateForm.controls["systemPaieBddHost"].setValidators([]);
      this.tenantUpdateForm.controls["systemPaieBddPwd"].setValidators([]);
      this.tenantUpdateForm.controls["systemPaieBddUser"].setValidators([]);
    }
  }
  save() {
    let tenant = this.createFromForm();
    this.tenantServ.saveTenant(tenant).subscribe(
      (data) => {
        this.alertService.success("Tenant créé.", true);
          this.route.navigateByUrl('/tenants');
      },
      (error) => {
        this.alertService.warn("Erreur de création.", true);
      }
    );
  }
  isValid(f) {

  }
  remidEmailActif() {
    return (
      this.tenantUpdateForm.get(["remindMailActive"]) &&
      this.tenantUpdateForm.get(["remindMailActive"])!.value == true
    );
  }
  remidSmsActif() {
    return (
      this.tenantUpdateForm.get(["remindSmsActive"]) &&
      this.tenantUpdateForm.get(["remindSmsActive"])!.value == true
    );
  }
  systemSyncActif() {
    return (
      this.tenantUpdateForm.get(["systemSync"]) &&
      this.tenantUpdateForm.get(["systemSync"])!.value == true
    );
  }
  checkbddConnection() {
    this.testStated = true;
    this.testOngoing = true;
    let bddName = this.tenantUpdateForm.get(["systemPaieBddName"])!.value;
    // this.customerService.checkdbbConnection(bddName).subscribe(
    //   (data) => {
    //     if (data) {
    //       this.connectionTestOk = true;
    //     } else {
    //       this.connectionTestOk = false;
    //     }

    //     this.testOngoing = false;
    //   },
    //   (error) => {
    //     console.log(error);
    //     this.connectionTestOk = false;
    //     this.testOngoing = false;
    //   }
    // );
  }
  onBddNameChange() {
    let val = this.tenantUpdateForm.get(["systemPaieBddName"])!.value;

    if (val != null) {
      this.testBtnDisable = false;
    } else {
      this.testBtnDisable = true;
    }
    this.testStated = false;
    this.testOngoing = false;
  }
  istestBtnDisable() {
    return this.testBtnDisable;
  }

  private createFromForm(): any {
    return {
      companyName : this.tenantUpdateForm.get(['companyName'])!.value,
      siret : this.tenantUpdateForm.get(['siret'])!.value,
      adress : this.tenantUpdateForm.get(['adress'])!.value,
      cp : this.tenantUpdateForm.get(['cp'])!.value,
      city : this.tenantUpdateForm.get(['city'])!.value,
      contactName : this.tenantUpdateForm.get(['contactName'])!.value,
      contactPosition : this.tenantUpdateForm.get(['contactPosition'])!.value,
      contactEmail : this.tenantUpdateForm.get(['contactEmail'])!.value,
      tel1 : this.tenantUpdateForm.get(['tel1'])!.value,
      tel2 : this.tenantUpdateForm.get(['tel2'])!.value,
      activity : this.tenantUpdateForm.get(['activity'])!.value,
      // systemPaie : this.tenantUpdateForm.get(['systemPaie'])!.value,
      // systemPaie: this.tenantUpdateForm.get(["systemPaie"]).value? this.tenantUpdateForm.get(["systemPaie"]).value: null,
      // systemPaieVersion : this.tenantUpdateForm.get(['systemPaieVersion'])!.value,
      // systemPaieBddHost : this.tenantUpdateForm.get(['systemPaieBddHost'])!.value,
      // systemPaieBddUser : this.tenantUpdateForm.get(['systemPaieBddUser'])!.value,
      // systemPaieBddPwd : this.tenantUpdateForm.get(['systemPaieBddPwd'])!.value,
      // systemSync : this.tenantUpdateForm.get(['systemSync'])!.value,
      // remindMailActive : this.tenantUpdateForm.get(['remindMailActive'])!.value,
      // remindMailDay : this.tenantUpdateForm.get(['remindMailDay'])!.value,
      // remindSmsActive : this.tenantUpdateForm.get(['remindSmsActive'])!.value,
      // remindSmsDay : this.tenantUpdateForm.get(['remindSmsDay'])!.value,
      // stripeCustomerId : this.tenantUpdateForm.get(['stripeCustomerId'])!.value,
      // methodPaymentId : this.tenantUpdateForm.get(['methodPaymentId'])!.value,
      // nbReader : this.tenantUpdateForm.get(['nbReader'])!.value,
      // nbBadge : this.tenantUpdateForm.get(['nbBadge'])!.value,
    };
  }

}
