<div role="document" class="modal-dialog">
  <div class="modal-content">
    <div class="modal-header">
      <h2 id="prep-file-heading">Import d'entées interphone</h2>
    </div>
    <div class="modal-body">
      <div class="modal-body">
        <form class="col-10" name="userCreateForm" role="form" novalidate #f="ngForm" (ngSubmit)="f.form.valid && onSubmit()"
            [formGroup]="userCreateForm">
          <div  class="form-group row">
            <label class="form-control-label">Fichier (.csv) :</label>
            <input type="file" formControlName="file" class="form-control-file"
            (change)="handleFileInput($event.target.files)"
                [ngClass]="{ 'is-invalid': f.submitted && userCreateForm?.controls?.file?.errors }" />
            <div *ngIf="f.submitted && userCreateForm?.controls?.file?.errors" class="invalid-feedback">
                <div *ngIf="f.submitted && userCreateForm?.controls?.file?.errors">champ obligatoire</div>
            </div>
          </div>
            <div class="m-2">
                <button type="button" id="cancel-save" class="btn btn-secondary" data-dismiss="modal"
                    (click)="close();">
                    &nbsp;<span>Annuler</span>
                </button>

                &nbsp;&nbsp;
                <button type="submit" id="save-entity" class="btn btn-primary button titre">
                    &nbsp;<span>Valider</span>
                </button>
            </div>
        </form>
    </div>
</div>
</div>
