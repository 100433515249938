import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import 'rxjs/add/operator/map';
import 'rxjs/Observable';
import { environment } from '../../environments/environment';
import { AuthenticationService } from './authentication.service';
@Injectable({
  providedIn: 'root'
})
export class VehiculeService {
  getById(id: string){
    let url=environment.apiUrl+ this.authenSrv.getUserApiTargetUrl() +'/vehicles/'+id;
    return this.http.get<any>(url);
  }

  update(badge){
    let url=environment.apiUrl+ this.authenSrv.getUserApiTargetUrl() +'/vehicles';
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.put<any>(url,badge,{headers ,withCredentials: true});
  }
  create(badge){
    let url=environment.apiUrl + this.authenSrv.getUserApiTargetUrl() +'/vehicles';
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post<any>(url,badge,{headers,responseType: 'text' as 'json', withCredentials: true});
  }
  enable(badgeId: string){
    let url = environment.apiUrl+ this.authenSrv.getUserApiTargetUrl() +'/vehicles/enable?badgeId='+badgeId;
    return this.http.get<any>(url,{withCredentials: true});
  }
  desable(badgeId: string){
    let url = environment.apiUrl+ this.authenSrv.getUserApiTargetUrl() +'/vehicles/disable?badgeId='+badgeId;
    return this.http.get<any>(url,{withCredentials: true});
  }
  delete(id: string){
    let url = environment.apiUrl + this.authenSrv.getUserApiTargetUrl() +'/vehicles/'+id;
    return this.http.delete<any>(url,{withCredentials: true});
  }
  constructor(private http: HttpClient, private authenSrv: AuthenticationService) { }

  getVehicules(){
    let url = environment.apiUrl + this.authenSrv.getUserApiTargetUrl() +'/vehicles';
    return this.http.get<any>(url,{withCredentials: true});
  }
}
