import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import 'rxjs/add/operator/map';
import 'rxjs/Observable';
import { environment } from '../../environments/environment';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class EquipmentService {
  delete(id: any) {
    //no Backend
    // throw new Error("Method not implemented.");
  }
  constructor(private http: HttpClient, private authenSrv: AuthenticationService) { }
  getEquipmentsFromBack(){
    let url=environment.apiUrl+ this.authenSrv.getUserApiTargetUrl() +'/equipments';
    return this.http.get<any>(url,{withCredentials: true});
  }
  getEquipmentByIdFromBack(id: string){
    let url=environment.apiUrl+ this.authenSrv.getUserApiTargetUrl() +'/equipments/'+id;
    return this.http.get<any>(url,{withCredentials: true});
  }

  saveEquipment(digicode){
    let url=environment.apiUrl+ this.authenSrv.getUserApiTargetUrl() +'/equipments';
    return this.http.put(url, digicode, { withCredentials: true, responseType:'text'});

  }
  createEquipment(digicode){
    let url=environment.apiUrl+ this.authenSrv.getUserApiTargetUrl() +'/equipments';
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post<any>(url,digicode,{headers , withCredentials: true});
  }
  forceOpen(digicode: string){
    let url =environment.apiUrl+ this.authenSrv.getUserApiTargetUrl() +'/control/forceOpen?equipmentId='+digicode;
    return this.http.post<any>(url,{withCredentials: true});
  }
  lock(equipementId, state){
    let url =environment.apiUrl+ this.authenSrv.getUserApiTargetUrl() +'/control/lock?equipmentId='+equipementId
    +'&locked='+state;
    return this.http.post<any>(url,{withCredentials: true});
  }
}
