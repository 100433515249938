<div role="document">
  <div>
    <h2 id="prep-file-heading">Creation Notification</h2>
  </div>

  <div class=" justify-content-center">
    <form name="editForm" role="form" novalidate (ngSubmit)="save()" [formGroup]="employeesCreateForm"
      #f="ngForm">
        <fieldset class="border p-2">
          <legend class="w-auto">Notification</legend>
          <div class="form-group row">
            <label for="type" class="col-1 col-form-label">Type</label>
            <div class="col-3">
              <select class="custom-select" formControlName="type" #mySelect (change)="refreshDestinataire()">
                <option *ngFor=" let typeCnt of typeContrat" [ngValue]="typeCnt.code">
                  {{typeCnt.name}}
                </option>
              </select>
            </div>
          </div>

          <div class="form-group row mb-2">
                <ng-container *ngIf="employeesCreateForm?.controls?.type?.value === 'MAIL'">
                  <label  class="col-1 col-form-label" for="destValue">Email </label>
                  <div class="col-3">
                    <input type="email" class="form-control" id="destValue"
                        name="destValue" [ngClass]="{ 'forminput-invalid':
                      f.submitted && employeesCreateForm?.controls?.destValue?.errors != null}" formControlName="destValue"  />
                      <div *ngIf="f.submitted && employeesCreateForm?.controls?.destValue?.errors?.email" class="text-danger">
                        format Email non valide
                      </div>
                  </div>
                </ng-container>

                <ng-container *ngIf="employeesCreateForm?.controls?.type?.value === 'TEL' ||  employeesCreateForm?.controls?.type?.value ==='SMS' ">
                  <label  class="col-1 col-form-label" for="destValue">Telephone</label>
                  <div class="col-3">
                    <input type="text" class="form-control" id="destValue"
                    name="destValue" [ngClass]="{ 'forminput-invalid':
                    f.submitted && employeesCreateForm?.controls?.destValue?.errors != null}" formControlName="destValue"  />

                  </div>
                </ng-container>
          </div>

          <div class="form-group row">
            <label for="type" class="col-1 col-form-label">Evènement</label>
            <div class="col-3">
              <select class="custom-select" formControlName="eventType" >
                <option *ngFor=" let typeCnt of typeEvent" [ngValue]="typeCnt.code">
                  {{typeCnt.name}}
                </option>
              </select>
            </div>
          </div>

          <div class="form-group form-row mb-2">
            <div class="col-3">
              <div class="form-group  align-items-center">
                <div class="custom-control custom-switch">
                <input type="checkbox" class="custom-control-input" id="switch1" formControlName="eachOccurence">
                <label class="custom-control-label" for="switch1" >Chaque occurence</label>
              </div>
              </div>
            </div>
          </div>
            <ng-container *ngIf="!employeesCreateForm.controls.eachOccurence.value">
            <div class="form-group row">
              <label  class="col-auto col-form-label" for="nbNextOccurence">Nombre d'occurrence</label>
              <div class="col-3">
                <input type="number" class="form-control" id="nbNextOccurence"
                name="nbNextOccurence" [ngClass]="{ 'forminput-invalid':
                f.submitted && employeesCreateForm?.controls?.nbNextOccurence?.errors != null}" formControlName="nbNextOccurence"  />
                <div *ngIf="f.submitted && employeesCreateForm?.controls?.nbNextOccurence?.errors?.required" class="text-danger">
                  champ Obligatoire
                </div>
              </div>
            </div>
            </ng-container>
        <div class="form-group row">
          <label  class="col-1 col-form-label" for="reference">Digicode</label>
          <div class="col-3">
            <ng-select  name="digicodeIds" dropdownPosition='bottom'
              formControlName="digicodeIds" required
              [ngClass] = "{ 'forminput-invalid':f.submitted &&
              employeesCreateForm['controls']?.equipmentIds?.errors != null}"
                [items]="degicods" [multiple]="true" [bindLabel]="'label'"
                [closeOnSelect]="false" bindValue="id" style="padding-left: 0px;
                          padding-right: 0px;">
              </ng-select>
              <div *ngIf="f.submitted && employeesCreateForm?.controls?.digicodeIds?.errors"
              class="text-danger">
                champ Obligatoire
              </div>
          </div>
        </div>
        <div class="form-group row">
          <label  class="col-1 col-form-label" for="accessCodeReference">Code</label>
          <div class="col-3">
            <input type="text" class="form-control" id="accessCodeReference"
            name="accessCodeReference" [ngClass]="{ 'forminput-invalid':
            f.submitted && employeesCreateForm?.controls?.accessCodeReference?.errors != null}" formControlName="accessCodeReference"  />
            <div *ngIf="f.submitted && employeesCreateForm?.controls?.accessCodeReference?.errors?.required" class="text-danger">
              champ Obligatoire
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label  class="col-1 col-form-label" for="reference">Camera</label>
          <div class="col-3">
            <ng-select  name="screenCameraId" dropdownPosition='bottom'
              formControlName="screenCameraId" 
              [ngClass] = "{ 'forminput-invalid':f.submitted &&
              employeesCreateForm['controls']?.screenCameraId?.errors != null}"
                [items]="cameras" [multiple]="false" [bindLabel]="'label'"
                [closeOnSelect]="false" bindValue="id" style="padding-left: 0px;
                          padding-right: 0px;">
              </ng-select>
              <div *ngIf="f.submitted && employeesCreateForm?.controls?.digicodeIds?.errors"
              class="text-danger">
                champ Obligatoire
              </div>
          </div>
        </div>
        <div class="form-group row">
          <label  class="col-auto col-form-label" for="waitBeforeShot">Capture après événement (sec)</label>
          <div class="col-3">
            <input type="number" class="form-control" id="waitBeforeShot"
            name="waitBeforeShot" [ngClass]="{ 'forminput-invalid':
            f.submitted && employeesCreateForm?.controls?.waitBeforeShot?.errors != null}" formControlName="waitBeforeShot"  />
            <div *ngIf="f.submitted && employeesCreateForm?.controls?.waitBeforeShot?.errors?.required" class="text-danger">
              champ Obligatoire
            </div>
          </div>
        </div>
      </fieldset>

      <div class="m-2">
        <button type="button" id="cancel-save" class="btn btn-secondary" data-dismiss="modal"
          [routerLink]="['/notifications']">
          &nbsp;<span>Annuler</span>
        </button>
        &nbsp;&nbsp;
        <button type="submit" id="save-entity" class="btn btn-primary">
          <span>Valider</span>
        </button>
      </div>
    </form>
  </div>
</div>
