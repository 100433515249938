import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class DeleveryNoteService {
  getById(id: string){
    let url=environment.apiUrl+ this.authenSrv.getUserApiTargetUrl() +'/deliveryNotes/'+id;
    return this.http.get<any>(url);
  }

  update(camera){
    let url=environment.apiUrl+ this.authenSrv.getUserApiTargetUrl() +'/deliveryNotes';
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.put<any>(url,camera,{headers ,withCredentials: true});
  }
  attachBill(noteNumb, numBill){
    let url=environment.apiUrl+ this.authenSrv.getUserApiTargetUrl() +'/deliveryNotes/attachBill?deliveryNoteId='+noteNumb + '&noBill=' + numBill;;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.put<any>(url,{headers ,withCredentials: true});
  }
  create(camera){
    let url=environment.apiUrl + this.authenSrv.getUserApiTargetUrl() +'/deliveryNotes';
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post<any>(url,camera,{headers,responseType: 'text' as 'json', withCredentials: true});
  }
  delete(id: string){
    let url = environment.apiUrl + this.authenSrv.getUserApiTargetUrl() +'/deliveryNotes/'+id;
    return this.http.delete<any>(url,{withCredentials: true});
  }
  constructor(private http: HttpClient, private authenSrv: AuthenticationService) { }

  get(){
    let url = environment.apiUrl + this.authenSrv.getUserApiTargetUrl() +'/deliveryNotes';
    return this.http.get<any>(url,{withCredentials: true});
  }
}
