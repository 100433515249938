<app-form-min-installation></app-form-min-installation>
<div class="basic-container" style="margin-top: 10px;">
  <table mat-table [dataSource]="lstEquipements" multiTemplateDataRows class="mat-elevation-z8">

	<ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef> status </th>
      <td mat-cell *matCellDef="let element">
        <span *ngIf="!element.online" class="btn btn-danger btn-lg btn-circle">
        </span>
        <span *ngIf="element.online" class="btn btn-info btn-lg btn-circle">
        </span>
      </td>
    </ng-container>
    <ng-container matColumnDef="backDesc">
      <th mat-header-cell *matHeaderCellDef> label </th>
      <td mat-cell *matCellDef="let element">
        <span>
          {{element.userDesc}}
        </span>
      </td>
    </ng-container>
    <ng-container matColumnDef="lastcomm">
      <th mat-header-cell *matHeaderCellDef> dernière communication </th>
      <td mat-cell *matCellDef="let element">
        <span *ngIf="!element.online">{{element.lastCommunication | momentPipe:'DD/MM/YYYY HH:mm'}}</span>
      </td>
    </ng-container>
    
    <ng-container matColumnDef="reboot">
      <th mat-header-cell *matHeaderCellDef class="centered"> Action </th>
      <td mat-cell *matCellDef="let element" class="centered">
        <span>
          <button _ngcontent-cxa-c12="" class="btn btn-primary" id="create-variable" type="button" (click)="reboot(element)">
          	<span _ngcontent-cxa-c12=""> Redémarrer </span></button>
        </span>
      </td>
    </ng-container>

    <!-- <ng-container matColumnDef="expandedDetail">
      <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
        <div class="example-element-detail">
          <div class="inner-table mat-elevation-z8">

            <table mat-table [dataSource]="[element]" multiTemplateDataRows class="flex mat-elevation-z8">

              <ng-container matColumnDef="dateModification">
                <th mat-header-cell *matHeaderCellDef> Modifié </th>
                <td mat-cell *matCellDef="let element"> {{element.dateModification| momentPipe:'DD/MM/YYYY'}} </td>
              </ng-container>
            </table>

          </div>
        </div>
      </td>
    </ng-container> -->
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let element; columns: displayedColumns;" class="example-element-row "
      [class.example-expanded-row]="expandedElement === element"
      (click)="expandedElement = expandedElement === element ? null : element">
    </tr>
  </table>
</div>
