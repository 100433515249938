export class Role {
    static  User = new Role('ROLE_USER', 'user');
    static Admin = new Role('ROLE_ADMIN', 'admin');
    static Sale = new Role('ROLE_SALE', 'sale');
    static SupperAdmin = new Role('ROLE_SUPER_ADMIN', 'superadmin');
    static Interphone = new Role('ROLE_INTERPHONE', 'interphone');
    static Badge = new Role('ROLE_BADGE', 'badge');
    static Web = new Role('ROLE_WEB', 'web');
    static Mobile = new Role('ROLE_MOBILE', 'mobile');
    static DeleverNote= new Role('ROLE_DELIVER_NOTE', 'deleverNote');
    static Lapi= new Role('ROLE_LAPI', 'lapi');

    private constructor(private readonly key: string, public readonly value: any) {
    }
    public static valueOf(str: string ){
      if(str === 'ROLE_USER'){
        return Role.User;
      }
      if(str === 'ROLE_ADMIN'){
        return Role.Admin;
      }
      if(str === 'ROLE_SALE'){
        return Role.Sale;
      }
      if(str === 'ROLE_SUPPERADMIN'){
        return Role.SupperAdmin;
      }
      if(str === 'ROLE_BADGE'){
        return Role.Badge;
      }
      if(str === 'ROLE_WEB'){
        return Role.Web;
      }
      if(str === 'ROLE_MOBILE'){
        return Role.DeleverNote;
      }
      if(str === 'ROLE_DELIVER_NOTE'){
        return Role.DeleverNote;
      }
      if(str === 'ROLE_LAPI'){
        return Role.Lapi;
      }
    }
    toString() {
      return this.key;
    }
}
