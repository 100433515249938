
<div class="wrapperDiv">
  <div class="col-md-6 col-10 login-form">
    <form name="form" (ngSubmit)="f.form.valid && resetPassword()" #f="ngForm" >
    <h2 class="text-center">Réinitialiser le mot de passe</h2>
    <div class="form-group titre">
      <input type="email" class="form-control"
      [ngClass]="{ 'forminput-invalid':f.submitted &&
      (!model.login ||model.login?.length == 0 )}" [(ngModel)]="model.login" #email="ngModel" name="login" placeholder="Login" required>
    <div *ngIf="f.submitted  && !model.login" class="text-danger">champ Obligatoire</div>
  </div>
        <div class="text-center">
          <div class="form-group">
            <button  type="submit" class="btn btn-primary btn-lg btn-block login-btn">Valider</button>
            <p class="text-center small"><a [routerLink]="['/login']">Annuler</a></p>
          </div>
        </div>

    </form>
  </div>
</div>
