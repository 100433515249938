import { HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { AlertService } from 'src/app/services/alert-service.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { DeleveryNoteService } from 'src/app/services/delevery-note.service';
import { FormChangeService } from 'src/app/services/form-change.service';
import { TenantService } from 'src/app/services/tenant.service';

@Component({
  selector: 'app-delevery-note-create',
  templateUrl: './delevery-note-create.component.html',
  styleUrls: ['./delevery-note-create.component.scss']
})
export class DeleveryNoteCreateComponent implements OnInit {
  userCreateForm: FormGroup;
  submitted = false;
  searchText;
  customers: {};
  customerId;
  tenantId;
  selectedCivilite: string = null;
  emailInvalid: boolean;
  numSsInvalid: boolean;
  valeurAcorriger = [];
  userForm: any;
  selectedCustomer;
  roles = new Array();
  selectedRoles = new Array();
  selectedTenant: any[];
  tenants: Object;
  constructor(
    protected delevryNoteService: DeleveryNoteService,
    private fb: FormBuilder,
    private tenantServ: TenantService,
    protected alertService: AlertService,
    private formService: FormChangeService,
    public authenSer: AuthenticationService,
    public activeModal: NgbActiveModal) {

     }

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.userCreateForm = this.fb.group({
      company : [],
      desc : [],
      immatriculation : [],
      lastModificationBy : [],
      name : [],
      numBill : [],
      tenantId : [],
    });
  }
  get f() { return this.userCreateForm.controls; }

  private createFromForm() {
    let tmp = {
      company : this.userCreateForm.get(["company"])!.value,
      desc : this.userCreateForm.get(["desc"])!.value,
      immatriculation : this.userCreateForm.get(["immatriculation"])!.value,
      name : this.userCreateForm.get(["name"])!.value,
      numBill : this.userCreateForm.get(["numBill"])!.value,
      tenantId: this.authenSer.isSupperAdmin?this.userCreateForm.get(['tenantId']).value:null,
    };
    
    return tmp;
  }

  onSubmit(): void {
    this.submitted = true;

    if (this.userCreateForm.invalid) {
      return;
    } else {
      this.userForm = this.createFromForm();
      this.subscribeToSaveResponse(this.delevryNoteService.create(this.userForm));
    }
  }

  rechercher() {
      this.getTenantsFromBackEnd()
      .subscribe(
        data => {
          let tmp = data as Array<any>;
          this.tenants = data;
        }
        , error => {
          console.log(error);
        });
  }

  getTenantsFromBackEnd() {
    return this.tenantServ.getTenants();
  }

  close(): void {
    this.activeModal.close();
  }

  protected subscribeToSaveResponse(result: Observable<HttpResponse<any>>): void {
    result.subscribe(
      (res) => this.onSaveSuccess(result),
      (res) => this.onSaveError(result)
    );
  }



  protected onSaveSuccess(result): void {
    
    this.alertService.success('Vehicule sauvegardé !', true);
    this.formService.raiseReloadDelevryNoteSearchRequestEvent();
    this.close();
  }

  protected onSaveError(result): void {
    
    this.alertService.error('Erreur dans le formulaire !', true);
  }

}
