import { trigger, state, style, transition, animate } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { ModalRASComponent } from '../modal/modalRAS.component';
import { ReaderUpdateComponent } from '../readers/reader-update/reader-update.component';
import { AlertService } from '../services/alert-service.service';
import { AuthenticationService } from '../services/authentication.service';
import { FormChangeService } from '../services/form-change.service';
import { ReaderService } from '../services/reader.service';
import { InterphoneCreateComponent } from './interphone-create/interphone-create.component';
import { InterphoneService } from './interphone.service';
import { VideoPlayComponent } from './video-play/video-play.component';

@Component({
  selector: 'app-interphone',
  templateUrl: './interphone.component.html',
  styleUrls: ['./interphone.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0px'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class InterphoneComponent implements OnInit {

  constructor(public interphoneServ: InterphoneService,
    public alertService: AlertService,
    public authenSrv: AuthenticationService,
    private modalService: NgbModal,
    private formService: FormChangeService,
    private router: Router) { }

  subscriptions = new Array<Subscription>();
  rowData: any;
  rowDataFiltred;
  gridOptions : any;
  columnDefs : any;
  displayedColumns: Array<string>;
  lstInterphone = new MatTableDataSource(new Array());
  innerDisplayedColumns = ['Nom','Contacts'];
  expandedElement: {} | null;
  ngOnInit() {
    this.displayedColumns = ['expandIcon','label','adress', 'tephone','Actions'];

    this.subscribeformChange();
  }
  create(): void {
    const modalRef = this.modalService.open(InterphoneCreateComponent, { backdrop: 'static' });
    modalRef.result.then(result => {
      if (result == true) {
        this.formService.raiseInterphonesSearchRequestEvent();
      }
    });
  }
  edit(digicod){
    const modalRef = this.modalService.open(ReaderUpdateComponent, { backdrop: 'static' });
    modalRef.componentInstance.digicode = digicod;
  }

  subscribeformChange(){
    this.formService.formInterphonesSearchChanged.subscribe(
      (param) => {
        
        this.lstInterphone = new MatTableDataSource(param);

      });
  }

  deleteInterphone(idTenant) {
    this.subscriptions.push(
      this.interphoneServ.delete(idTenant)
      .subscribe(
        (result) => {
          this.alertService.success('interphone supprimé !', true);
          this.formService.raiseInterphonesSearchRequestEvent();
        },
        (result) => {
          this.alertService.error('Erreur', true);
        }
      ));

  }
  videoPlay(element){
    const modalRefere = this.modalService.open(VideoPlayComponent, {
      backdrop: "static", keyboard: false
    });

    let titre = " Video interphone: " + element.label;
    modalRefere.componentInstance.title = titre;
    modalRefere.componentInstance.videoSrc = element.rtspUrl;
  }
  editTenant(element) {
    this.router.navigateByUrl('/update-ineterphone', { state: {interphone:element}});

  }
  openDesableInterphoneConfirm(interphone){
    let enable = !interphone['active'];
    const modalRefere = this.modalService.open(ModalRASComponent, {
      backdrop: "static", keyboard: false
    });
    let titre = enable ? 'Activation' : 'Désactivation';
    titre +=  " de l'interphone  " + interphone.label;
    modalRefere.componentInstance.title = titre
    modalRefere.componentInstance.message = "Confirmez-vous " + (enable ? "l'activation" : "la désactivation") + "?";
    modalRefere.componentInstance.withCancel = true;
    modalRefere.result.then((result) => {
      if (result != false) {
        this.interphoneServ.enableDisableInterphone(interphone.id, enable)
          .subscribe(
            (data) => {
              this.alertService.success('Modification effectuée.', true);
              this.formService.raiseInterphonesSearchRequestEvent();
          },
            (error) => {
              this.alertService.warn('Erreur de modification.', true);
            }
          );
        };
    })
    .catch((error)=>{
      console.log(error);
    }
    );
  }
  openDeleteConfirm(id): void {
    const modalRef = this.modalService.open(ModalRASComponent, { backdrop: 'static' });
    modalRef.componentInstance.title = "Suppression d'interphone";
    modalRef.componentInstance.message = "Confirmer la suppression ?";
    modalRef.componentInstance.withCancel = true;
    modalRef.result.then(result => {

      if (result == true) {
        this.deleteInterphone(id);
      }
    });
  }
}
