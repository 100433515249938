import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { AlertService } from '../services/alert-service.service';
import { FormChangeService } from '../services/form-change.service';
import { InstallationService } from '../services/installation.service';
import {Observable} from 'rxjs/Observable';
import { interval } from 'rxjs';
@Component({
  selector: 'app-installatation',
  templateUrl: './installatation.component.html',
  styleUrls: ['./installatation.component.scss']
})
export class InstallatationComponent implements OnInit, OnDestroy {
  sub;
  expandedElement: {} | null;
  lstEquipements = new MatTableDataSource(new Array());
  displayedColumns = ['status', 'backDesc','lastcomm','reboot'];
  constructor(private installationServ: InstallationService, private alertService: AlertService, private formService: FormChangeService){

  }
  ngOnDestroy(){
    this.sub.unsubscribe();
    
  }

  ngOnInit(): void {
    this.subscribeformChange();
    this.sub = interval(60000)
    .subscribe((val) => {
      
       this.formService.raiseReloadInstallationsSearchRequestEvent()
      });
  }

  subscribeformChange(){
    this.formService.formInstallationtSearchChanged.subscribe(
      (param) => {
        this.lstEquipements = new MatTableDataSource(param);
      });
  }

  reboot(equipment){
    this.installationServ.rebootGetway(equipment.id).subscribe(
      data =>{
        let msg = "Commande de redémarrage  envoyée";
        this.alertService.success(msg);
      },
      error=>{
        this.alertService.error(error);
      },
      ()=>{
      }
    );
  }
}
