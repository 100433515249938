<div role="document" class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h2 id="prep-file-heading">Créer un bon de livraison </h2>
      </div>
      <div class="modal-body">
        <div class="modal-body">
          <form class="col-10" name="userCreateForm" role="form" novalidate #f="ngForm" (ngSubmit)="f.form.valid && onSubmit()"
              [formGroup]="userCreateForm">
              <div  class="form-group row">
                <label class="form-control-label">Nom</label>
                <input type="text" formControlName="name" class="form-control"
                    [ngClass]="{ 'is-invalid': f.submitted && userCreateForm?.controls?.name?.errors }" />
                <div *ngIf="f.submitted && userCreateForm?.controls?.name?.errors" class="invalid-feedback">
                    <div *ngIf="f.submitted && userCreateForm?.controls?.name?.errors">Champ obligatoire</div>
                </div>
            </div>
              <div  class="form-group row">
                <label class="form-control-label">N° Facture :</label>
                <input type="text" formControlName="numBill" class="form-control"
                    [ngClass]="{ 'is-invalid': f.submitted && userCreateForm?.controls?.numBill?.errors }" />
                <div *ngIf="f.submitted && userCreateForm?.controls?.numBill?.errors" class="invalid-feedback">
                    <div *ngIf="f.submitted && userCreateForm?.controls?.numBill?.errors">Champ obligatoire</div>
                </div>
              </div>
              <div  class="form-group row">
                  <label class="form-control-label" for="desc"
                      >Description</label>
                  <textarea type="text" class="form-control" id="desc" name="desc"
                  [ngClass]="{ 'is-invalid': f.submitted && userCreateForm?.controls?.desc?.errors }"
                   formControlName="desc" >
                   </textarea>
                  <div *ngIf="f.submitted && userCreateForm?.controls?.desc?.errors " class="text-danger"> champ
                      obligatoire
                  </div>
              </div>
  
              <div  class="form-group row">
                  <label class="form-control-label" for="immatriculation"
                    >Immatriculation</label>
                  <input type="text" class="form-control" id="immatriculation" name="immatriculation"
                  [ngClass]="{ 'is-invalid': f.submitted && userCreateForm?.controls?.immatriculation?.errors }"
                   formControlName="immatriculation" />
                  <div *ngIf="f.submitted && userCreateForm?.controls?.immatriculation?.errors " class="text-danger"> champ
                    obligatoire
                </div>
              </div>
  
               <div  class="form-group row">
                  <label class="form-control-label">Entreprise</label>
                  <input type="text" formControlName="company" class="form-control"
                      [ngClass]="{ 'is-invalid': f.submitted && userCreateForm?.controls?.company?.errors }" />
                  <div *ngIf="f.submitted && userCreateForm?.controls?.company?.errors" class="invalid-feedback">
                      <div *ngIf="f.submitted && userCreateForm?.controls?.company?.errors">champ obligatoire</div>
                  </div>
              </div>
              <div class="m-2">
                  <button type="button" id="cancel-save" class="btn btn-secondary" data-dismiss="modal"
                      (click)="close();">
                      &nbsp;<span>Annuler</span>
                  </button>
  
                  &nbsp;&nbsp;
                  <button type="submit" id="save-entity" class="btn btn-primary button titre">
                      &nbsp;<span>Valider</span>
                  </button>
              </div>
          </form>
      </div>
  </div>
  </div>