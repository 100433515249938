import { HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { AlertService } from 'src/app/services/alert-service.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { BadgeService } from 'src/app/services/badge.service';
import { FormChangeService } from 'src/app/services/form-change.service';
import { TenantService } from 'src/app/services/tenant.service';

@Component({
  selector: 'app-badge-create',
  templateUrl: './badge-create.component.html',
  styleUrls: ['./badge-create.component.scss']
})
export class BadgeCreateComponent implements OnInit {
  userCreateForm: FormGroup;
  submitted = false;
  searchText;
  customers: {};
  customerId;
  tenantId;
  selectedCivilite: string = null;
  emailInvalid: boolean;
  numSsInvalid: boolean;
  valeurAcorriger = [];
  userForm: any;
  selectedCustomer;
  roles = new Array();
  selectedRoles = new Array();
  selectedTenant: any[];
  tenants: Object;
  constructor(
    protected userService: BadgeService,
    private fb: FormBuilder,
    private tenantServ: TenantService,
    protected alertService: AlertService,
    private formService: FormChangeService,
    public authenSer: AuthenticationService,
    public activeModal: NgbActiveModal) {

     }

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.userCreateForm = this.fb.group({
      // customerId: [],
      tenantId: [],
      tel1: [],
      tel2: [],
      firstname: ['', Validators.required],
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
    });
    if (!this.authenSer.isAdmin){
      this.userCreateForm.addControl(
        'login', new FormControl("", [Validators.required])
        );
    }
    this.rechercher();
  }
  // convenience getter for easy access to form fields
  get f() { return this.userCreateForm.controls; }



  private createFromForm() {
    let tmp = {
      // customerId: this.userCreateForm.get(['customerId']).value,
      tenantId: this.authenSer.isSupperAdmin?this.userCreateForm.get(['tenantId']).value:null,
      tel1: this.userCreateForm.get(['label'])!.value,
      tel2: this.userCreateForm.get(['reference'])!.value,
      email: this.userCreateForm.get(['email'])!.value,
      firstname: this.userCreateForm.get(['firstname'])!.value,
      name: this.userCreateForm.get(['name'])!.value,
      roles: this.selectedRoles,
    };
    return tmp;
  }

  onSubmit(): void {
    this.submitted = true;

    if (this.userCreateForm.invalid) {
      return;
    } else {
      this.userForm = this.createFromForm();

      this.subscribeToSaveResponse(this.userService.create(this.userForm));
    }
  }

  rechercher() {
    // let filter = this.getFilter();
    // if(this.authenSer.isSupperAdmin){
      this.getTenantsFromBackEnd()
      .subscribe(
        data => {
          let tmp = data as Array<any>;
          this.tenants = data;
          // if (tmp) {
          //   if (filter) {
          //       tmp = this.findTextInCustomers(tmp, filter);
          //     }
          // }
          // this.selectedTenant = tmp;
        }
        , error => {
          console.log(error);
        });
    // }
  }

  getTenantsFromBackEnd() {
    return this.tenantServ.getTenants();
  }

  close(): void {
    this.activeModal.close();
  }

  protected subscribeToSaveResponse(result: Observable<HttpResponse<any>>): void {
    result.subscribe(
      (result) => this.onSaveSuccess(result),
      (result) => this.onSaveError(result)
    );
  }



  protected onSaveSuccess(result): void {
    this.alertService.success('Badge sauvegardé !', true);
    this.formService.raisereloadSearchRequestUserEvent();
    this.close();
  }

  protected onSaveError(result): void {
    this.alertService.error('Erreur dans le formulaire !', true);
  }
}
