import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, NgModel } from '@angular/forms';
import { AlertService } from 'src/app/services/alert-service.service';
import { ProspectService } from 'src/app/services/prospect.service';
import { FormChangeService } from 'src/app/services/form-change.service';
import { Router, ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { AccessService } from 'src/app/services/access.service';
import { DigiCodService } from 'src/app/services/digicod.service';
import { NotificationsService } from 'src/app/services/notifications.service';

@Component({
  selector: 'app-form-min-notifications',
  templateUrl: './form-min-notifications.component.html',
  styleUrls: ['./form-min-notifications.component.scss']
})
export class FormMinNotificationsComponent implements OnInit,OnChanges {
  dateRange = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });
  @Input() installationId: any;
  searchText: string;
  model: NgModel;
  constructor(
    private alertService: AlertService,
    private formService: FormChangeService,
    private digicodServ: DigiCodService,
    private notifacationService: NotificationsService,
    private digicodService: DigiCodService,
    private route: ActivatedRoute,
    public router: Router) {
  }
  ngOnChanges(changes: SimpleChanges): void {

    this.rechercher();
  }


  ngOnInit() {

    this.rechercher();
    this.subscribeReloadSearchRequest();
  }

  subscribeReloadSearchRequest() {
    this.formService.reloadNotificationsRequest.subscribe(
      () => {
        this.rechercher();
      });
  }
  // efface le formulaire de rechercher appelé depuis navBar
  clearForm() {
    this.rechercher();
  }
  private findTextInProspect(prospects) {
    let filter = this.getTextFilter();
    let prospectFiltred = prospects;

    if (filter) {
      let text = filter.split(' ');
      prospectFiltred = prospects.filter(function (item) {
        let flt = text.every(function (el) {
          //'canal','dateCreation','type','nbExecution','destValue','eachOccurence','nbNextOccurence'
          let tmp = (item['accessCode'] && item['accessCode'].toUpperCase().indexOf(el.toUpperCase()) > -1)
            || (item['type'] && item['type'].toUpperCase().indexOf(el.toUpperCase()) > -1)
            || (item['reference'] && item['reference'].toUpperCase().indexOf(el.toUpperCase()) > -1)
            || (item['reference'] && item['reference'].toUpperCase().indexOf(el.toUpperCase()) > -1)
            || (item['readerId'] && item['readerId'].toUpperCase().indexOf(el.toUpperCase()) > -1);
            return tmp;
        });

        return flt;
      });

    }
    prospectFiltred = this.filterPrspectDateRange(prospectFiltred);
    return prospectFiltred;
  }
  filterPrspectDateRange(prospectFiltred: Array<any>): any {
    let tmp = prospectFiltred;
    if(this.dateRange.get('start').value){
      let start = moment(this.dateRange.get('start').value).startOf('day');
        tmp = tmp.filter(element => {
          let dateCreation = element.dateCreation?moment(element.dateCreation): null;
          return dateCreation && dateCreation.startOf('day').isSameOrAfter(start);
        });
      }
      if(this.dateRange.get('end').value){
        let end = moment(this.dateRange.get('end').value).startOf('day');
          tmp = tmp.filter(element => {
            let dateCreation = element.dateCreation?moment(element.dateCreation): null;
            return dateCreation && dateCreation.startOf('day').isSameOrBefore(end);
          });
      }
    return tmp;
  }

  private getTextFilter() {
    return this.searchText;
  }
  rechercher() {
    this.getNotificationsBackEnd();
  }

  onFormChange() {
    this.rechercher();
  }

  getNotificationsFromBackEnd() {

    if(this.installationId){
      return this.notifacationService.notificationsByInstallation(this.installationId);
    }else {
      return this.notifacationService.notifications();
    }

  }
  getNotificationsBackEnd() {

    this.getNotificationsFromBackEnd()
      .subscribe(
        (data) => {
          let tmpUsers = data as Array<any>;
           tmpUsers = this.findTextInProspect(tmpUsers);

          this.formService.raiseNotificationsListEvent(tmpUsers);
        }
        , (error) => {
          console.log(error);
          this.formService.raiseNotificationsListEvent([]);
        });
  }
}
