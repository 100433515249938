<div role="document" class="modal-dialog">
  <div class="modal-content">
    <div class="modal-header">
      <h2 id="prep-file-heading">Ajouter un interphone</h2>
    </div>
    <div class="modal-body">
      <div class="modal-body">
        <form class="col-10" name="userCreateForm" role="form" novalidate #f="ngForm" (ngSubmit)="f.form.valid && onSubmit()"
            [formGroup]="userCreateForm">
            <ng-container *ngIf="this.authenSer.isSupperAdmin">
              <div class="form-group row ">
                <label class="form-control-label">Saisissez un tenant :</label>
              <ng-select name="selectedVar" class="form-control"
                dropdownPosition='bottom' formControlName="tenantId" [items]="tenants"
                [ngClass]="{ 'forminput-invalid':f.submitted && userCreateForm?.controls?.customerId?.errors }"
                placeHolder="Tenant" [multiple]="false" [bindLabel]="'companyName'" [closeOnSelect]="true"
                bindValue="id" [clearable]="false">
              </ng-select>
            </div>
            </ng-container>
            <div  class="form-group row">
              <label class="form-control-label">Telphone Principal :</label>
              <input type="text" formControlName="callNumber" class="form-control"
                  [ngClass]="{ 'is-invalid': f.submitted && userCreateForm?.controls?.callNumber?.errors }" />
              <div *ngIf="f.submitted && userCreateForm?.controls?.callNumber?.errors" class="invalid-feedback">
                  <div *ngIf="f.submitted && userCreateForm?.controls?.callNumber?.errors">champ obligatoire</div>
              </div>
            </div>
            <ng-container>
              <div class="form-group row">
                <label class="form-control-label" for="label"
                    >Label :</label>
                <input type="text" class="form-control" id="label" name="label"
                [ngClass]="{ 'is-invalid': f.submitted && userCreateForm?.controls?.label?.errors }"
                 formControlName="label" />
                <div *ngIf="f.submitted && userCreateForm?.controls?.label?.errors " class="text-danger"> champ
                    obligatoire
                </div>
              </div>
            </ng-container>
            <div  class="form-group row">
                <label class="form-control-label" for="adress"
                    >Adresse :</label>
                <input type="text" class="form-control" id="adress" name="adress"
                [ngClass]="{ 'is-invalid': f.submitted && userCreateForm?.controls?.adress?.errors }"
                 formControlName="adress" />
                <div *ngIf="f.submitted && userCreateForm?.controls?.adress?.errors " class="text-danger"> champ
                    obligatoire
                </div>
            </div>
            <div  class="form-group row">
                <label class="form-control-label" for="cp"
                  >Code postal :</label>
                <input type="text" class="form-control" id="cp" name="cp"
                [ngClass]="{ 'is-invalid': f.submitted && userCreateForm?.controls?.cp?.errors }"
                 formControlName="cp" />
                <div *ngIf="f.submitted && userCreateForm?.controls?.cp?.errors " class="text-danger"> champ
                  obligatoire
              </div>
            </div>
            <div  class="form-group row">
              <label class="form-control-label" for="city"
                >Ville :</label>
              <input type="text" class="form-control" id="city" name="city"
              [ngClass]="{ 'is-invalid': f.submitted && userCreateForm?.controls?.city?.errors }"
               formControlName="city" />
              <div *ngIf="f.submitted && userCreateForm?.controls?.city?.errors " class="text-danger"> champ
                obligatoire
            </div>
          </div>
          <div  class="form-group row">
            <label class="form-control-label" for="hostUrl"
              >Host :</label>
            <input type="text" class="form-control" id="hostUrl" name="hostUrl"
             formControlName="hostUrl" />
          </div>
          <div  class="form-group row">
            <label class="form-control-label" for="username"
              >UserName :</label>
            <input type="text" class="form-control" id="username" name="username"
             formControlName="username" />
          </div>
          <div  class="form-group row">
            <label class="form-control-label" for="password"
              >Password :</label>
            <input type="Password" class="form-control" id="password" name="password"
             formControlName="password" />
          </div>

            <div class="m-2">
                <button type="button" id="cancel-save" class="btn btn-secondary" data-dismiss="modal"
                    (click)="close();">
                    &nbsp;<span>Annuler</span>
                </button>

                &nbsp;&nbsp;
                <button type="submit" id="save-entity" class="btn btn-primary button titre">
                    &nbsp;<span>Valider</span>
                </button>
            </div>
        </form>
    </div>
</div>
</div>
