import { Component, OnInit, ViewChild } from '@angular/core';
import { DigiCodService } from '../services/digicod.service';
import { AlertService } from '../services/alert-service.service';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { ModalRASComponent } from '../modal/modalRAS.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormChangeService } from '../services/form-change.service';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { AuthenticationService } from '../services/authentication.service';
import { DigicodUpdateComponent } from './digicod-update/digicod-update.component';

@Component({
  selector: 'app-digicods',
  templateUrl: './digicods.component.html',
  styleUrls: ['./digicods.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0px'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class DigicodsComponent implements OnInit {

  constructor(public digiCodService: DigiCodService,
    private formService: FormChangeService,
    private modalService: NgbModal,
    public alertService: AlertService,
    public authenSrv: AuthenticationService,
    private router: Router) { }
    rowData: any;
  rowDataFiltred;
  gridOptions : any;
  columnDefs : any;

  lstDigics = new MatTableDataSource(new Array());
  displayedColumns = ['label','gerer','notification','journal'
  ];
  expandedElement: {} | null;

  @ViewChild(MatSort, {static: true}) sort: MatSort;

  getKeys(object): string[] {
    return Object.keys(object);
  }

  openDeleteConfirm(id): void {
    const modalRef = this.modalService.open(ModalRASComponent, { backdrop: 'static' });
    modalRef.componentInstance.title = "Suppresion d'un Digicod";
    modalRef.componentInstance.message = "Confirmer la suppression ?";
    modalRef.componentInstance.withCancel = true;
    modalRef.result.then(result => {
      if (result == true) {
        this.delete(id);
      }
    });
  }

  ngOnInit() {
    this.subscribeformChange();
    this.lstDigics.sort = this.sort;
  }
  subscribeformChange() {
    this.formService.formDigicodsSearchChanged.subscribe(
      (param) => {

        this.lstDigics = new MatTableDataSource(param);

      });

  }

  create(){
    //DOTO: implement (new)
  }

  edit(digicod){
      const modalRef = this.modalService.open(DigicodUpdateComponent, { backdrop: 'static' });
      modalRef.componentInstance.digicode = digicod;
  }
  journal(installation){
    this.router.navigate(['/accessLogs'],{queryParams: {id:installation.id}});
  }
  notification(digicod){
    this.router.navigate(['/notifications'],{queryParams: {id:digicod.id}});
  }
  createInstallation(){
    this.router.navigateByUrl('/createDigicod');
  }
  forceOpen(installation){

    this.digiCodService.forceOpen(installation.id).subscribe(
      data =>{

        this.alertService.success("Commande d'ouverture "+ installation.label + " envoyée");
      },
      error=>{
        this.alertService.error(error);
      },
      ()=>{
        //this.usubscribe();
      }
    );
  }
  delete(installation){
    this.digiCodService.delete(installation.id)
    .subscribe(
      () => this.onDeleteSuccess(),
      () => this.onDeleteError()
    );
  }
  protected onDeleteSuccess(): void {
    this.alertService.success('Suppression effectuée.', false);
    this.formService.raiseReloadProspectSearchRequestEvent();
  }


  protected onDeleteError(): void {
    this.alertService.warn('Erreur de suppression.', false);
  }

  onChange() {
    // this.formService.raiseReloadDigicodsSearchRequestEvent();
  }
}
