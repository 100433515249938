
<div class="card">
    <div class="card-body " >
            <div class="container">
                <div class="row">
            <div class="col">

                <!-- <h5> <a (click)="goto('/saisies')">Etat des saisies</a></h5> -->
                <h5> <a >Etat des saisies</a></h5>

                <ul>
                  <li>Période en cours</li>
                </ul>
              <!-- <h5> <a (click)="goto('/configurations')"> Configurations</a></h5> -->
              <h5> <a > Configurations</a></h5>
                <ul>
                  <li>Logiciel de paie </li>
                </ul>

            </div>
            <div class="col">
              <div>
                <!-- <h5> <a (click)="goto('/salaries')">Gestion des salariés </a></h5> -->
                <h5> <a >Gestion des salariés </a></h5>
                <div>
                  <ul>
                    <li>Ajouter un salarié</li>
                    <li>Rechercher un salarié </li>
                  </ul>
                </div>
              </div>

               <div *ngIf="this.authenService.isUser">
                <h5 class="desactive">  Gestion des présences </h5>
                <div>
                  <ul>
                    <li>Créer un badge </li>
                    <li>Révoquer un badge </li>
                    <li>Etat des présences</li>
                  </ul>
                </div>
              </div>

              <!-- <div>
                <h5 class="desactive"> Statistiques</h5>
                <div>
                  <ul>
                    <li>Masse salariale </li>
                    <li>Absences</li>
                    <li>Egalité H/F</li>
                  </ul>
                </div>
              </div>  -->
            </div>
          </div>
        </div>
</div>
