<div role="document" class="modal-dialog">
  <div class="modal-content">
    <div class="modal-header">
      <h2 id="prep-file-heading">Modifiez un Badge</h2>
    </div>

    <div class="modal-body">
      <div class="modal-body">
        <form class="col-10" name="userUpdateForm" role="form" novalidate (ngSubmit)="onSubmit()"
          #f="ngForm" [formGroup]="userUpdateForm">

          <ng-container *ngIf="this.authenSer.isSupperAdmin">
            <div class="form-group ">Saisissez un tenant :
              <ng-select class="mb-2" dropdownPosition='bottom' placeholder="Tenant" [items]="tenants"
                [multiple]="false" [bindLabel]="'companyName'" [closeOnSelect]="true" formControlName="tenantId"
                bindValue="id" name="selectedTenant">
                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                  {{item.companyName}}
                </ng-template>
              </ng-select>
            </div>

          </ng-container>
          <div class="form-group row">
            <label class="form-control-label" for="reference"
              >Reference</label>
              <input type="text" class="form-control" id="reference" name="reference"
                    [ngClass]="{ 'is-invalid': f.submitted && userUpdateForm?.controls?.reference?.errors }"
                     formControlName="reference" />
                    <div *ngIf="f.submitted && userUpdateForm?.controls?.reference?.errors " class="text-danger"> champ
                        obligatoire
                    </div>
          </div>

          <div class="form-group row">
            <label class="form-control-label" for="label">Label</label>
            <input type="text" class="form-control" id="label" name="label"
                    [ngClass]="{ 'is-invalid': f.submitted && userUpdateForm?.controls?.label?.errors }"
                     formControlName="label" />
                    <div *ngIf="f.submitted && userUpdateForm?.controls?.label?.errors " class="text-danger"> champ
                      obligatoire
                  </div>
          </div>

          <div class="m-2">
            <button type="button" id="cancel-save" class="btn btn-secondary" data-dismiss="modal" (click)="close();">
              &nbsp;<span>Annuler</span>
            </button>

            &nbsp;&nbsp;
            <button type="submit" id="save-entity" class="btn btn-primary button titre">
              &nbsp;<span>Valider</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
