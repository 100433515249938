<div role="document" class="modal-dialog">
  <div class="modal-content">
    <div class="modal-header">
      <h2 id="prep-file-heading">Modification d'entrée</h2>
    </div>
    <div class="modal-body">
      <div class="modal-body">
        <form class="col-10" name="userCreateForm" role="form" novalidate #f="ngForm" (ngSubmit)="f.form.valid && onSubmit()"
            [formGroup]="userCreateForm">
            <div  class="form-group row">
              <label class="form-control-label">Nom :</label>
              <input type="text" formControlName="name" class="form-control"
                  [ngClass]="{ 'is-invalid': f.submitted && userCreateForm?.controls?.name?.errors }" />
              <div *ngIf="f.submitted && userCreateForm?.controls?.name?.errors" class="invalid-feedback">
                  <div *ngIf="f.submitted && userCreateForm?.controls?.name?.errors">champ obligatoire</div>
              </div>
            </div>

            <div  class="form-group row">
                <label class="form-control-label" for="tel1"
                    >Telephone 1 :</label>
                <input type="text" class="form-control" id="tel1" name="tel1"
                [ngClass]="{ 'is-invalid': f.submitted && userCreateForm?.controls?.tel1?.errors }"
                 formControlName="tel1" />
                <div *ngIf="f.submitted && userCreateForm?.controls?.tel1?.errors " class="text-danger"> champ
                    obligatoire
                </div>
            </div>
            <div  class="form-group row">
                <label class="form-control-label" for="tel2"
                  >Telephone 2 :</label>
                <input type="text" class="form-control" id="tel2" name="tel2"
                 formControlName="tel2" />
            </div>

            <div class="m-2">
                <button type="button" id="cancel-save" class="btn btn-secondary" data-dismiss="modal"
                    (click)="close();">
                    &nbsp;<span>Annuler</span>
                </button>

                &nbsp;&nbsp;
                <button type="submit" id="save-entity" class="btn btn-primary button titre">
                    &nbsp;<span>Valider</span>
                </button>
            </div>
        </form>
    </div>
</div>
</div>
