import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Role } from 'src/app/class/role';
import { AlertService } from 'src/app/services/alert-service.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { FormChangeService } from 'src/app/services/form-change.service';
import { TenantService } from 'src/app/services/tenant.service';
import { InterphoneService } from '../interphone.service';

@Component({
  selector: 'app-interphone-create',
  templateUrl: './interphone-create.component.html',
  styleUrls: ['./interphone-create.component.scss']
})
export class InterphoneCreateComponent implements OnInit {
  userCreateForm: FormGroup;
  submitted = false;
  searchText;
  customers: {};
  customerId;
  tenantId;
  selectedCivilite: string = null;
  userForm: any;
  selectedCustomer;
  roles = new Array();
  selectedRoles = new Array();
  selectedTenant: any[];
  tenants: Object;
  constructor(
    protected interphoneSrv: InterphoneService,
    private fb: FormBuilder,
    private tenantServ: TenantService,
    protected alertService: AlertService,
    private formService: FormChangeService,
    public authenSer: AuthenticationService,
    public activeModal: NgbActiveModal) {

      Object.keys(Role).forEach(rl =>{
        this.roles.push(Role[rl]);
      });

     }

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.userCreateForm = this.fb.group({

      tenantId: ['', Validators.required],
      label: ['', Validators.required],
      cp: ['', Validators.required],
      city: ['', Validators.required],
      callNumber: ['', Validators.required],
      adress: ['', [Validators.required]],
      username: [''],
      password:[''],
      hostUrl:['']
    });

    this.rechercher();
  }
  // convenience getter for easy access to form fields
  get f() { return this.userCreateForm.controls; }



  private createFromForm() {
    
    let tmp = {
      label: this.userCreateForm.get(['label']).value,
      tenantId: this.authenSer.isSupperAdmin?this.userCreateForm.get(['tenantId']).value:this.authenSer.getCurrentUser().tenantId,
      cp: this.userCreateForm.get(['cp'])!.value,
      city: this.userCreateForm.get(['city'])!.value,
      callNumber: this.userCreateForm.get(['callNumber'])!.value,
      adress: this.userCreateForm.get(['adress'])!.value,
      active: true,
      username: this.userCreateForm.get(['username'])!.value,
      password: this.userCreateForm.get(['password'])!.value,
      hostUrl: this.userCreateForm.get(['hostUrl'])!.value
    };
    return tmp;
  }

  onSubmit(): void {
    this.submitted = true;

    if (this.userCreateForm.invalid) {
      return;
    } else {
      this.userForm = this.createFromForm();
      
      this.interphoneSrv.createInterphone(this.userForm).subscribe(
        (data) => {
          this.alertService.success('Interphone sauvegardé !', true);
          this.formService.raiseInterphonesSearchRequestEvent();
          this.close();
        },
        (error) =>  {
          this.alertService.error('Erreur dans le formulaire !', true);
        }
      );
    }
  }

  rechercher() {
    if(this.authenSer.isSupperAdmin){
      this.getTenantsFromBackEnd()
      .subscribe(
        data => {
          let tmp = data as Array<any>;
          this.tenants = data;
        }
        , error => {
          console.log(error);
        });
    }
  }

  // getCustomersFromBackEnd(filter) {
  //   return this.customerService.getCustomers();
  // }

  getTenantsFromBackEnd() {
    return this.tenantServ.getTenants();
  }

  close(): void {
    this.activeModal.close();
  }

}
