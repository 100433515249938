<div role="document" class="modal-dialog">
  <div class="modal-content">
    <div class="modal-header">
      <h2 id="prep-file-heading">Modifiez un utilisateur</h2>
    </div>

    <div class="modal-body">
      <div class="modal-body">
        <form class="col-10" name="userUpdateForm" role="form" novalidate (ngSubmit)="onSubmit()"
          #f="ngForm" [formGroup]="userUpdateForm">
          <ng-container *ngIf="this.authenSer.isSupperAdmin">
            <div class="form-group ">Saisissez un tenant :
              <ng-select class="mb-2" dropdownPosition='bottom' placeholder="Tenant" [items]="tenants"
                [multiple]="false"
                [ngClass]="{ 'forminput-invalid':f.submitted && userUpdateForm?.controls?.tenantId?.errors }"
                [bindLabel]="'companyName'" [closeOnSelect]="true" formControlName="tenantId"
                bindValue="id" name="selectedTenant">
                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                  {{item.companyName}}
                </ng-template>
              </ng-select>
            </div>
            <div class="form-group ">Niveau d'accès :
              <ng-select class="mb-2" [ngModelOptions]="{standalone: true}" dropdownPosition='bottom' placeholder=""
                [items]="roles" [multiple]="true" [bindLabel]="'value'" [closeOnSelect]="false" bindValue="key"
                [(ngModel)]="selectedRoles" name="selectedRoles">
                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                  {{item.value}}
                </ng-template>
              </ng-select>
            </div>
          </ng-container>
          <div class="form-group row">
            <label class="form-control-label" for="field_firstname"
              >Prénom</label>
              <input type="text" class="form-control" id="field_firstname" name="firstname"
                    [ngClass]="{ 'is-invalid': f.submitted && userUpdateForm?.controls?.firstname?.errors }"
                     formControlName="firstname" />
                    <div *ngIf="f.submitted && userUpdateForm?.controls?.firstname?.errors " class="text-danger"> champ
                        obligatoire
                    </div>
          </div>

          <div class="form-group row">
            <label class="form-control-label" for="field_name">Nom</label>
            <input type="text" class="form-control" id="field_name" name="name"
                    [ngClass]="{ 'is-invalid': f.submitted && userUpdateForm?.controls?.name?.errors }"
                     formControlName="name" />
                    <div *ngIf="f.submitted && userUpdateForm?.controls?.name?.errors " class="text-danger"> champ
                      obligatoire
                  </div>
          </div>

          <div class="form-group row">

            <label class="form-control-label">Email</label>

            <input type="text" formControlName="email" class="form-control"
                        [ngClass]="{ 'is-invalid': f.submitted && userUpdateForm?.controls?.email?.errors }" />
            <div *ngIf="f.submitted && userUpdateForm?.controls?.email?.errors" class="invalid-feedback">
                <div *ngIf="f.submitted && userUpdateForm?.controls?.email?.errors">Email obligatoire</div>
                <div *ngIf="f.submitted && userUpdateForm?.controls?.email?.errors?.email">adresse invalide</div>
            </div>

          </div>
          <ng-container *ngIf="!this.authenSer.isSupperAdmin">
          <div class="form-group row">
            <div class="col-3">
              <div class="form-group  align-items-center">
                <div class="custom-control custom-switch">
                <input type="checkbox" class="custom-control-input" id="switch1" formControlName="web">
                <label class="custom-control-label" for="switch1" >Web</label>
              </div>
              </div>
            </div>
          </div>
          <div  class="form-group row">
            <div class="col-3">
              <div class="form-group  align-items-center">
                <div class="custom-control custom-switch">
                <input type="checkbox" class="custom-control-input" id="switch2" formControlName="mobile">
                <label class="custom-control-label" for="switch2" >Mobile</label>
              </div>
              </div>
            </div>
        </div>
      </ng-container>
          <div class="form-group row">
            <label class="form-control-label" for="field_login">Identifiant</label>
            <input type="text" class="form-control" id="field_login" name="login" formControlName="login" readonly
                [ngClass]="{ 'is-invalid': f.submitted && userUpdateForm?.controls?.login?.errors }" />
            <div *ngIf="f.submitted && userUpdateForm?.controls?.login?.errors" class="invalid-feedback">
                <div *ngIf="f.submitted && userUpdateForm?.controls?.login?.errors">Identifiant obligatoire</div>
            </div>
          </div>
          <div class="d-flex justify-content-center">
            <button type="button" id="cancel-save" class="btn btn-danger" data-dismiss="modal" (click)="resetPwd();">
              &nbsp;<span>reset mot de passe</span>
            </button>
          </div>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

          <div class="m-2">
            <button type="button" id="cancel-save" class="btn btn-secondary" data-dismiss="modal" (click)="close();">
              &nbsp;<span>Annuler</span>
            </button>

            &nbsp;&nbsp;
            <button type="submit" id="save-entity" class="btn btn-primary button titre">
              &nbsp;<span>Valider</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
