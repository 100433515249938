<div role="document" class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h2 id="prep-file-heading"> Camera : {{camera.label}}</h2>
      </div>
  
      <div class="modal-body">
        <!-- <iframe width="100%" height="315" [attr.src]='sanitizer.bypassSecurityTrustUrl(source)' frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe> -->
        <canvas id='canvas'></canvas>
      </div>
      <div class="modal-footer">
        <div class="m-2">
          <button type="button" id="cancel-save" class="btn btn-secondary" data-dismiss="modal" (click)="close();">
            &nbsp;<span>Fermer</span>
          </button>
        </div>
    </div>
    </div>
  </div>
  