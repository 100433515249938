import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  apiUrl = environment.apiUrl;

  constructor(private http: HttpClient, private authenSrv: AuthenticationService) { }

  notifications(){
    let url= this.apiUrl+ this.authenSrv.getUserApiTargetUrl() +'/notificationConfigs';
    return this.http.get<any>(url,{withCredentials: true});
  }
  saveNotification(prospect) {
    const url = this.apiUrl  + this.authenSrv.getUserApiTargetUrl() + '/notificationConfigs';
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    return this.http.post(url, prospect, { withCredentials: true, responseType:'text'});
  }

  putNotification(prospect) {
      const url = this.apiUrl  + this.authenSrv.getUserApiTargetUrl() +'/notificationConfigs';
      let headers = new HttpHeaders();
      headers = headers.append('Content-Type', 'application/json');
      return this.http.put(url, prospect, { withCredentials: true });
  }
  createNotification(notification){
    let url = this.apiUrl + this.authenSrv.getUserApiTargetUrl() +'/notificationConfigs';

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(url,notification,{headers , responseType: "text", withCredentials: true})
  }
  notificationsByInstallation(id){
    let url =environment.apiUrl+ this.authenSrv.getUserApiTargetUrl() +'/notificationConfigs/digicode/'+id+'/notificationConfigs';
    return this.http.get<any>(url,{withCredentials: true});
  }
  deleteNotification(id){

    let url =environment.apiUrl+ this.authenSrv.getUserApiTargetUrl() +'/notificationConfigs/'+id;
    return this.http.delete<any>(url,{withCredentials: true});
  }
}
