import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from './authentication.service';
const apiUrl = environment.apiUrl;
@Injectable({
  providedIn: 'root'
})
export class CreditCardService {
  getCustomerCard() {
    const url = apiUrl + this.authenSrv.getUserApiTargetUrl() +'/payments/card';
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    return this.http.get(url);
  }
  getCusstomerPaymentMethods(){
    const url = apiUrl + this.authenSrv.getUserApiTargetUrl() +'/payments/paymentMethods';
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    return this.http.get(url);
  }
  getCustomerIBAN() {
    const url = apiUrl + this.authenSrv.getUserApiTargetUrl() +'/payments/iban';
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    return this.http.get(url);
  }
  constructor(private http: HttpClient, private authenSrv: AuthenticationService) { }

  getPublicKey() {
    const url = apiUrl + this.authenSrv.getUserApiTargetUrl() +'/payments/publicKeyJson';
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    return this.http.get(url);
  }
  registerCard(paymentMethod){
    const formData: FormData = new FormData();
    formData.append('paymentMethodId', paymentMethod);
    let url = apiUrl + this.authenSrv.getUserApiTargetUrl() +'/payments/registerCard';
    let headers = new HttpHeaders();
    return this.http.post(url, formData, { headers, withCredentials: true });
  }
  registerIban(id,data){
    const formData: FormData = new FormData();
    formData.append('sourceId', id);
    formData.append('name', data.name);
//    formData.append('iban', data.iban);
    formData.append('mandateUrl', data.mandateUrl);
    formData.append('cp', data.cp);
    formData.append('city', data.city);
//    formData.append('bic', data.bic);
    formData.append('adress', data.adress);
    let url = apiUrl + this.authenSrv.getUserApiTargetUrl() +'/payments/registerIban';
    let headers = new HttpHeaders();
    return this.http.post(url, formData, { headers, withCredentials: true });
  }
}
