import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, NgForm } from '@angular/forms';
import { AlertService } from '../services/alert-service.service';
import { AuthenticationService } from '../services/authentication.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormChangeService } from '../services/form-change.service';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { AccessService } from '../services/access.service';
import { DigiCodService } from '../services/digicod.service';
import { EquipmentService } from '../services/equipement.service';
import { ModalRASComponent } from '../modal/modalRAS.component';
import { ModalComponent } from '../modal/modal.component';
import { BadgeService } from '../services/badge.service';
import { VehiculeService } from '../services/vehicule.service';
import { HttpParams } from '@angular/common/http';
import { ReaderService } from '../services/reader.service';
import { CameraService } from '../services/camera.service';

@Component({
  selector: 'app-access-generation',
  templateUrl: './access-generation.component.html',
  styleUrls: ['./access-generation.component.scss']
})
export class AccessGenerationComponent implements OnInit {
  accessCreateForm: FormGroup;
  selectedCivilite: string = null;
  employeeToSave: any;
  emailInvalid: boolean;
  numSsInvalid: boolean;
  valeurAcorriger = [];
  cpInvalid: boolean;
  lstSites = [];
  lstCustomers =  [];
  keys = Object.keys;
  startingDate = new Date();

  constructor(
    private equipmentService: EquipmentService,
    private badgeService: BadgeService,
    private readerService: ReaderService,
    private vehiculeService: VehiculeService,
    private cameraService: CameraService,
    private digicodService: DigiCodService,
    private accessService: AccessService,
    private fb: FormBuilder,
    protected alertService: AlertService,
    public authenSer: AuthenticationService,
    public activeModal: NgbActiveModal,
    private formService: FormChangeService,
    private modalService: NgbModal,
    private route: Router
  ) {}
  dateRange = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });
  accessKey: string;
  emAccessKey: string;
  degicods = new Array();
  equipments = new Array();
  vehicules = new Array();
  cameras = new Array();
  badges = new Array();
  readers = new Array();
  datepickerStart;
  datepickerEnd;
  minDateValue = new Date();
  displayModal= 'none';
  accessKeypersonalise;
  ngOnInit() {

    this.digicodService.getDigicodFromBack().subscribe(
      data => {
      this.degicods = data;
    },
    error => {
      let msg
      if(error.error.message){
        msg = error.error.message;
      }else if(error.message){
        msg =error.message;
      }else{
        console.log("erreur :");
        console.log(JSON.parse(error));
        msg = "erreur Non parsable, ouvez la console";
      }
      this.alertService.error(msg);

    });
    this.equipmentService.getEquipmentsFromBack().subscribe(
      data => {
      this.equipments = data;
    },
    error => {
      let msg
      if(error.error.message){
        msg = error.error.message;
      }else if(error.message){
        msg =error.message;
      }else{
        console.log("erreur :");
        console.log(JSON.parse(error));
        msg = "erreur Non parsable, ouvez la console";
      }
      this.alertService.error(msg);

    });
    
    this.readerService.getReadersFromBack().subscribe(
      data => {
      this.readers = data;
    },
    error => {
      let msg
      if(error.error.message){
        msg = error.error.message;
      }else if(error.message){
        msg =error.message;
      }else{
        console.log("erreur :");
        console.log(JSON.parse(error));
        msg = "erreur Non parsable, ouvez la console";
      }
      this.alertService.error(msg);
    });

    this.vehiculeService.getVehicules().subscribe(
      data => {
      this.vehicules = data;
    },
    error => {
      let msg
      if(error.error.message){
        msg = error.error.message;
      }else if(error.message){
        msg =error.message;
      }else{
        console.log("erreur :");
        console.log(JSON.parse(error));
        msg = "erreur Non parsable, ouvez la console";
      }
      this.alertService.error(msg);
    });
    
    this.cameraService.get().subscribe(
      data => {
      this.cameras = data;
    },
    error => {
      let msg
      if(error.error.message){
        msg = error.error.message;
      }else if(error.message){
        msg =error.message;
      }else{
        console.log("erreur :");
        console.log(JSON.parse(error));
        msg = "erreur Non parsable, ouvez la console";
      }
      this.alertService.error(msg);
    });

    this.badgeService.getBadges().subscribe(
      data => {
      this.badges = data;
    },
    error => {
      let msg
      if(error.error.message){
        msg = error.error.message;
      }else if(error.message){
        msg =error.message;
      }else{
        console.log("erreur :");
        console.log(JSON.parse(error));
        msg = "erreur Non parsable, ouvez la console";
      }
      this.alertService.error(msg);
    });
    
    this.initForm();
  }
  
  validate(form: NgForm) {
    let val = form.value;
    let otherError = new Array<string>() ;
    let dateS;
    let dateE;
      if (val.startEffect) {
         dateS = moment(val.startEffect.getTime());
      }
      if (val.endEffect) {
        dateE = moment(val.endEffect.getTime());
      }

      if (dateE && dateS && dateS.isAfter(dateE)) {
        otherError.push('Incoherence dans les dates saisies');
      }

    if (otherError.length >0) {
      this.alertService.error("Champs requis : "+otherError.join(', '), false);
      return false;
    }
    return true;
  }
  closeModalDialog(){
    this.displayModal='none'; //set none css after close dialog
   }

  initForm() {
    this.accessCreateForm = this.fb.group({
      accessMode: new FormControl("code"),
      digicodeIds: new FormControl(""),
      mode :'ONE_ACCESS',
      accessCode: new FormControl(""),
      endEffect: new FormControl(""),
      startEffect: new FormControl(""),
      dureeEffect: new FormControl(""),
      equipmentIds: new FormControl(""),

      vehiculeIds: new FormControl(""),
      cameraIds: new FormControl(""),

      badgeIds: new FormControl(""),
      readerIds: new FormControl(""),

      equipmentId: new FormControl(""),
      
      reference: new FormControl(""),
      uniqueUsage: new FormControl("yes"),
      digit: new FormControl(""),
      tel: new FormControl(""),
      timeStart: new FormControl({hour: 0, minute: 0}),
      timeEnd:	new FormControl({hour: 0, minute: 0}),
    });
  }
  switchChange() {
    let modeValue=  this.accessCreateForm.get(["accessMode"]).value;
    if(modeValue != 'vehicule'){
      this.accessCreateForm.patchValue({
        vehiculeIds: '',digicodeIds:'',
        cameraIds:'',
      });
    }
    if(modeValue != 'badge'){
      this.accessCreateForm.patchValue({
        badgeIds: '',digicodeIds:'',
        readerIds: '',
      });
    }
    if(modeValue != 'code'){
      this.accessCreateForm.patchValue({
        digicodeIds:'',
        digit: '',
        accessCode: '',
      });
    }
    if(modeValue != 'phone'){
      this.accessCreateForm.patchValue({
        digicodeIds:'',
        digit: '',
        accessCode: '',
      });
    }
    if(modeValue != 'phone'){
      this.accessCreateForm.patchValue({
        tel:'',
      });
    }
    
  }
  onChangedateEffect(event){
    let dateS = this.accessCreateForm.get(["startEffect"]).value ? moment(this.accessCreateForm.get(["startEffect"]).value!.getTime()) : null;
    let dateE = this.accessCreateForm.get(["endEffect"]).value ? moment(this.accessCreateForm.get(["endEffect"]).value!.getTime()) : null;
    if(dateS && dateE){
      this.accessCreateForm.patchValue({
        dureeEffect: dateE.diff(dateS,'minutes') + 1,
      });
    }
  }

  refrechDateS(){
    this.accessCreateForm.patchValue({
      startEffect: moment().toDate(),
    });
    this.onChangePeriodEffect(null);
  }
  onChangePeriodEffect(event){
    let dateS = this.accessCreateForm.get(["startEffect"]).value ? moment(this.accessCreateForm.get(["startEffect"]).value.getTime()) : null;
    let duree = this.accessCreateForm.get(["dureeEffect"]).value ? this.accessCreateForm.get(["dureeEffect"]).value : null;
    if (dateS && duree){
      this.accessCreateForm.patchValue({
        endEffect: dateS.add(duree,'minutes').toDate(),
      });

    }
  }

  save(f){

    if(!this.validate(f)) return ;
    let  mode = this.accessCreateForm.get(["mode"]).value;
    let selectedEquipements  = this.accessCreateForm.get(["equipmentIds"]).value;
    // let selectedEquipement  = this.accessCreateForm.get(["equipmentId"]).value;

    let selectedVehicules  = this.accessCreateForm.get(["vehiculeIds"]).value;
    let selectedCameras = this.accessCreateForm.get(["cameraIds"]).value;

    let selectedBadges  = this.accessCreateForm.get(["badgeIds"]).value;
    let selectedReaders = this.accessCreateForm.get(["readerIds"]).value;

    let selectedDegic = this.accessCreateForm.get(["digicodeIds"]).value;
    let reference = this.accessCreateForm.get(["reference"]).value;
    let accessCode = this.accessCreateForm.get(["accessCode"]).value;
    let dateS = this.accessCreateForm.get(["startEffect"]).value ? moment(this.accessCreateForm.get(["startEffect"]).value.getTime()) : null;
    let dateE = this.accessCreateForm.get(["endEffect"]).value ? moment(this.accessCreateForm.get(["endEffect"]).value.getTime()) : null;
    let digit = this.accessCreateForm.get(["digit"]).value;
    let tel = this.accessCreateForm.get(["tel"]).value;
    let accessMode = this.accessCreateForm.get(["accessMode"]).value;
    let timeStartH = this.accessCreateForm.get(['timeStart']).value.hour;
    let timeStartM = this.accessCreateForm.get(['timeStart']).value.minute;
    let timeEndH = this.accessCreateForm.get(['timeEnd']).value.hour;
    let timeEndM = this.accessCreateForm.get(['timeEnd']).value.minute;
    let param = this.buildParam(reference, dateS != null ? dateS.format("DD/MM/YYYY HH:mm:ss") : null,
     dateE != null ? dateE.format("DD/MM/YYYY HH:mm:ss") : null,
      selectedDegic, mode, selectedEquipements,
      selectedVehicules, selectedCameras,
      selectedBadges, selectedReaders,
      this.accessCreateForm.get(["uniqueUsage"]).value == 'yes'?true: false,
      accessCode, digit,tel, timeStartH,timeStartM, timeEndH, timeEndM);
    this.accessService.generateAccess(accessMode, param).subscribe(
        data => {
          this.accessKey = data;
          const modalRef = this.modalService.open(ModalComponent, {
            backdrop: "static",
          });
          modalRef.componentInstance.message = 'Code accès créé : <b>' + data + '</b>';
          modalRef.componentInstance.title = 'Code Accès';
          modalRef.result.then((result) => {
          });

        },
        error => {
          let msg;
          if(error.error.message){
            msg = error.error.message;
          }else if(error.message){
            msg =error.message;
          }else{
            console.log("erreur :");
            console.log(JSON.parse(error));
            msg = "erreur Non parsable, ouvez la console";
          }
          this.alertService.error(msg);
        });
  }
  
  private buildParam(reference: string, dateDebut: string,
     dateFin: string, digicodeIds: any[], mode: string,
     equipmentIds: any[],
     selectedVehicules: any[],selectedCameras: any[],
     selectedBadges: any[], selectedReaders: any[],
     uniqueUsage: boolean, accessCodepersonalise: string,
     digit: string,
     tel: string,
     timeStartH: string,
     timeStartM: string,
     timeEndH: string,
     timeEndM: string,
     ) {
    let params = new HttpParams();
    let modeValue=  this.accessCreateForm.get(["accessMode"]).value;
    
    if (reference != undefined) {
      params = params.append("reference", reference);
    }
    if (dateDebut != null) {
      params = params.append("startEffect", dateDebut);
    }
    if (dateFin != null) {
      params = params.append("endEffect", dateFin);
    }
    if (timeStartH != null) {
      params = params.append("hourStart", timeStartH);
    }
    if (timeStartM != null) {
      params = params.append("minStart", timeStartM);
    }
    if (timeEndH != null) {
      params = params.append("hourEnd", timeEndH);
    }
    if (timeEndM != null) {
      params = params.append("minEnd", timeEndM);
    }
    if('code' == modeValue){
      if (mode != null) {
        params = params.append("mode", mode);
      }
      if (digicodeIds.length > 1){
        
          digicodeIds.forEach((element) => {
            params = params.append("digicodeIds", element);
          });
        } else {
          params = params.append("digicodeIds", digicodeIds[0]);
        }
        if (digit != null) {
          params = params.append("digit", digit);
        }
        if (accessCodepersonalise != null) {
          params = params.append("accessCode", accessCodepersonalise);
        }
    } 
    if (equipmentIds.length > 1) {
      equipmentIds.forEach((element) => {
        params = params.append("equipmentIds", element);
      });
    } else {
      params = params.append("equipmentIds", equipmentIds[0]);
    }
    if('phone' == modeValue){
      params = params.append("tel", tel);
    }
    if('vehicule' == modeValue){
      if(selectedVehicules.length > 1){
          selectedVehicules.forEach((element) => {
            params = params.append("vehicleIds", element);
          });
        } else {
          params = params.append("vehicleIds", selectedVehicules[0]);
        }
      if(selectedCameras.length > 1){
        selectedCameras.forEach((element) => {
          params = params.append("cameraIds", element);
        });
      } else {
        params = params.append("cameraIds", selectedCameras[0]);
      }
      
    }
    if('badge' == modeValue){
        if(selectedBadges.length > 1){
          selectedBadges.forEach((element) => {
            params = params.append("badgeIds", element);
          });
        } else {
          params = params.append("badgeIds", selectedBadges[0]);
        }
        if(selectedReaders != null){
          if (selectedReaders.length > 1){
            selectedReaders.forEach((element) => {
              params = params.append("readerIds", element);
            });
          } else {
            params = params.append("readerIds", selectedReaders[0]);
          }
        }
    }
    
    
    if (uniqueUsage != null) {
      params = params.append("uniqueUsage", uniqueUsage.toString());
    }
    
    
    return params;
  }
}
