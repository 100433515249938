export enum unit {
    heure,
    km,
    '€',
    nb,
    jour
}
export enum unitType {
    heure,
    jour
}

export enum roleEnum {
    ROLE_USER,
    ROLE_ADMIN,
    ROLE_SUPER_ADMIN,
    ROLE_WEB,
    ROLE_MOBILE,
    ROLE_INTERPHONE,
    ROLE_DIGICODE,
    ROLE_BADGE
}

export enum AccessMode {
  EDITION = 'EDITION',
  CONSULTATION = 'CONSULTATION'
}

export enum Civilitee {
  Madame = 'MADAME',
  Mademoiselle = 'MADEMOISELLE',
  Monsieur = 'MONSIEUR'
}

export enum typeEvent
{
  EMERGENCY= 'EMERGENCY',
  ACCESS_SUCCESS= 'ACCESS_SUCCESS',
  GENERATE= 'GENERATE',
  ACCESS_FAILED='ACCESS_FAILED',
  ALREADY_CONSUMED='ALREADY_CONSUMED',
  SECURITY_ATTEMPT='SECURITY_ATTEMPT',
  REVOKE='REVOKE',
  LOCK= "LOCK",
  OUT_DATED='OUT_DATED',
  OPEN_REMOTLY= "OPEN_REMOTLY"
}
