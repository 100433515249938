import { Component, OnInit, OnChanges, Input, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, NgModel } from '@angular/forms';
import { AlertService } from 'src/app/services/alert-service.service';
import { FormChangeService } from 'src/app/services/form-change.service';
import { AccessService } from 'src/app/services/access.service';
import { DigiCodService } from 'src/app/services/digicod.service';
import { Router } from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'app-form-min-manage-access',
  templateUrl: './form-min-manage-access.component.html',
  styleUrls: ['./form-min-manage-access.component.scss']
})
export class FormMinManageAccessComponent implements OnInit,OnChanges {
  dateRange = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });
  searchText: string;
  model: NgModel;
  constructor(
    private alertService: AlertService,
    private formService: FormChangeService,
    private accessServ: AccessService,
    private digicodServ: DigiCodService,
    public router: Router) {
  }
  ngOnChanges(changes: SimpleChanges): void {

    this.rechercher();
  }


  ngOnInit() {

    this.rechercher();
    this.subscribeReloadSearchRequest();
  }

  subscribeReloadSearchRequest() {
    this.formService.reloadAccessRequest.subscribe(
      () => {
        this.rechercher();
      });
  }
  clearForm() {
    this.rechercher();
  }
  private findTextInProspect(prospects) {
    let filter = this.getTextFilter();
    let prospectFiltred = prospects;

    if (filter) {
      let text = filter.split(' ');
      prospectFiltred = prospects.filter(function (item) {
        let flt = text.every(function (el) {
          let tmp = (item['accessCode'] && item['accessCode'].toUpperCase().indexOf(el.toUpperCase()) > -1)
            || (item['type'] && item['type'].toUpperCase().indexOf(el.toUpperCase()) > -1)
            || (item['reference'] && item['reference'].toUpperCase().indexOf(el.toUpperCase()) > -1)
            || (item['reference'] && item['reference'].toUpperCase().indexOf(el.toUpperCase()) > -1)
            || (item['readerId'] && item['readerId'].toUpperCase().indexOf(el.toUpperCase()) > -1);
            return tmp;
        });

        return flt;
      });

    }
    prospectFiltred = this.filterPrspectDateRange(prospectFiltred);
    return prospectFiltred;
  }
  filterPrspectDateRange(prospectFiltred: Array<any>): any {
    let tmp = prospectFiltred;
    if(this.dateRange.get('start').value){
      let start = moment(this.dateRange.get('start').value).startOf('day');
        tmp = tmp.filter(element => {
          let dateCreation = element.dateCreation?moment(element.dateCreation): null;
          return dateCreation && dateCreation.startOf('day').isSameOrAfter(start);
        });
      }
      if(this.dateRange.get('end').value){
        let end = moment(this.dateRange.get('end').value).startOf('day');
          tmp = tmp.filter(element => {
            let dateCreation = element.dateCreation?moment(element.dateCreation): null;
            return dateCreation && dateCreation.startOf('day').isSameOrBefore(end);
          });
      }
    return tmp;
  }

  private getTextFilter() {
    return this.searchText;
  }
  rechercher() {
    this.getLogsBackEnd();
  }

  onFormChange() {
    this.rechercher();
  }

  getLogsFromBackEnd() {

    return this.accessServ.getAllAcces();
  }
  getLogsBackEnd() {

    this.getLogsFromBackEnd()
      .subscribe(
        (data) => {
          let tmpUsers = data as Array<any>;
           tmpUsers = this.findTextInProspect(tmpUsers);
          this.formService.raiseAccessListEvent(tmpUsers);
        }
        , (error) => {
          console.log(error);
          this.formService.raiseAccessListEvent([]);
        });
  }
}
