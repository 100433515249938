import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { ProspectService } from 'src/app/services/prospect.service';
import { AlertService } from 'src/app/services/alert-service.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormChangeService } from 'src/app/services/form-change.service';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { MomentPipe } from 'src/app/moment-pipe.pipe';
import { BadgeService } from 'src/app/services/badge.service';
import { TenantService } from 'src/app/services/tenant.service';
import { Observable } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { VehiculeService } from 'src/app/services/vehicule.service';

@Component({
  selector: 'app-vehicule-update',
  templateUrl: './vehicule-update.component.html',
  styleUrls: ['./vehicule-update.component.scss']
})
export class VehiculeUpdateComponent implements OnInit {

  userUpdateForm: FormGroup;
  tenantId;
  userForm: any;
  selectedCustomer;
  selectedTenant;
  tenants;
  vehicule: any;
  constructor(
    private fb: FormBuilder,
    private vehiculeService: VehiculeService,
    protected alertService: AlertService,
    private formService: FormChangeService,
    public authenSer: AuthenticationService,
    public activeModal: NgbActiveModal,
    private tenantServ: TenantService) { }

  ngOnInit(): void {
    this.initForm();
    this.updateForm(this.vehicule);
    this.rechercher();
  }

  initForm() {
    this.userUpdateForm = this.fb.group({
      id: [],
      tenantId: [],
      label: [],
      desc: [],
      holderFirstName: [],
      holderName: [],
      immatriculation: [],
      active: [],
    });

  }
  rechercher() {
    if(this.authenSer.isSupperAdmin){
      this.getTenantsFromBackEnd()
      .subscribe(
        data => {
          let tmp = data as Array<any>;
          this.tenants = data;
        }
        , error => {
          console.log(error);
        });
    }
  }

  getTenantsFromBackEnd() {
    return this.tenantServ.getTenants();
  }

  get f() { return this.userUpdateForm.controls; }



  private createFromForm(): any {
    return {
      id: this.userUpdateForm.get(['id']).value,
      desc: this.userUpdateForm.get(['desc']).value,
      tenantId: this.userUpdateForm.get(['tenantId']).value,
      label: this.userUpdateForm.get(['label'])!.value,
      holderFirstName: this.userUpdateForm.get(['holderFirstName'])!.value,
      holderName: this.userUpdateForm.get(['holderName'])!.value,
      immatriculation: this.userUpdateForm.get(['immatriculation'])!.value,
      active: this.userUpdateForm.get(['active'])!.value,
    };
  }
  // dans le cas de l'update on récupère les données pour les afficher
  updateForm(veh: any): void {
    
    this.userUpdateForm.patchValue({
      id: veh.id,
      tenantId: veh.tenantId,
      label: veh.label,
      desc: veh.desc,
      holderFirstName: veh.holderFirstName,
      holderName: veh.holderName,
      immatriculation: veh.immatriculation,
      active: veh.active,
    });
    this.tenantId = veh.tenantId;
    
  }


  onSubmit(): void {
    if (this.userUpdateForm.invalid) {
      return;
    } else {
      this.userForm = this.createFromForm();
      this.subscribeToSaveResponse(this.vehiculeService.update(this.userForm));

    }

  }

  close(): void {
    this.activeModal.close();
  }

  protected subscribeToSaveResponse(result: Observable<HttpResponse<any>>): void {
    result.subscribe(
      (result) => this.onSaveSuccess(result),
      (result) => this.onSaveError(result)
    );
  }



  protected onSaveSuccess(result): void {
    this.alertService.success('Vehicule sauvegardé !', false);
    this.close();
    this.formService.raiseReloadVehiculesSearchRequestEvent();
  }

  protected onSaveError(result): void {
    this.alertService.error('Erreur dans le formulaire !', false);
  }
}
