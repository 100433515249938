<app-form-min-interphone></app-form-min-interphone>
<ng-container >
  <div class="d-flex">
    <div class="col-12 my-1">
      <button id="create-variable" class="btn btn-primary float-right" type="submit" (click)="create()">
        <span>
          Ajouter
        </span>
      </button>
    </div>
  </div>
</ng-container>
<div class="basic-container" style="margin-top: 10px;">
  <table mat-table matSort [dataSource]="lstInterphone" multiTemplateDataRows class="mat-elevation-z8">
    <ng-container matColumnDef="expandIcon">
      <th mat-header-cell *matHeaderCellDef> </th>
      <td mat-cell *matCellDef="let element">
        <mat-icon (click)="expandedElement = expandedElement === element ? null : element">
          {{expandedElement === element ? 'expand_less' : 'expand_more'}}
        </mat-icon>
      </td>
    </ng-container>
    <ng-container matColumnDef="label">
      <th mat-header-cell *matHeaderCellDef> label </th>
      <td mat-cell *matCellDef="let element">
      <span >
          {{element.label}}
      </span>
    </td>
    </ng-container>
    <ng-container matColumnDef="adress">
      <th mat-header-cell *matHeaderCellDef> Adresse </th>
      <td mat-cell *matCellDef="let element">
      <span >
          {{element.adress +' ('+element.cp +')'}}
      </span>
    </td>
    </ng-container>
    <ng-container matColumnDef="tephone">
      <th mat-header-cell *matHeaderCellDef> Telephone </th>
      <td mat-cell *matCellDef="let element">
      <span >
          {{element.callNumber}}
      </span>
    </td>
    </ng-container>
    <ng-container matColumnDef="Actions">
      <th mat-header-cell *matHeaderCellDef>  </th>
      <td mat-cell *matCellDef="let element">
         <div class="buttons">
           <!--  -->
           <div class="m-2">
                <span *ngIf="element.rtspUrl" (click)="videoPlay(element)">
                    <i class="fa fa fa-play-circle text-warning" style="cursor: pointer;"
                        aria-hidden="true" title="Video"></i>
                </span>
            </div>
            <div class="m-2">
                <span (click)="editTenant(element)">
                    <i class="fa fa-pencil-square-o text-primary" style="cursor: pointer;"
                        aria-hidden="true" title="Editer"></i>
                </span>
            </div>
          <!-- </ng-container> -->
            <div class="m-2">
              <span (click)="openDesableInterphoneConfirm(element)">
                <i class="fa " [ngClass]="element.active?'fa-ban text-danger':'fa-check text-secondary'" aria-hidden="true" style="cursor: pointer;" [title]="element.active?'Désactiver': 'Activer' "></i>
              </span>
            </div>
            <ng-container *ngIf="this.authenSrv.isSupperAdmin">
              <div class="m-2">
                  <span (click)="openDeleteConfirm(element.id)">
                      <i class="fa fa-trash-o text-danger" style="cursor: pointer;" aria-hidden="true"
                          title="Supprimer"></i>
                  </span>
              </div>
            </ng-container>
          </div>

      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let element; columns: displayedColumns;" class="example-element-row "
      [class.example-expanded-row]="expandedElement === element"
      (click)="expandedElement = expandedElement === element ? null : element">
    </tr>
  </table>
</div>
