<div role="document" class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h2 id="prep-file-heading">Créer une Camera</h2>
      </div>
      <div class="modal-body">
        <div class="modal-body">
          <form class="col-10" name="userCreateForm" role="form" novalidate #f="ngForm" (ngSubmit)="f.form.valid && onSubmit()"
              [formGroup]="userCreateForm">
              <div  class="form-group row">
                <label class="form-control-label">Label</label>
                <input type="text" formControlName="label" class="form-control"
                    [ngClass]="{ 'is-invalid': f.submitted && userCreateForm?.controls?.label?.errors }" />
                <div *ngIf="f.submitted && userCreateForm?.controls?.label?.errors" class="invalid-feedback">
                    <div *ngIf="f.submitted && userCreateForm?.controls?.label?.errors">Champ obligatoire</div>
                </div>
            </div>
              
              <div  class="form-group row">
                <label class="form-control-label">Ip :</label>
                <input type="text" formControlName="ip" class="form-control"
                    [ngClass]="{ 'is-invalid': f.submitted && userCreateForm?.controls?.ip?.errors }" />
                <div *ngIf="f.submitted && userCreateForm?.controls?.ip?.errors" class="invalid-feedback">
                    <div *ngIf="f.submitted && userCreateForm?.controls?.ip?.errors">Champ obligatoire</div>
                </div>
              </div>
              <div  class="form-group row">
                  <label class="form-control-label" for="protocol"
                      >Protocol</label>
                  <input type="text" class="form-control" id="protocol" name="protocol"
                  [ngClass]="{ 'is-invalid': f.submitted && userCreateForm?.controls?.protocol?.errors }"
                   formControlName="protocol" />
                  <div *ngIf="f.submitted && userCreateForm?.controls?.protocol?.errors " class="text-danger"> champ
                      obligatoire
                  </div>
              </div>
  
              <div  class="form-group row">
                  <label class="form-control-label" for="username"
                    >Login</label>
                  <input type="text" class="form-control" id="username" name="username"
                  [ngClass]="{ 'is-invalid': f.submitted && userCreateForm?.controls?.username?.errors }"
                   formControlName="username" />
                  <div *ngIf="f.submitted && userCreateForm?.controls?.username?.errors " class="text-danger"> champ
                    obligatoire
                </div>
              </div>
  
               <div  class="form-group row">
                  <label class="form-control-label">Mot de passe</label>
                  <input type="password" formControlName="pwd" class="form-control"
                      [ngClass]="{ 'is-invalid': f.submitted && userCreateForm?.controls?.pwd?.errors }" />
                  <div *ngIf="f.submitted && userCreateForm?.controls?.pwd?.errors" class="invalid-feedback">
                      <div *ngIf="f.submitted && userCreateForm?.controls?.pwd?.errors">champ obligatoire</div>
                  </div>
              </div>
              <div class="form-group row align-items-center">
                <div class="custom-control custom-switch">
                 <input type="checkbox" class="custom-control-input" id="switch1" formControlName="visible">
                 <label class="custom-control-label" for="switch1" >Visible</label>
               </div>
             </div>
              <div class="form-group row align-items-center">
                <div class="custom-control custom-switch">
                 <input type="checkbox" class="custom-control-input" id="switch2" formControlName="active">
                 <label class="custom-control-label" for="switch2" >Actif</label>
               </div>
             </div>
              <div class="m-2">
                  <button type="button" id="cancel-save" class="btn btn-secondary" data-dismiss="modal"
                      (click)="close();">
                      &nbsp;<span>Annuler</span>
                  </button>
  
                  &nbsp;&nbsp;
                  <button type="submit" id="save-entity" class="btn btn-primary button titre">
                      &nbsp;<span>Valider</span>
                  </button>
              </div>
          </form>
      </div>
  </div>
  </div>
  