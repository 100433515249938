import { Component, OnInit, Input } from "@angular/core";
import { NgbActiveModal, NgbTimeStruct } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-modal",
  templateUrl: "./modal.component.html",
  styleUrls: ["./modal.component.scss"]
})
export class ModalComponent implements OnInit {
  @Input() title = ``;
  @Input() message = ``;
  @Input() okBtnMsg;
  @Input() cancelBtnMsg;
  @Input() employee: any;


  model: any = {};
  listVars: Array<any>;
withCancel = false;
  constructor(
      public activeModal: NgbActiveModal,
  ) { }

  validate() {

      this.activeModal.close(true);
  }
  ngOnInit() {
  }
}
