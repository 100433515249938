<app-form-min-user></app-form-min-user>
<div class="d-flex">
  <div class="col-12 my-1">
    <button id="create-variable" class="btn btn-primary float-right" type="submit" (click)="create()">
      <span>
        Ajouter un utilisateur
      </span>
    </button>
  </div>
</div>
<div class="basic-container table-responsive" style="margin-top: 10px;">
  <table mat-table [dataSource]="lstUsers" multiTemplateDataRows class=" mat-elevation-z8">
    <ng-container matColumnDef="expandIcon">
      <th mat-header-cell *matHeaderCellDef> </th>

      <td mat-cell *matCellDef="let element">
        <div class="flex-nowrap form-inline">
          <span >
              <img *ngIf="element.civilite == this.civiliteeEnum.Monsieur.toString()" alt="Avatar" class="avatar" src="./../../assets/mr_avatar.png">
              <img *ngIf="element.civilite != this.civiliteeEnum.Monsieur.toString()" alt="Avatar" class="avatar" src="./../../assets/mm_avatar.png">
          </span>
          <mat-icon (click)="expandedElement = expandedElement === element ? null : element">
            {{expandedElement === element ? 'expand_less' : 'expand_more'}}
          </mat-icon>
        </div>
      </td>
    </ng-container>
    <!-- Nom -->
    <ng-container matColumnDef="Nom/Prenom">
      <th mat-header-cell *matHeaderCellDef> Nom/Prenom </th>

      <td mat-cell *matCellDef="let element">
      <span >
          {{element.firstname}} {{element.name}}
      </span>
    </td>
    </ng-container>

    <!-- Identifiant -->
    <ng-container matColumnDef="Identifiant">
      <th mat-header-cell *matHeaderCellDef> Identifiant </th>
      <td mat-cell *matCellDef="let element"> {{element.login}} </td>
    </ng-container>

    <!-- Client -->
    <ng-container matColumnDef="Client">
      <th mat-header-cell *matHeaderCellDef> Client </th>
      <td mat-cell *matCellDef="let element"> {{element.customerName}} </td>
    </ng-container>
    <!-- Email -->
    <ng-container matColumnDef="Email">
      <th mat-header-cell *matHeaderCellDef> Email </th>
      <td mat-cell *matCellDef="let element"> {{element.email}} </td>
    </ng-container>
    <!-- last connection -->
    <ng-container matColumnDef="lastConnection">
      <th mat-header-cell *matHeaderCellDef> Dernière connexion </th>
      <td mat-cell *matCellDef="let element">{{element.lastConnection| momentPipe:'DD/MM/YYYY à HH:mm'}} </td>
    </ng-container>
    <!-- Actions -->
    <ng-container matColumnDef="Actions">
      <th mat-header-cell *matHeaderCellDef> </th>
      <td mat-cell *matCellDef="let element">
        <div class="buttons">
          <div class="m-2">
            <span (click)="editUser(element)">
              <i class="fa fa-pencil-square-o text-primary" style="cursor: pointer;" aria-hidden="true"
                title="Editer"></i>
            </span>
          </div>
          <div class="m-2">
            <span (click)="openDeleteConfirm(element.id)">
              <i class="fa fa-trash-o text-danger" style="cursor: pointer;" aria-hidden="true" title="Supprimer"></i>
            </span>
          </div>
        </div>
      </td>
    </ng-container>

    <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
    <ng-container matColumnDef="expandedDetail">
      <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
        <div class="example-element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
          <div class="example-element-descriptions">
            <div>
              <div class="example-element-description">
                <div class="example-element-description__header">
                  <div class="example-element-description__cell"> Adresse </div>
                  <div class="example-element-description__cell"> Telephone </div>
                </div>
                <div class="example-element-description__content">
                  <div class="example-element-description__cell">
                    <span>{{element['adress']}}</span>&nbsp;<span>{{element['cp']}}</span>&nbsp;<span>{{element['city']}}</span>
                  </div>
                  <div class="example-element-description__cell">
                    <span>{{element['tel1']}}</span>&nbsp;<span>{{element['tel2']}}</span></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let element; columns: displayedColumns;" class="example-element-row "
      [class.example-expanded-row]="expandedElement === element"
      (click)="expandedElement = expandedElement === element ? null : element">
    </tr>
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
  </table>
</div>
