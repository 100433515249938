import { CollectionViewer } from '@angular/cdk/collections';
import { DataSource } from '@angular/cdk/table';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { AccessService } from '../access.service';
import { catchError, finalize } from "rxjs/operators";

export class CustomDataSource implements DataSource<any>{

  private todoSubject = new BehaviorSubject<any[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  private countSubject = new BehaviorSubject<number>(0);
  public counter$ = this.countSubject.asObservable();

  constructor(private accessServ: AccessService) { }

  connect(collectionViewer: CollectionViewer): Observable<any[] | readonly any[]> {
    return this.todoSubject.asObservable();
  }
  disconnect(collectionViewer: CollectionViewer): void {
    this.todoSubject.complete();
    this.loadingSubject.complete();
    this.countSubject.complete();
  }
  loadData(accessId= '',sort=null, order= null, pageNumber = 0, pageSize = 10) {
    
    this.loadingSubject.next(true);
    this.accessServ.loadPage(accessId, sort, order, pageNumber, pageSize )
        .pipe(
            catchError(() => of([])),
            finalize(() => this.loadingSubject.next(false))
        )
        .subscribe((result: any) => {
            this.todoSubject.next(result.content);
            this.countSubject.next(result.totalElements);
        }
        );

}
loadFiltredData(data) {
  this.loadingSubject.next(true);
  this.todoSubject.next(data);
  this.countSubject.next(data.length);
}

}
