import { HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { AlertService } from 'src/app/services/alert-service.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { DigiCodService } from 'src/app/services/digicod.service';
import { FormChangeService } from 'src/app/services/form-change.service';
import { UserService } from 'src/app/services/user-service';

@Component({
  selector: 'app-digicod-update',
  templateUrl: './digicod-update.component.html',
  styleUrls: ['./digicod-update.component.scss']
})
export class DigicodUpdateComponent implements OnInit {

  digicodUpdateForm: FormGroup;
  submitted = false;
  userForm: any;
  digicode: any;
  userIds: any;
  constructor(
    private userServ: UserService,
    protected digicodServ: DigiCodService,
    private fb: FormBuilder,
    protected alertService: AlertService,
    private formService: FormChangeService,
    public authenSer: AuthenticationService,
    public activeModal: NgbActiveModal,) { }

  ngOnInit(): void {
    this.userServ.getUser().subscribe(
      data =>{
        this.userIds = data;
        this.userIds.forEach(element => {
          let tmp = '';
          if(element.firstName){
            tmp = element.firstName + ' ';
          }
          if(element.name){
            tmp +=element.name;
          }
          element['fullName'] = tmp
        });
      }
    );
    this.initForm();
    this.updateForm(this.digicode);
  }

  initForm() {
    this.digicodUpdateForm = this.fb.group({
      id: [],
      label: [],
      desc: [],
      active: [],
      gatewayId: [],
      userIds: [],

    });

  }

  get f() { return this.digicodUpdateForm.controls; }

  private createFromForm(): any {
    return {
      label: this.digicodUpdateForm.get(['label']).value,
      desc: this.digicodUpdateForm.get(['desc']).value,
      active: this.digicodUpdateForm.get(['active']).value,
      gatewayId: this.digicode.gatewayId,
      id: this.digicode.id,
      userIds: this.digicodUpdateForm.get(['userIds']).value,

    };
  }
  updateForm(digic: any): void {

    this.digicodUpdateForm.patchValue({
      id: digic.id,
      desc: digic.desc,
      label: digic.label,
      active: digic.active,
      gatewayId: digic.gatewayId,
      userIds: digic.userIds,
    });
  }


  onSubmit(): void {

      this.userForm = this.createFromForm();
      this.subscribeToSaveResponse(this.digicodServ.saveDigicod(this.userForm));

  }


  close(): void {
    this.activeModal.close();
  }

  protected subscribeToSaveResponse(result: Observable<HttpResponse<any>>): void {
    result.subscribe(
      (result) => this.onSaveSuccess(result),
      (result) => this.onSaveError(result)
    );
  }



  protected onSaveSuccess(result): void {
    this.alertService.success('Digicode sauvegardé !', true);
    this.formService.raiseReloadDigicodsSearchRequestEvent();
    this.close();
  }

  protected onSaveError(result): void {
    this.alertService.error('Erreur lors de la modification digicode !', true);
  }
}
