<app-form-min-digicods></app-form-min-digicods>
<div class="d-flex">
  <div class="col-12 my-1">
    <button id="create-variable" class="btn btn-primary float-right" type="submit" (click)="create()">
      <span>
        Ajouter un digicode
      </span>
    </button>
  </div>
</div>
<div class="basic-container" style="margin-top: 10px;">
  <table mat-table matSort [dataSource]="lstDigics" multiTemplateDataRows class="mat-elevation-z8">
    <ng-container matColumnDef="label">
      <th mat-header-cell *matHeaderCellDef> label </th>
      <td mat-cell *matCellDef="let element">
      <span >
          {{element.label}}
      </span>
    </td>
    </ng-container>
    <ng-container matColumnDef="gerer">
      <th mat-header-cell *matHeaderCellDef class="centered"> Gérer </th>
      <td mat-cell *matCellDef="let element" class="centered">
        <span  >
          <button class="btn btn-info btn-lg btn-circle" type="button"  (click)="edit(element)">
              <i class="fa fa fa-cogs" aria-hidden="true"></i>
          </button>
        </span>
       </td>
    </ng-container>
    <ng-container matColumnDef="notification" >
      <th mat-header-cell *matHeaderCellDef class="centered"> Notification </th>
      <td mat-cell *matCellDef="let element" class="centered">
        <span >
          <button class="btn btn-warning btn-lg btn-circle" type="button"  (click)="notification(element)">
              <i class="fa fa-bell" aria-hidden="true"></i></button>
        </span>
       </td>
    </ng-container>
    <ng-container matColumnDef="journal" class="centered">
      <th mat-header-cell *matHeaderCellDef class="centered"> Journal </th>
      <td mat-cell *matCellDef="let element" class="centered">
        <span >
          <button class="btn btn-secondary btn-lg btn-circle" type="button"  (click)="journal(element)">
              <i class="fa fa-history" aria-hidden="true"></i>
          </button>
        </span>
       </td>
    </ng-container> <ng-container matColumnDef="expandedDetail">
      <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
        <div class="example-element-detail">
          <div class="inner-table mat-elevation-z8">

            <table mat-table [dataSource]="[element]" multiTemplateDataRows class="flex mat-elevation-z8">

              <ng-container matColumnDef="dateModification">
                <th mat-header-cell *matHeaderCellDef> Modifié </th>
                <td mat-cell *matCellDef="let element"> {{element.dateModification| momentPipe:'DD/MM/YYYY'}} </td>
              </ng-container>
            </table>

          </div>
        </div>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let element; columns: displayedColumns;" class="example-element-row "
      [class.example-expanded-row]="expandedElement === element"
      (click)="expandedElement = expandedElement === element ? null : element">
    </tr>
  </table>
</div>
