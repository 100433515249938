<div class="d-flex flex-column" >
  <div class=" col-auto">
    <div class="form-group">
      <input type="text" class="form-control" (keydown.enter)="$event.preventDefault()"
      placeholder="Rechercher dans la Map" autocorrect="off" autocapitalize="off"
      spellcheck="off" type="text" #search>
      <ng-container *ngIf="">

      </ng-container>
    </div>
  </div>
  <div class="d-flex">
    <div class="col-auto" style="height:400px; overflow-y: scroll;">
      <div *ngFor="let place of lstResources"  [ngClass]="{'active': place.active}">
        <!-- <div> -->
          <div class="d-flex flex-column mb-2 card" (click)="centerMapTo(place)">
            <div class="p-2 col">
              <ng-container *ngIf="place.type == 'Equ'">
                <i>
                  <img src="../../assets/ic_open_door.png" style="height: 30px; width: 30px;">
                  {{place.label}}</i>
              </ng-container>
              <ng-container *ngIf="place.type == 'Cam'">
                <i>
                  <img src="../../assets/ic_open_door.png" style="height: 30px; width: 30px;">
                  {{place.label}}</i>
                  
              </ng-container>

            </div>
            <ng-container *ngIf="place.street">
              <div class="col d-inline">Etat : {{place.busy? 'Occupée': 'Libre'}}</div>
            </ng-container>
            <ng-container *ngIf="!place.street">
              <div class="col d-inline">Etat : {{place.latitude || place.longitude? 'Localisé':'Localosation Indisponible'}}</div>
            </ng-container>
          </div>
      </div>
    <!-- </ng-container> -->
    </div>
    <div class="container flex-grow-1">
      <div #map style="height:400px"></div>
    </div>
  </div>
</div>
