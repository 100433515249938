import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { AlertService } from 'src/app/services/alert-service.service';
import { FormChangeService } from 'src/app/services/form-change.service';
import { InstallationService } from 'src/app/services/installation.service';

@Component({
  selector: 'app-form-min-installation',
  templateUrl: './form-min-installation.component.html',
  styleUrls: ['./form-min-installation.component.scss']
})
export class FormMinInstallationComponent implements OnInit {
  searchText: string;
  searchTextCust: string;
  newSearched = true;
  constructor(
    private alertService: AlertService,
    private installationServ: InstallationService,
    private formService: FormChangeService,
    public router: Router
  ) {}

  ngOnInit() {
    this.rechercher();
    this.subscribeReloadSearchRequest();
  }

  subscribeReloadSearchRequest() {
    this.formService.reloadInstallationRequest.subscribe(() => {
      this.rechercher();
    });
  }
  // efface le formulaire de rechercher appelé depuis navBar
  clearForm() {
    this.rechercher();
  }
  private findTextInProspect(prospects) {
    let filter = this.getTextFilter();
    let prospectFiltred = prospects;
    if (filter) {
      let text = filter.split(" ");
      prospectFiltred = prospects.filter(function (item) {
        let flt = text.every(function (el) {
          let tmp =
            (item["label"] &&
              item["label"].toUpperCase().indexOf(el.toUpperCase()) >
                -1) ||
            (item["status"] &&
              item["status"].toUpperCase().indexOf(el.toUpperCase()) >
                -1) ;
          return tmp;
        });

        return flt;
      });
    }
    return prospectFiltred;
  }

  private getTextFilter() {
    return this.searchText;
  }

  rechercher() {
    this.getInstallatiosFromBackEnd().subscribe(
      (data) => {
        let tmpUsers = data as Array<any>;
        
        tmpUsers = this.findTextInProspect(tmpUsers);
        this.formService.raiseInstallationsListEvent(tmpUsers);
      },
      (error) => {
        console.log(error);
        this.formService.raiseInstallationsListEvent([]);
      }
    );
  }

  onFormChange() {
    this.rechercher();
  }

  getInstallatiosFromBackEnd() {
    return this.installationServ.getGetways();
  }
}
