import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-digicod-create',
  templateUrl: './digicod-create.component.html',
  styleUrls: ['./digicod-create.component.scss']
})
export class DigicodCreateComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
