
<h2 id="page-heading">
  <span>gestion d'accès : </span>
</h2>
<div class="searchForm flex-row" #myForm id="formMinAnnonce">
    <div class="flex-row">
        <div class="form-row">
            <div class="col-5">
                <label class="sr-only" for="text">Rechercher</label>
                <input (input)="onFormChange();" type="text" [(ngModel)]="searchText" class="form-control mb-2" id="text"
                    placeholder="Rechercher ">
            </div>
            <div class="form-inline col-6  mb-2 formGroup">
              <mat-form-field class="form-control" >
                    <mat-date-range-input  [formGroup]="dateRange" [rangePicker]="picker" >
                      <input matStartDate formControlName="start" placeholder="Date début" (dateChange)="onFormChange()">
                      <input matEndDate formControlName="end" placeholder="Date fin" (dateChange)="onFormChange()">
                    </mat-date-range-input>
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-date-range-picker #picker></mat-date-range-picker>
                  
                    <mat-error *ngIf="dateRange.controls.start.hasError('matStartDateInvalid')">date début invalide</mat-error>
                    <mat-error *ngIf="dateRange.controls.end.hasError('matEndDateInvalid')">date fin invalide</mat-error>
                  </mat-form-field>
          </div>
        </div>
    </div>
</div>
