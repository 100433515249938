<div role="document" class="modal-dialog">
  <div class="modal-content">
    <div class="modal-header">
      <h2 id="prep-file-heading">Modifiez un bon de livraison</h2>
    </div>
    <div class="modal-body">
      <div class="modal-body">
        <form class="col-10" name="userUpdateForm" role="form" novalidate (ngSubmit)="onSubmit()" #f="ngForm"
          [formGroup]="userUpdateForm">
          <div class="form-group row">
            <label class="form-control-label" for="name">Nom</label>
            <input type="text" class="form-control" id="name" name="name"
              [ngClass]="{ 'is-invalid': f.submitted && userUpdateForm?.controls?.name?.errors }"
              formControlName="name" />
            <div *ngIf="f.submitted && userUpdateForm?.controls?.name?.errors " class="text-danger"> champ
              obligatoire
            </div>
          </div>
          <div class="form-group row">
            <label class="form-control-label" for="numBill">N° Facture :</label>
            <input type="text" class="form-control" id="numBill" name="numBill"
              [ngClass]="{ 'is-invalid': f.submitted && userUpdateForm?.controls?.numBill?.errors }"
              formControlName="numBill" />
            <div *ngIf="f.submitted && userUpdateForm?.controls?.numBill?.errors " class="text-danger"> Champ
              obligatoire
            </div>
            <div class="form-group row">
              <label class="form-control-label" for="desc">Description</label>
              <textarea type="text" class="form-control" id="desc" name="desc"
                [ngClass]="{ 'is-invalid': f.submitted && userUpdateForm?.controls?.desc?.errors }"
                formControlName="desc" >
                </textarea>
              <div *ngIf="f.submitted && userUpdateForm?.controls?.desc?.errors " class="text-danger"> champ
                obligatoire
              </div>
            </div>

            <div class="form-group row">
              <label class="form-control-label" for="immatriculation">Immatriculation</label>
              <input type="text" class="form-control" id="immatriculation" name="immatriculation"
                [ngClass]="{ 'is-invalid': f.submitted && userUpdateForm?.controls?.immatriculation?.errors }"
                formControlName="immatriculation" />
              <div *ngIf="f.submitted && userUpdateForm?.controls?.immatriculation?.errors " class="text-danger"> champ
                obligatoire
              </div>
            </div>

            <div class="form-group row">
              <label class="form-control-label">Entreprise</label>
              <input type="text" formControlName="company" class="form-control"
                [ngClass]="{ 'is-invalid': f.submitted && userUpdateForm?.controls?.company?.errors }" />
              <div *ngIf="f.submitted && userUpdateForm?.controls?.company?.errors" class="invalid-feedback">
                <div *ngIf="f.submitted && userUpdateForm?.controls?.company?.errors">champ obligatoire</div>
              </div>
            </div>
            <div class="m-2">
              <button type="button" id="cancel-save" class="btn btn-secondary" data-dismiss="modal" (click)="close();">
                &nbsp;<span>Annuler</span>
              </button>

              &nbsp;&nbsp;
              <button type="submit" id="save-entity" class="btn btn-primary button titre">
                &nbsp;<span>Valider</span>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>