import { HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { AlertService } from 'src/app/services/alert-service.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { BadgeService } from 'src/app/services/badge.service';
import { FormChangeService } from 'src/app/services/form-change.service';
import { TenantService } from 'src/app/services/tenant.service';
import { VehiculeService } from 'src/app/services/vehicule.service';
@Component({
  selector: 'app-vehicule-create',
  templateUrl: './vehicule-create.component.html',
  styleUrls: ['./vehicule-create.component.scss']
})
export class VehiculeCreateComponent implements OnInit {
  userCreateForm: FormGroup;
  submitted = false;
  searchText;
  customers: {};
  customerId;
  tenantId;
  selectedCivilite: string = null;
  emailInvalid: boolean;
  numSsInvalid: boolean;
  valeurAcorriger = [];
  userForm: any;
  selectedCustomer;
  roles = new Array();
  selectedRoles = new Array();
  selectedTenant: any[];
  tenants: Object;
  constructor(
    protected vehiculeService: VehiculeService,
    private fb: FormBuilder,
    private tenantServ: TenantService,
    protected alertService: AlertService,
    private formService: FormChangeService,
    public authenSer: AuthenticationService,
    public activeModal: NgbActiveModal) {

     }

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.userCreateForm = this.fb.group({
      tenantId: [],
      label: [],
      desc: [],
      holderFirstName: [],
      holderName: [],
      immatriculation: [],
      active: [],
    });
    if (!this.authenSer.isAdmin){
      this.userCreateForm.addControl(
        'tenantId', new FormControl("", [Validators.required])
        );
    }
    this.rechercher();
  }
  get f() { return this.userCreateForm.controls; }

  private createFromForm() {
    let tmp = {
      tenantId: this.authenSer.isSupperAdmin?this.userCreateForm.get(['tenantId']).value:this.authenSer.getCurrentUser().tenantId,
      label: this.userCreateForm.get(['label'])!.value,
      desc: this.userCreateForm.get(['desc'])!.value,
      holderFirstName: this.userCreateForm.get(['holderFirstName'])!.value,
      holderName: this.userCreateForm.get(['holderName'])!.value,
      immatriculation: this.userCreateForm.get(['immatriculation'])!.value,
      active: this.userCreateForm.get(['active'])!.value,
    };
    
    return tmp;
  }

  onSubmit(): void {
    this.submitted = true;

    if (this.userCreateForm.invalid) {
      return;
    } else {
      this.userForm = this.createFromForm();

      this.subscribeToSaveResponse(this.vehiculeService.create(this.userForm));
    }
  }

  rechercher() {
      this.getTenantsFromBackEnd()
      .subscribe(
        data => {
          let tmp = data as Array<any>;
          this.tenants = data;
        }
        , error => {
          console.log(error);
        });
  }

  getTenantsFromBackEnd() {
    return this.tenantServ.getTenants();
  }

  close(): void {
    this.activeModal.close();
  }

  protected subscribeToSaveResponse(result: Observable<HttpResponse<any>>): void {
    result.subscribe(
      (res) => this.onSaveSuccess(result),
      (res) => this.onSaveError(result)
    );
  }



  protected onSaveSuccess(result): void {
    
    this.alertService.success('Vehicule sauvegardé !', true);
    this.formService.raiseReloadVehiculesSearchRequestEvent();
    this.close();
  }

  protected onSaveError(result): void {
    
    this.alertService.error('Erreur dans le formulaire !', true);
  }
}
