import { HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { AlertService } from 'src/app/services/alert-service.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { CameraService } from 'src/app/services/camera.service';
import { FormChangeService } from 'src/app/services/form-change.service';
import { TenantService } from 'src/app/services/tenant.service';
import JSMpeg from '@cycjimmy/jsmpeg-player';
@Component({
  selector: 'app-camera-stream',
  templateUrl: './camera-stream.component.html',
  styleUrls: ['./camera-stream.component.scss']
})
export class CameraStreamComponent implements OnInit {

  source ='https://www.youtube.com/embed/W26JgQW7Uss';
  camera: any;
  constructor(
    private sanitizer: DomSanitizer,
    public activeModal: NgbActiveModal,
    private camServ: CameraService) { }

  ngOnInit(): void {
    this.getTenantsFromBackEnd();
  }


  getTenantsFromBackEnd() {
    return this.camServ.getStreamUrl(this.camera.id).subscribe(
      res =>{
        this.source = res;
        
        // loadPlayer({
        //   url: this.source,
        //   canvas: document.getElementById('canvas')as HTMLCanvasElement
        // });
        // let player = new JSMpeg.Player(this.source, 
        // { canvas: document.getElementById('canvas'),
        //  autoplay: true, audio: false, }
        //   ) 
      },
      error =>{
        debugger
      }
    );
  }
  safeURL() {
    // return this.sanitizer.bypassSecurityTrustUrl(this.source);
  }
  close(): void {
    this.activeModal.close();
  }
}
