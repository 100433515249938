import { Component, OnInit, Input } from "@angular/core";

import * as moment from "moment";
import { FormControl, FormGroup, NgModel } from "@angular/forms";
import { AlertService } from "src/app/services/alert-service.service";
import { FormChangeService } from "src/app/services/form-change.service";
import { Router } from "@angular/router";
import { EquipmentService } from 'src/app/services/equipement.service';

@Component({
  selector: "app-form-min-equipement",
  templateUrl: "./form-min-equipement.component.html",
  styleUrls: ["./form-min-equipement.component.scss"],
})
export class FormMinEquipementComponent implements OnInit {
  dateRange = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });
  sites: {};
  selectedCustomer: string;
  equipements;
  selectedSite: any[];

  selectAll: boolean;
  searchText: string;
  searchTextCust: string;
  newSearched = true;
  numeroMin: number;
  numeroMax: number;
  model: NgModel;
  @Input() withPeriod: boolean;
  period: any;
  selectedPeriod: string;
  periodList: any[];
  constructor(
    private alertService: AlertService,
    private equipementServ: EquipmentService,
    private formService: FormChangeService,
    public router: Router
  ) {}

  ngOnInit() {
    this.rechercher();
    this.subscribeReloadSearchRequest();
  }

  subscribeReloadSearchRequest() {
    this.formService.reloadEquipementRequest.subscribe(() => {

      this.rechercher();
      
    });
  }
  // efface le formulaire de rechercher appelé depuis navBar
  clearForm() {
    this.rechercher();
  }
  private findTextInProspect(prospects) {
    let filter = this.getTextFilter();
    let prospectFiltred = prospects;
    if (filter) {
      let text = filter.split(" ");
      prospectFiltred = prospects.filter(function (item) {
        let flt = text.every(function (el) {
          let tmp =
            (item["label"] &&
              item["label"].toUpperCase().indexOf(el.toUpperCase()) >
                -1) ||
            (item["status"] &&
              item["status"].toUpperCase().indexOf(el.toUpperCase()) >
                -1) ;
          return tmp;
        });

        return flt;
      });
    }
    prospectFiltred = this.filterPrspectDateRange(prospectFiltred);
    return prospectFiltred;
  }
  filterPrspectDateRange(prospectFiltred: Array<any>): any {
    let tmp = prospectFiltred;
    if(this.dateRange.get('start').value){
      let start = moment(this.dateRange.get('start').value).startOf('day');
        tmp = tmp.filter(element => {
          let dateCreation = element.dateCreation?moment(element.dateCreation): null;
          return dateCreation && dateCreation.startOf('day').isSameOrAfter(start);
        });
      }
      if(this.dateRange.get('end').value){
        let end = moment(this.dateRange.get('end').value).startOf('day');
          tmp = tmp.filter(element => {
            let dateCreation = element.dateCreation?moment(element.dateCreation): null;
            return dateCreation && dateCreation.startOf('day').isSameOrBefore(end);
          });
      }
    return tmp;
  }

  private getTextFilter() {
    return this.searchText;
  }

  rechercher() {
    this.getEquipementsFromBackEnd().subscribe(
      (data) => {
        let tmpUsers = data as Array<any>;
        tmpUsers = this.findTextInProspect(tmpUsers);
        this.formService.raiseEquipementsListEvent(tmpUsers);
      },
      (error) => {
        console.log(error);
        this.formService.raiseEquipementsListEvent([]);
      }
    );
  }

  onFormChange() {
    this.rechercher();
  }

  getEquipementsFromBackEnd() {
    return this.equipementServ.getEquipmentsFromBack();
  }
}
