import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { AuthenticationService } from './authentication.service';
@Injectable({
  providedIn: 'root'
})
export class MapService {

  constructor(private http: HttpClient, private authenSrv: AuthenticationService) { }
  getResources(){
    let url =environment.apiUrl+ this.authenSrv.getUserApiTargetUrl() +'/maps';
    return this.http.get<any>(url,{withCredentials: true});
  }
}