<div role="document" class="modal-dialog">
  <div class="modal-content">
    <div class="modal-header">
      <h2 id="prep-file-heading">Créer un Badge</h2>
    </div>
    <div class="modal-body">
      <div class="modal-body">
        <form class="col-10" name="userCreateForm" role="form" novalidate #f="ngForm" (ngSubmit)="f.form.valid && onSubmit()"
            [formGroup]="userCreateForm">
            <ng-container *ngIf="this.authenSer.isSupperAdmin">
              <div class="form-group row ">Saisissez un tenant :
              <ng-select name="selectedVar" dropdownPosition='bottom' formControlName="tenantId" [items]="tenants"
                [ngClass]="{ 'forminput-invalid':f.submitted && userCreateForm?.controls?.customerId?.errors }"
                placeHolder="Tenant" [multiple]="false" [bindLabel]="'companyName'" [closeOnSelect]="true"
                class="form-control" bindValue="id" [clearable]="false">
              </ng-select>
            </div>
            <div class="form-group row ">Niveau d'accès :
              <ng-select  class="form-control mb-2" [ngModelOptions]="{standalone: true}" dropdownPosition='bottom'
                placeholder="" [items]="roles" [multiple]="true" [bindLabel]="'value'"
                [closeOnSelect]="false"
                bindValue="key" [(ngModel)]="selectedRoles" name="selectedRoles">
                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                  {{item.value}}
                </ng-template>
              </ng-select>
            </div>
            </ng-container>

            <div  class="form-group row">
                <label class="form-control-label" for="field_firstname"
                    >Prénom</label>
                <input type="text" class="form-control" id="field_firstname" name="firstname"
                [ngClass]="{ 'is-invalid': f.submitted && userCreateForm?.controls?.firstname?.errors }"
                 formControlName="firstname" />
                <div *ngIf="f.submitted && userCreateForm?.controls?.firstname?.errors " class="text-danger"> champ
                    obligatoire
                </div>
            </div>

            <div  class="form-group row">
                <label class="form-control-label" for="field_name"
                  >Nom</label>
                <input type="text" class="form-control" id="field_name" name="name"
                [ngClass]="{ 'is-invalid': f.submitted && userCreateForm?.controls?.name?.errors }"
                 formControlName="name" />
                <div *ngIf="f.submitted && userCreateForm?.controls?.name?.errors " class="text-danger"> champ
                  obligatoire
              </div>
            </div>

            <div  class="form-group row">

                <label class="form-control-label">Email</label>
                <input type="text" formControlName="email" class="form-control"
                    [ngClass]="{ 'is-invalid': f.submitted && userCreateForm?.controls?.email?.errors }" />
                <div *ngIf="f.submitted && userCreateForm?.controls?.email?.errors" class="invalid-feedback">
                    <div *ngIf="f.submitted && userCreateForm?.controls?.email?.errors">Email obligatoire</div>
                    <div *ngIf="f.submitted && userCreateForm?.controls?.email?.errors?.email">adresse invalide</div>
                </div>

            </div>
            <div  class="form-group row">
              <label class="form-control-label">Téléphone 1</label>
              <input type="text" formControlName="tel1" class="form-control"
                  [ngClass]="{ 'is-invalid': f.submitted && userCreateForm?.controls?.tel1?.errors }" />
              <div *ngIf="f.submitted && userCreateForm?.controls?.tel1?.errors" class="invalid-feedback">
                  <div *ngIf="f.submitted && userCreateForm?.controls?.tel1?.errors">Champ obligatoire</div>
              </div>
          </div>
          <div  class="form-group row">
            <label class="form-control-label">Téléphone 2</label>
            <input type="text" formControlName="tel2" class="form-control"
                [ngClass]="{ 'is-invalid': f.submitted && userCreateForm?.controls?.tel2?.errors }" />
            <div *ngIf="f.submitted && userCreateForm?.controls?.tel2?.errors" class="invalid-feedback">
                <div *ngIf="f.submitted && userCreateForm?.controls?.tel2?.errors">Champ obligatoire</div>
            </div>
        </div>
            <div *ngIf="!this.authenSer.isAdmin" class="form-group row">
                <label class="form-control-label" for="field_login" >Identifiant</label>
                <input type="text" class="form-control" id="field_login" name="login" formControlName="login"
                    [ngClass]="{ 'is-invalid': f.submitted && userCreateForm?.controls?.login?.errors }" />
                <div *ngIf="f.submitted && userCreateForm?.controls?.login?.errors" class="invalid-feedback">
                    <div *ngIf="f.submitted && userCreateForm?.controls?.login?.errors">Identifiant obligatoire</div>
                </div>
            </div>

            <div class="m-2">
                <button type="button" id="cancel-save" class="btn btn-secondary" data-dismiss="modal"
                    (click)="close();">
                    &nbsp;<span>Annuler</span>
                </button>

                &nbsp;&nbsp;
                <button type="submit" id="save-entity" class="btn btn-primary button titre">
                    &nbsp;<span>Valider</span>
                </button>
            </div>
        </form>
    </div>
</div>
</div>
