
<nav class="navbar-custom navbar navbar-expand-md bg-dark navbar-dark">
  <ng-container>
    <img src="./assets/logo_text_bold2_redim.png" width="20%"/>
  </ng-container>

  <a class="navbar-brand"  ></a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="collapsibleNavbar">
    <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
      <li *ngIf="this.authenService.isSale" class="nav-item " [routerLinkActive]="['active']"
       [routerLinkActiveOptions]="{exact:true}">
        <a class="nav-link" routerLink="/prospects" >
          <i>Prospects</i>
        </a>
      </li>
      <li *ngIf="this.authenService.isSupperAdmin" class="nav-item" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
        <a class="nav-link"  routerLink="/tenants">
          <i>Tenants</i>
        </a>
      </li>
      
      <li *ngIf="this.authenService.isAdmin || this.authenService.isSupperAdmin" class="nav-item"  [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
        <a class="nav-link" routerLink="/users">
          <i>Utilisateurs</i>
        </a>
      </li>
      
      <li *ngIf="this.authenService.isAuthenticated() || this.authenService.isAdmin" class="nav-item ">
	      <a class="nav-link" routerLink="/installations" routerLinkActive="active">Installations
	          <span class="sr-only">(current)</span>
	      </a>
      </li>
      <li *ngIf="this.authenService.isAdmin || this.authenService.isSupperAdmin" class="nav-item"  [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
        <a class="nav-link" routerLink="/map">
          <i>Map</i>
        </a>
      </li>
      
      <li *ngIf="this.authenService.isAuthenticated()" class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false">
            Resources<span class="sr-only">(current)</span>
        </a>
        <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
          <li *ngIf="this.authenService.hasInterphoneAccess" class="nav-item" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
            <a class="nav-link"  routerLink="/interphones">
              <i>Interphones</i>
            </a>
          </li>
          <li *ngIf="this.authenService.UserHasAdminPrevilege" >
            <a [routerLink]="['digicods']">&nbsp;Digicode</a>
                <span class="sr-only">(current)</span>
          </li>
            <li *ngIf="this.authenService.UserHasAdminPrevilege">
                <a [routerLink]="['readers']">&nbsp;Lecteur</a>
                <span class="sr-only">(current)</span>
            </li>
            <li *ngIf="this.authenService.UserHasAdminPrevilege">
                <a [routerLink]="['badges']">&nbsp;Badge</a>
                <span class="sr-only">(current)</span>
            </li>
            <li *ngIf="this.authenService.isAuthenticated">
              <a [routerLink]="['vehicules']">&nbsp;Vehicules</a>
              <span class="sr-only">(current)</span>
            </li>
            <li *ngIf="this.authenService.isAuthenticated && this.authenService.isLapi">
              <a [routerLink]="['cameras']">&nbsp;Cameras</a>
              <span class="sr-only">(current)</span>
            </li>
        </ul>

      </li>
      <li *ngIf="this.authenService.isAuthenticated()&& !this.authenService.isUser " class="nav-item ">
        <a class="nav-link" routerLink="/equipements" routerLinkActive="active">Ouverture
            <span class="sr-only">(current)</span>
        </a>
      </li>
      <li *ngIf="this.authenService.isAuthenticated() " class="nav-item ">
          <a class="nav-link" routerLink="/generate" routerLinkActive="active">Créer un code
              <span class="sr-only">(current)</span>
          </a>
      </li>
      
      <li *ngIf="this.authenService.isAuthenticated() &&  ! this.authenService.isUser " class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false">
              Gestion Accès<span class="sr-only">(current)</span>

          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <li>
                  <a [routerLink]="['editAccess']">&nbsp;Editer</a>
                  <span class="sr-only">(current)</span>
              </li>
              <li>
                  <a [routerLink]="['checkAccess']">&nbsp;Verifier</a>
                  <span class="sr-only">(current)</span>
              </li>
          </ul>

      </li>
      <li *ngIf="this.authenService.isAuthenticated() && !this.authenService.isUser" class="nav-item ">
        <a class="nav-link" routerLink="/accessLogs" routerLinkActive="active">Journal d'accès
            <span class="sr-only">(current)</span>
        </a>
      </li>
      <li *ngIf="this.authenService.isAuthenticated() && this.authenService.isDeleveryNote" class="nav-item ">
        <a class="nav-link" routerLink="/notes" routerLinkActive="active">Bon de livraison
            <span class="sr-only">(current)</span>
        </a>
      </li>
          <li *ngIf="this.authenService.isAuthenticated() && !this.authenService.isUser" class="nav-item ">
              <a class="nav-link" routerLink="/notifications" routerLinkActive="active">Notifications
                  <span class="sr-only">(current)</span>
              </a>
          </li>

    </ul>
    <ul class="nav navbar-nav ">
      <li class="nav-item" *ngIf="!this.authenService.isAuthenticated()" routerLink="/login">
        <a class="nav-link" href="#">
          <i class="fa fa-user" aria-hidden="true"></i>
           Se connecter
        </a>
      </li>
      <li *ngIf="this.authenService.isAuthenticated()" class="nav-item dropdown div-inline">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i class="fa fa-user-circle-o" aria-hidden="true"></i>
            {{currentUser.login}}
          </a>
          <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
            <!-- <a *ngIf="this.authenService.isAdmin" class="dropdown-item" (click)="params()" >Paramètre</a> -->
            <a *ngIf="this.authenService.isAdmin" class="dropdown-item" (click)="profil()" >Mon profil</a>
            <a class="dropdown-item" (click)="changePass()" >Changer Mon Mot de passe</a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" (click)="logout()" > Se déconnecter</a>
          </div>
        </li>
      </ul>
  </div>
</nav>
