<div role="document">
  <div>
    <br/><h2 id="prep-file-heading">Mon Profil</h2><br/>
  </div>

  <div class=" justify-content-center">

      <ng-container *ngIf="this.authenSer.isAdmin">
        <fieldset class="border p-2" >
          <legend class="w-auto">Moyen de Paiement :</legend>
          <app-credit-card-form></app-credit-card-form>
        </fieldset>
      </ng-container>

      <ng-container *ngIf="this.authenSer.isAdmin">
      <form name="editForm" role="form" novalidate [formGroup]="mytenantForm" #f="ngForm">
        <fieldset class="border p-2" >
          <legend class="w-auto">Mes informations :</legend>
          <div class="form-row mb-2">
            <div class="col">
              <label class="form-control-label" for="field_adress">Adresse : </label>
              <ng-container *ngIf="this.consultationMode">
                <span> {{this.myTenant?.adress}}</span>
              </ng-container>
              <ng-container *ngIf="!this.consultationMode">
                <input class="form-control"
                  [ngClass]="{ 'forminput-invalid':f.submitted && mytenantForm?.controls?.adress?.errors != null}"
                  formControlName="adress" />
                <div *ngIf="f.submitted && mytenantForm?.controls?.adress?.errors?.required" class="text-danger">
                  champ Obligatoire
                </div>
              </ng-container>
            </div>

          </div>
          <div class="form-row mb-2">
            <div class="col">
              <label class="form-control-label" for="field_cp">Code Postal : </label>
              <ng-container *ngIf="this.consultationMode">
                <span> {{this.myTenant?.cp}}</span>
              </ng-container>
              <ng-container *ngIf="!this.consultationMode">
                <input type="text" class="form-control" formControlName="cp" maxlength="5" minlength="5" pattern="^[0-9]*$"
                  [ngClass]="{ 'forminput-invalid':f.submitted && mytenantForm?.controls?.cp?.errors != null}" />
                <div *ngIf="f.submitted && mytenantForm?.controls?.cp?.errors?.required" class="text-danger">
                  champ Obligatoire
                </div>
            <div *ngIf="f.submitted && mytenantForm?.controls?.cp?.errors?.maxLength" class="text-danger">Ce champ
              doit contenir 5 chiffres.</div>
              </ng-container>
            </div>
            <div class="col">
              <label class="form-control-label" for="field_city">Ville : </label>
              <ng-container *ngIf="this.consultationMode">
                <span> {{this.myTenant?.city}}</span>
              </ng-container>
              <ng-container *ngIf="!this.consultationMode">
                <input type="text" class="form-control" id="field_city"
                [ngClass]="{ 'forminput-invalid':f.submitted && mytenantForm?.controls?.city?.errors != null}"
                name="city" formControlName="city" />
              <div *ngIf="f.submitted && mytenantForm?.controls?.city?.errors?.required" class="text-danger">
                champ Obligatoire</div>
              </ng-container>
            </div>

          </div>
          <div class="form-row mb-2">
            <div class="col">
              <label class="form-control-label" for="field_tel1">Téléphone : </label>
              <ng-container *ngIf="this.consultationMode">
                <span> {{this.myTenant?.tel1}}</span>
              </ng-container>
              <ng-container *ngIf="!this.consultationMode">
                <input type="text" class="form-control" id="field_tel1"
             name="tel1" formControlName="tel1" />
            </ng-container>
            </div>
            <div class="col">
              <label class="form-control-label" for="field_email">Email : </label>
              <ng-container *ngIf="this.consultationMode">
                <span> {{this.myTenant?.contactEmail}}</span>
              </ng-container>
              <ng-container *ngIf="!this.consultationMode">
                <input type="text" class="form-control" formControlName="contactEmail"
                [ngClass]="{ 'forminput-invalid': f.submitted && mytenantForm?.controls?.contactEmail.errors }" />
              <div *ngIf="f.submitted && mytenantForm?.controls?.contactEmail?.errors?.required" class="text-danger">
                champ Obligatoire
              </div>
              </ng-container>
            </div>
          </div>
          <div class="d-flex flex-row-reverse mb-2">
            <ng-container *ngIf="this.consultationMode ==true">
              <button type="button" id="save-entity"
              (click)= "switchMode()" class="btn btn-primary  ml-2">
              <span >modifier</span>
            </button>
            </ng-container>
            <ng-container *ngIf="this.consultationMode == false">
              <button type="button" id="save-entity"
              (click)= "f.form.valid && validate()" class="btn btn-primary  ml-2">
              <span >Valider</span>
            </button>
            </ng-container>
            <ng-container *ngIf="!this.consultationMode">
              <button type="button" id="cancel-save"
              class="btn btn-secondary " data-dismiss="modal"
              (click)= "annuler()">
                <span>Annuler</span>
              </button>
            </ng-container>
          </div>
        </fieldset>
      </form>
      </ng-container>
  </div>
</div>
