import { Component, OnInit } from '@angular/core';
import { EquipmentService } from 'src/app/services/equipement.service';
import { DigiCodService } from 'src/app/services/digicod.service';
import { AccessService } from 'src/app/services/access.service';
import { FormBuilder, FormControl, Validators, FormGroup } from '@angular/forms';
import { AlertService } from 'src/app/services/alert-service.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormChangeService } from 'src/app/services/form-change.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-check-access',
  templateUrl: './check-access.component.html',
  styleUrls: ['./check-access.component.scss']
})
export class CheckAccessComponent implements OnInit {
  accessCreateForm : FormGroup;

  constructor(private equipmentService: EquipmentService,
    private digicodService: DigiCodService,
    private accessService: AccessService,
    private fb: FormBuilder,
    protected alertService: AlertService,
    public authenSer: AuthenticationService,
    public activeModal: NgbActiveModal,
    private formService: FormChangeService,
    private modalService: NgbModal,
    private route: Router
  ) {}
  dateRange = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });
  degicods = new Array();

  ngOnInit() {
    this.digicodService.getDigicodFromBack().subscribe(
      data => {
      this.degicods = data;
    },
    error => {
      let msg
      if(error.error.message){
        msg = error.error.message;
      }else if(error.message){
        msg =error.message;
      }else{
        console.log("erreur :");
        console.log(JSON.parse(error));
        msg = "erreur Non parsable, ouvez la console";
      }
      this.alertService.error(msg);

    });
    this.initForm();
  }
  isValid(f){
    let val = f.form.valid;

    return val;
  }

  check(){

    let selectedDegic = this.accessCreateForm.get(["digicodeIds"])!.value;
    let accessCode = this.accessCreateForm.get(["accessCode"])!.value;

    this.accessService.checkAccess(accessCode,selectedDegic ).subscribe(
      data => {
        if(data == true){
          this.alertService.success('Code d\'accès valide: '+accessCode);
        }else{
          this.alertService.error('Code d\'accès invalide: '+accessCode);
        }
      },
      error => {
        this.alertService.error('erreur lors du traitement: '+accessCode);
      },
    () =>{
    });
  }

  initForm() {
    this.accessCreateForm = this.fb.group({
      accessCode: new FormControl("", [Validators.required]),
      digicodeIds: new FormControl("", [Validators.required]),
    });
  }

}
