import { stringify } from "querystring";

export class Utils {
  static createUrlQuery(params: any) {
    if (!params) {
        return "";
    }

    let page;
    let perPage;
    let field;
    let order;
    let query: any = {};
    if (params.pagination) {
         page = params.pagination.page;
         perPage =  params.pagination.perPage;
        query.page = page;
        query.size = perPage;
    }
    
    if (params.sort) {
        field = params.sort.field.active;
        order = params.sort.field._direction;
        if (field && order) {
            query.sort = field;
            query.direction = order;
        } else {
          query.sort = 'dateCreation';
          query.direction = 'desc';
        }

    }
    return stringify(query);
}
}
