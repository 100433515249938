<div role="document" class="modal-dialog">
  <div class="modal-content">
    <div class="modal-header">
      <legend _ngcontent-myl-c4="" class="w-auto">Enregistrer coordonnées bancaires</legend>
    </div>
    <div class="modal-body ">
      <div class="panel panel-default">
        <ng-container *ngIf="!this.cardAdded">
        <form  #f="ngForm" [formGroup]="myForm" novalidate (ngSubmit)=" f.form.valid && pay()">
          <div class="panel-body">
            <div class="row">
              <div class="form-group col-12">
                <label>Titulaire</label>
                <div class="input-group">
                  <input id="name" name="name" formControlName="name" class="form-control" placeholder="Nom et prénom" required>
                </div>
                <div class="sr-field-error text-danger" id="card-errors" role="alert"></div>
              </div>
<!--               <div class="form-group col-12"> -->
<!--                 <label>Adresse</label> -->
<!--                   <input id="adresse" class="col-12 mb-2 form-control" formControlName="adress" name="adress" placeholder="adresse" required> -->
<!--                   <div class="d-flex"> -->
<!--                     <input id="cp" class="col-4 form-control" formControlName="cp" name="cp" placeholder="CP" required> -->
<!--                     <input id="city" class="col-8 form-control" formControlName="city" name="name" placeholder="Ville" required> -->
<!--                   </div> -->

<!--               </div> -->
              <div class="form-group col-12">
                  <label>IBAN</label>
                  <div class="input-group">
                    <div class="sr-input sr-iban-element form-control" id="iban-element"></div>
                  </div>
                  <div class="sr-field-error text-danger" id="card-errors" role="alert"></div>
              </div>
<!--               <div class="form-group col-12"> -->
<!--                 <label>BIC</label> -->
<!--                 <input id="bic" class="col-12 mb-2 form-control" formControlName="bic" name="bic" placeholder="BIC" required> -->

<!--             </div> -->
            </div>
        </div>
        <div class="panel-footer">
          <div class="d-flex flex-row-reverse">
            <div>
            En signant ce formulaire de mandat, vous autorisez (A) DEVARCHI TECHNOLOGIES à envoyer des
instructions à votre banque pour débiter votre compte, et (B) votre banque à débiter votre compte
conformément aux instructions de DEVARCHI TECHNOLOGIES. <br/>Vous bénéficiez du droit d'être
remboursé par votre banque selon les conditions décrites dans la convention que vous avez passée
avec elle. Une demande de remboursement doit être présentée dans les 8 semaines suivant la date de
débit de votre compte pour un prélèvement autorisé.<br/><br/>
            <button class="btn btn-primary" id="submit-card" type="submit" [disabled]="loading">
              <i id="spinner" class="fa fa-spinner fa-pulse fa-fw" [hidden]="!loading"></i>

              <span id="button-text">Signer le mandat de prélèvement</span>
            </button>
          </div>
          </div>
        </div>
      </form>
    </ng-container>
    <ng-container *ngIf="this.cardAdded">
      <div class="panel-body">
        <div class="d-flex align-items-center flex-column">
        <div class="center-block">
           <i class="fa fa-4x fa-check-circle-o text-success" aria-hidden="true"></i>
        </div>
        <div class="center-block">RIB enregistré</div>
      </div>
    </div>

    </ng-container>
    </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="close(this.cardAdded)">Fermer
      </button>
    </div>
  </div>

</div>
