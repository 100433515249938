<h2 id="page-heading">
  <span>Tenants</span>
</h2>
<div class="searchForm flex-row" #myForm id="formMinAnnonce" style="margin-bottom: 10px;" >
  <div class="flex-row">
    <div class="col-6 my-1">
      <label class="sr-only" for="text">Rechercher</label>
      <input type="text" (input)="onChange();" [(ngModel)]="searchText" class="form-control mb-2" id="text"
        placeholder="Rechercher ">
    </div>
  </div>
</div>
<div class="d-flex" >
    <div class="col-12 my-1 ">
      <button id="create-variable" class="btn btn-primary float-right" type="submit" (click)="createCustomer()">
        Ajouter un dossier client
      </button>
    </div>
</div>
