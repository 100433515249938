import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Role } from 'src/app/class/role';
import { AlertService } from 'src/app/services/alert-service.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { FormChangeService } from 'src/app/services/form-change.service';
import { TenantService } from 'src/app/services/tenant.service';
import { InterphoneService } from '../interphone.service';

@Component({
  selector: 'app-entry-update',
  templateUrl: './entry-update.component.html',
  styleUrls: ['./entry-update.component.scss']
})
export class EntryUpdateComponent implements OnInit {
  userCreateForm: FormGroup;
  submitted = false;
  searchText;
  customers: {};
  customerId;
  tenantId;
  selectedCivilite: string = null;
  userForm: any;
  selectedCustomer;
  roles = new Array();
  selectedRoles = new Array();
  selectedTenant: any[];
  tenants: Object;
  interphoneEntry;
  constructor(
    protected interphoneSrv: InterphoneService,
    private fb: FormBuilder,
    private tenantServ: TenantService,
    protected alertService: AlertService,
    private formService: FormChangeService,
    public authenSer: AuthenticationService,
    public activeModal: NgbActiveModal) {

      Object.keys(Role).forEach(rl =>{
        this.roles.push(Role[rl]);
      });

     }

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.userCreateForm = this.fb.group({
      name: [this.interphoneEntry.name, Validators.required],
      tel1: [this.interphoneEntry.tel1, Validators.required],
      tel2: [this.interphoneEntry.tel2],
    });

  }
  // convenience getter for easy access to form fields
  get f() { return this.userCreateForm.controls; }



  private createFromForm() {
    let tmp = {
      name: this.userCreateForm.get(['name']).value,
      tenantId: this.interphoneEntry.tenantId,
      interphoneId: this.interphoneEntry.interphoneId,
      id: this.interphoneEntry.id,
      tel1: this.userCreateForm.get(['tel1'])!.value,
      tel2: this.userCreateForm.get(['tel2'])!.value,
    };
    return tmp;
  }

  onSubmit(): void {
    this.submitted = true;
    if (this.userCreateForm.invalid) {
      return;
    } else {
      this.userForm = this.createFromForm();
      this.interphoneSrv.updateInterphoneEntry(this.userForm).subscribe(
        (data) => {
          this.alertService.success('Entrée Interphone sauvegardé !', true);

          this.close();
        },
        (error) =>  {

          this.alertService.error('Erreur dans le formulaire !', true);
        }
      );
    }
  }


  // getCustomersFromBackEnd(filter) {
  //   return this.customerService.getCustomers();
  // }

  getTenantsFromBackEnd() {
    return this.tenantServ.getTenants();
  }

  close(): void {
    this.activeModal.close();
  }


}
