<app-form-min-access-log [accessId]="this.accessId"></app-form-min-access-log>

<div class="basic-container" style="margin-top: 10px;">
  <table mat-table matSort [dataSource]="accessLogsDataSource"
  matSortActive="dateCreation" matSortDirection="desc" multiTemplateDataRows class="mat-elevation-z8">

    <ng-container matColumnDef="accessCode">
      <th mat-header-cell   mat-sort-header *matHeaderCellDef> Code d'accès </th>
      <td mat-cell *matCellDef="let element">
      <span >
          {{'ACCESS_SUCCESS' != element.type ? element.accessCode: ''}}
      </span>
    </td>
    </ng-container>
    <ng-container matColumnDef="dateCreation">
      <th mat-header-cell  mat-sort-header  *matHeaderCellDef> Date Creation </th>
      <td mat-cell *matCellDef="let element">
      <span >
          {{element.dateCreation| momentPipe:'DD/MM/YYYY HH:mm'}}
      </span>
    </td>
    </ng-container>
    <ng-container matColumnDef="type">
      <th mat-header-cell  mat-sort-header *matHeaderCellDef> événement </th>
      <td mat-cell *matCellDef="let element">

      <span [ngSwitch]="typeEventEnum[element.type]" >
        <!-- <ng-container *ngIf="typeEventEnum[element.type] == typeEventEnum.ACCESS_SUCCESS"> -->
          <img *ngSwitchCase="typeEventEnum.ACCESS_SUCCESS" style="height: 30px; width: 30px" src="../../../assets/ok.png"
          title="Accès succès">
        <!-- </ng-container>
        <ng-container *ngIf="typeEventEnum[element.type] == typeEventEnum.REVOKE"> -->
          <img *ngSwitchCase="typeEventEnum.REVOKE" style="height: 30px; width: 30px" src="../../../assets/revoke.jpg"
          title="Accès révoqué">
        <!-- </ng-container>
        <ng-container *ngIf="typeEventEnum[element.type] == typeEventEnum.GENERATE"> -->
          <img *ngSwitchCase="typeEventEnum.GENERATE" style="height: 30px; width: 30px" src="../../../assets/key.png"
          title="Accès généré">
        <!-- </ng-container>
        <ng-container *ngIf="typeEventEnum[element.type] == typeEventEnum.ACCESS_FAILED"> -->
          <img *ngSwitchCase="typeEventEnum.ACCESS_FAILED" style="height: 30px; width: 30px" src="../../../assets/ic_door_close-web_old.png"
          title="Tentative d'accès">
        <!-- </ng-container>
        <ng-container *ngIf="typeEventEnum[element.type] == typeEventEnum.REMOTE"> -->
          <img *ngSwitchCase="typeEventEnum.OPEN_REMOTLY" style="height: 30px; width: 30px" src="../../../assets/remote.jpg"
          title="Ouverture à distance">
        <!-- </ng-container>
        <ng-container *ngIf="typeEventEnum[element.type] == typeEventEnum.OUT_DATED"> -->
          <img *ngSwitchCase="typeEventEnum.OUT_DATED" style="height: 30px; width: 30px" src="../../../assets/outoftime.png"
          title="Tentative hors horaire">
        <!-- </ng-container> -->
        <span *ngSwitchDefault>{{element.type}}</span>
      </span>
    </td>
    </ng-container>
    <ng-container matColumnDef="readerId">
      <th mat-header-cell  mat-sort-header *matHeaderCellDef> Accès </th>
      <td mat-cell *matCellDef="let element"> {{element.readerId}} </td>
    </ng-container>
    <ng-container matColumnDef="reference">
      <th mat-header-cell  mat-sort-header *matHeaderCellDef> Réference </th>
      <td mat-cell *matCellDef="let element"> {{element.reference}} </td>
    </ng-container>

    <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
    <ng-container matColumnDef="expandedDetail">
      <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
        <div class="example-element-detail">

        </div>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let element; columns: displayedColumns;" class="example-element-row "

      >
    </tr>
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
  </table>
  <mat-paginator class="mt-2"
  [pageSizeOptions]="[500, 1000, 2000]"
  ></mat-paginator>
</div>

