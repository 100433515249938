<div role="document" class="modal-dialog d-flex">
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" [innerHTML]="title"></h5>
        </div>
        <div class="modal-body">
            <div class=" flex">
                <div [innerHTML]="message"></div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-primary" (click)="validate()">{{okBtnMsg?okBtnMsg:'OK'}}</button>
            <button *ngIf="withCancel" type="button" class="btn btn-secondary" data-dismiss="modal"
                (click)="activeModal.close(false)">{{cancelBtnMsg?cancelBtnMsg:'Annuler'}}
            </button>
        </div>
    </div>
</div>
