import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl, SafeUrl} from '@angular/platform-browser';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-video-play',
  templateUrl: './video-play.component.html',
  styleUrls: ['./video-play.component.scss']
})
export class VideoPlayComponent implements OnInit {
  @Input() title = ``;
  @Input() videoSrc ;
  videoUrl;
  constructor(private sanitizer: DomSanitizer,
              private activeModal: NgbActiveModal) { }

  ngOnInit() {
    this.videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.videoSrc);
    
  }
  closeModal(): void {
    this.activeModal.close();
  }
}
