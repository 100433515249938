import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Role } from 'src/app/class/role';
import { AlertService } from 'src/app/services/alert-service.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { FormChangeService } from 'src/app/services/form-change.service';
import { TenantService } from 'src/app/services/tenant.service';
import { UserService } from 'src/app/services/user-service';
import { InterphoneService } from '../interphone.service';

@Component({
  selector: 'app-entry-create',
  templateUrl: './entry-create.component.html',
  styleUrls: ['./entry-create.component.scss']
})
export class EntryCreateComponent implements OnInit {
  userCreateForm: FormGroup;
  users: Array<any>;
  userForm: any;
  selectedUser;
  interphone;
  constructor(
    protected interphoneSrv: InterphoneService,
    private fb: FormBuilder,
    private userSrv: UserService,
    protected alertService: AlertService,
    private formService: FormChangeService,
    public authenSer: AuthenticationService,
    public activeModal: NgbActiveModal) {

     }

  ngOnInit(): void {
    this.initForm();
    this.loadUsers();
  }

  initForm() {
    this.userCreateForm = this.fb.group({
      userId: [''],
      name: ['', Validators.required],
      interphoneId: this.interphone.id,
      tel1: ['', Validators.required],
      tel2: [''],
    });

    // this.rechercher();
  }
  // convenience getter for easy access to form fields
  get f() { return this.userCreateForm.controls; }



  private createFromForm() {
    let tmp = {
      name: this.userCreateForm.get(['name']).value,
      tenantId: this.interphone.tenantId,
      interphoneId: this.interphone.id,
      tel1: this.userCreateForm.get(['tel1'])!.value,
      tel2: this.userCreateForm.get(['tel2'])!.value,
      userId: this.userCreateForm.get(['userId'])!.value,
    };
    return tmp;
  }

  onSubmit(): void {
    if (this.userCreateForm.invalid) {
      return;
    } else {
      this.userForm = this.createFromForm();
      this.interphoneSrv.createInterphoneEntry(this.userForm).subscribe(
        (data) => {
          this.alertService.success('Entrée Interphone sauvegardé !', true);

          this.close();
        },
        (error) =>  {

          this.alertService.error('Erreur dans le formulaire !', true);
        }
      );
    }
  }
  onFormChange(){
    let userId = this.userCreateForm.controls.userId.value;
    debugger
    let selectedUser = this.users.find(el => el.id == userId);
    this.userCreateForm.patchValue({
      name: selectedUser?selectedUser!.name:'',
    });
    this.userCreateForm.patchValue({
      tel1: selectedUser?selectedUser!.tel1:''
    });
    this.userCreateForm.patchValue({
      tel2: selectedUser?selectedUser!.tel2:'',
    });
    this.userCreateForm.patchValue({
      userId: selectedUser?selectedUser!.id:'',
    });
  }
  private loadUsers() {
    this.userSrv.getUser()
        .subscribe(
          data => {
            this.users = data as Array<any>;
          }
          , error => {
            console.log(error);
            this.formService.raiseCustomerListEvent({});
          });
  }
  close(): void {
    this.activeModal.close();
  }

}
