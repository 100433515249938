<div role="document" class="modal-dialog">
  <div class="modal-content">
    <div class="modal-header">
      <h2 id="prep-file-heading">Modifier un digicode</h2>
    </div>

    <div class="modal-body">
      <div class="modal-body">
        <form class="col-10" name="digicodUpdateForm" role="form" (ngSubmit)="f.form.valid && onSubmit()"
          #f="ngForm" [formGroup]="digicodUpdateForm">
          <div class="form-group row">
            <label class="form-control-label" for="field_label"
              >Label</label>
              <input type="text" class="form-control" id="field_label" name="label"
                    [ngClass]="{ 'is-invalid': f.submitted && digicodUpdateForm?.controls?.label?.errors }"
                     formControlName="label" />
                    <div *ngIf="f.submitted && digicodUpdateForm?.controls?.label?.errors " class="text-danger"> champ
                        obligatoire
                    </div>
          </div>

          <div class="form-group row">
            <label class="form-control-label" for="field_desc">Description</label>
            <input type="text" class="form-control" id="field_desc" name="name"
                    [ngClass]="{ 'is-invalid': f.submitted && digicodUpdateForm?.controls?.desc?.errors }"
                     formControlName="desc" />
                    <div *ngIf="f.submitted && digicodUpdateForm?.controls?.desc?.errors " class="text-danger"> champ
                      obligatoire
                  </div>
          </div>
          <div class="form-group row">
            <label class="form-control-label col-12" for="selectedVar">
              Utilisateurs</label>
            <ng-select class="col-12"  name="userIds" dropdownPosition='bottom'
                formControlName="userIds"  [bindLabel]="'fullName'"
                    [ngClass] ="{ 'forminput-invalid':f.submitted &&
                    digicodUpdateForm['controls']?.userIds?.errors != null}"
                [items]="userIds" [multiple]="true"
                [closeOnSelect]="false" bindValue="id"
                style="padding-left: 0px; padding-right: 0px;">
                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                  {{item.fullName}}
                </ng-template>
              </ng-select>
          </div>
          <div class="form-group row align-items-center">
            <div class="custom-control custom-switch">
             <input type="checkbox" class="custom-control-input" id="switch1" formControlName="active">
             <label class="custom-control-label" for="switch1" >Actif</label>
           </div>
         </div>
          <div class="m-2">
            <button type="button" id="cancel-save" class="btn btn-secondary" data-dismiss="modal" (click)="close();">
              &nbsp;<span>Annuler</span>
            </button>

            &nbsp;&nbsp;
            <button type="submit" id="save-entity" class="btn btn-primary button titre">
              &nbsp;<span>Valider</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
