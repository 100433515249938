import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { HttpParams, HttpHeaders, HttpClient } from "@angular/common/http";
import { Utils } from '../class/utils';
import { Observable } from 'rxjs';
import { AuthenticationService } from "./authentication.service";
import * as moment from "moment";

@Injectable({
  providedIn: "root",
})
export class AccessService {
  export(start: any, end: any) {
    let url = environment.apiUrl + this.authenSrv.getUserApiTargetUrl() +"/auditTrail/export";
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/octet-stream');
    let params = new HttpParams();
    
    if (start != undefined) {
        url += "?from="+ moment(start).format("DD/MM/YYYY");
    }
    if (start != undefined) {
      url += "&to="+ moment(start).format("DD/MM/YYYY");
    }
    
    return this.http.get(url, {observe:'response', responseType: 'blob'});
    // return this.http.get<any>(url, {observe:'response', withCredentials: true});
  }

  generateAccess(accessMode: string, param: HttpParams
  ) {
    let url = environment.apiUrl ;
    if(accessMode == 'code'){
      url += this.authenSrv.getUserApiTargetUrl() +"/accessConfig/generateAccessByCode";
    }
    if (accessMode == 'vehicule'){
      url += this.authenSrv.getUserApiTargetUrl() +"/accessConfig/generateAccessByVehicle";
    }
    if (accessMode == 'badge'){
      url += this.authenSrv.getUserApiTargetUrl() +"/accessConfig/generateAccessByBadge";
    }
    if (accessMode == 'phone'){
      url += this.authenSrv.getUserApiTargetUrl() +"/accessConfig/generateAccessByNumTel";
    }
    
    let headers = new HttpHeaders({
      "Content-Type": "application/x-www-form-urlencoded",
    });
    return this.http.post(url, null, {
      headers,
      params: param,
      responseType: "text",
      withCredentials: true,
    });
  }

  generateEmergencyAccess(digicodeIds: Array<any>, reference?: string) {
    //??
    let url = environment.apiUrl + this.authenSrv.getUserApiTargetUrl() +"/accessConfig/emergencyCode?";
    let params = new HttpParams();
    if (digicodeIds != undefined) {
      if (reference != undefined) {
        url += "&";
      }
      digicodeIds.forEach((element, i) => {
        if (i == 0) {
          url = url + "digicodeIds=" + element;
        } else {
          url = url + "&digicodeIds=" + element;
        }
      });
    }
    return this.http.get<any>(url, { withCredentials: true });
  }
  deleteAccess(id) {
    let url = environment.apiUrl + this.authenSrv.getUserApiTargetUrl() +"/accessCode/" + id.trim();
    return this.http.delete<any>(url, { withCredentials: true });
  }
  useAccess(accessCode: string) {
    return null; //this.http.get<any>(environment.apiUrl+'/digicode2/cxf/appaccessCode/revoke?accessCode='+accessCode);
  }
  checkAccess(accessCode: string, digicodeId) {
    let url =
      environment.apiUrl + this.authenSrv.getUserApiTargetUrl() +"/accessConfig/isValid?accessCode=" +
      accessCode +
      "&digicodeId=" +
      digicodeId;
    return this.http.post(url, null, { withCredentials: true });
  }
  public fetchLatest(sort: string = '', order: string = '', page: number = 0, perPage: number = 50, initTotal: Function = () => {}): Observable<[]> {
    let url=environment.apiUrl+ this.authenSrv.getUserApiTargetUrl() +'/auditTrail';
    return this.http.get(url+'?' + Utils.createUrlQuery({sort: {field: sort, order: order}, pagination: { page, perPage }}))
        .map((res ) => {

            const total = res['totalElements'];
            initTotal(total);
            return res['content'];
        });
}
  loadPage(installationId: string ='' , sort: string = '', order: string = '', page: number = 0, perPage: number = 50, initTotal: Function = () => {}) {
    let url=environment.apiUrl+ this.authenSrv.getUserApiTargetUrl() +'/auditTrail';
    let subUrl;
    if(installationId){
      subUrl = '/accessCode/'+installationId+'/auditTrails';
    } else {
      subUrl ="";
    }
    url += subUrl;
    let urlFinal = url + '?'
    + Utils.createUrlQuery({sort: {field: sort, order: order}, pagination: { page, perPage }});
      return this.http.get(urlFinal)
  }
  getAccesLogs(accessId) {
    let url = environment.apiUrl + this.authenSrv.getUserApiTargetUrl() +"/auditTrail";
    let subUrl;

    if(accessId){
      subUrl = '/accessCode/'+accessId+'/auditTrails';
    } else {
      subUrl ="";
    }
    url += subUrl;
    return this.http.get<any>(url, { withCredentials: true });
  }
  getAllAcces() {
    let url = environment.apiUrl + this.authenSrv.getUserApiTargetUrl() +"/accessConfig/accessCode";
    return this.http.get<any>(url, { withCredentials: true });
  }
  constructor(private http: HttpClient, private authenSrv: AuthenticationService) {}
}
