<div role="document" class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h2 id="prep-file-heading">Modifiez un Vehicule</h2>
      </div>
  
      <div class="modal-body">
        <div class="modal-body">
          <form class="col-10" name="userUpdateForm" role="form" novalidate (ngSubmit)="onSubmit()"
            #f="ngForm" [formGroup]="userUpdateForm">
  
            <ng-container *ngIf="this.authenSer.isSupperAdmin">
              <div class="form-group ">Saisissez un tenant :
                <ng-select class="mb-2" dropdownPosition='bottom' placeholder="Tenant" [items]="tenants"
                  [multiple]="false" [bindLabel]="'companyName'" [closeOnSelect]="true" formControlName="tenantId"
                  bindValue="id" name="selectedTenant">
                  <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                    {{item.companyName}}
                  </ng-template>
                </ng-select>
              </div>
  
            </ng-container>
            <div class="form-group row">
              <label class="form-control-label" for="label"
                >Label</label>
                <input type="text" class="form-control" id="label" name="label"
                      [ngClass]="{ 'is-invalid': f.submitted && userUpdateForm?.controls?.label?.errors }"
                       formControlName="label" />
                      <div *ngIf="f.submitted && userUpdateForm?.controls?.label?.errors " class="text-danger"> champ
                          obligatoire
                      </div>
            </div>
  
            <div  class="form-group row">
              <label class="form-control-label" for="holderFirstName"
                  >Prénom conducteur</label>
              <input type="text" class="form-control" id="holderFirstName" name="holderFirstName"
              [ngClass]="{ 'is-invalid': f.submitted && userUpdateForm?.controls?.holderFirstName?.errors }"
               formControlName="holderFirstName" />
              <div *ngIf="f.submitted && userUpdateForm?.controls?.holderFirstName?.errors " class="text-danger"> champ
                  obligatoire
              </div>
          </div>

          <div  class="form-group row">
              <label class="form-control-label" for="holderName"
                >Nom conducteur</label>
              <input type="text" class="form-control" id="holderName" name="holderName"
              [ngClass]="{ 'is-invalid': f.submitted && userUpdateForm?.controls?.holderName?.errors }"
               formControlName="holderName" />
              <div *ngIf="f.submitted && userUpdateForm?.controls?.holderName?.errors " class="text-danger"> champ
                obligatoire
            </div>
          </div>

           <div  class="form-group row">

              <label class="form-control-label">Description</label>
              <input type="text" formControlName="desc" class="form-control"
                  [ngClass]="{ 'is-invalid': f.submitted && userUpdateForm?.controls?.desc?.errors }" />
              <div *ngIf="f.submitted && userUpdateForm?.controls?.desc?.errors" class="invalid-feedback">
                  <div *ngIf="f.submitted && userUpdateForm?.controls?.desc?.errors">champ obligatoire</div>
              </div>

          </div>
          <div class="form-group row align-items-center">
            <div class="custom-control custom-switch">
             <input type="checkbox" class="custom-control-input" id="switch1" formControlName="active">
             <label class="custom-control-label" for="switch1" >Actif</label>
           </div>
         </div>
  
            <div class="m-2">
              <button type="button" id="cancel-save" class="btn btn-secondary" data-dismiss="modal" (click)="close();">
                &nbsp;<span>Annuler</span>
              </button>
  
              &nbsp;&nbsp;
              <button type="submit" id="save-entity" class="btn btn-primary button titre">
                &nbsp;<span>Valider</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  