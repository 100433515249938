<div role="document">
  <div>
    <h2 id="prep-file-heading">Verifier un Accès</h2>
  </div>

  <div class=" justify-content-center">

    <form name="editForm" role="form" novalidate (ngSubmit)="f.form.valid && check()" [formGroup]="accessCreateForm"
      #f="ngForm">
      <ng-container >
        <fieldset class="border p-2">
          <legend class="w-auto">Code d'accès</legend>
          <div class="form-row mb-2">
            <ng-container >
              <div class="col-3">
                <label class="form-control-label" for="accessCode">Code accès </label>
                <input type="text" class="form-control" id="accessCode"
                  name="accessCode" formControlName="accessCode"  />
              </div>
              <div *ngIf="f.submitted && accessCreateForm?.controls?.digicodeIds?.errors"
             class="text-danger">
              champ Obligatoire
            </div>
            </ng-container>
          </div>

          <div class="form-row mb-2">
            <div class="col-3">
              <label class="form-control-label" for="reference">Digicode </label>
                <ng-select  name="digicodeIds" dropdownPosition='bottom'
              formControlName="digicodeIds" required
              [ngClass] = "{ 'forminput-invalid':f.submitted &&
              accessCreateForm['controls']?.equipmentIds?.errors != null}"
                [items]="degicods" [multiple]="false" [bindLabel]="'label'"
                [closeOnSelect]="false" bindValue="id" style="padding-left: 0px;
                          padding-right: 0px;">
              </ng-select>
              <div *ngIf="f.submitted && accessCreateForm?.controls?.digicodeIds?.errors"
             class="text-danger">
              champ Obligatoire
            </div>
            </div>
          </div>
        </fieldset>
      </ng-container>
      <div class="m-2">
        <button type="button" id="cancel-save" class="btn btn-secondary" data-dismiss="modal"
          [routerLink]="['/editAccess']">
          &nbsp;<span>Annuler</span>
        </button>
        &nbsp;&nbsp;
        <button type="submit" id="save-entity" class="btn btn-primary">
          <span>Valider</span>
        </button>
      </div>
    </form>
  </div>
</div>
