
<app-form-min-tenant></app-form-min-tenant>

<div class="basic-container table-responsive" style="margin-top: 10px;">
  <table mat-table [dataSource]="lstTenants" multiTemplateDataRows class="mat-elevation-z8">
    <!-- Client -->
    <ng-container matColumnDef="Nom de l'entreprise">
      <th mat-header-cell *matHeaderCellDef> Nom de l'entreprise </th>
      <td mat-cell *matCellDef="let element"> {{element.companyName}} </td>
    </ng-container>
    <!-- Siret -->
    <ng-container matColumnDef="Siret">
      <th mat-header-cell *matHeaderCellDef> Siret </th>
      <td mat-cell *matCellDef="let element"> {{element.siret}} </td>
    </ng-container>
    <!-- Telephone -->
    <ng-container matColumnDef="Telephone">
      <th mat-header-cell *matHeaderCellDef> Telephone </th>
      <td mat-cell *matCellDef="let element"> {{element.tel1}} </td>
    </ng-container>
    <!-- Email -->
    <ng-container matColumnDef="Email">
      <th mat-header-cell *matHeaderCellDef> Email </th>
      <td mat-cell *matCellDef="let element"> {{element.email}} </td>
    </ng-container>
    <!-- Actions -->
    <ng-container matColumnDef="Actions">
      <th mat-header-cell *matHeaderCellDef>  </th>
      <td mat-cell *matCellDef="let element">
         <div class="buttons">
            <div class="m-2">
                <span (click)="editTenant(element)">
                    <i class="fa fa-pencil-square-o text-primary" style="cursor: pointer;"
                        aria-hidden="true" title="Editer"></i>
                </span>
            </div>
            <div class="m-2">
                <span (click)="openDeleteConfirm(element.id)">
                    <i class="fa fa-trash-o text-danger" style="cursor: pointer;" aria-hidden="true"
                        title="Supprimer"></i>
                </span>
            </div>
          </div>
      </td>

    </ng-container>
    <tr mat-header-row *matHeaderRowDef="columnsHeaderToDisplay"></tr>

    <tr mat-row *matRowDef="let row; columns: columnsHeaderToDisplay" class="example-detail-row"></tr>

    </table>
    </div>
