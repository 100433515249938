import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from '../services/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class InterphoneService {
  constructor(private http: HttpClient, private authenSrv: AuthenticationService) { }

  getInterphoneFromBack(){
    let url = environment.apiUrl + this.authenSrv.getUserApiTargetUrl() + '/interphones';
    return this.http.get<any>(url);
  }
  getInterphoneByIdFromBack(id: string){
    let url = environment.apiUrl + this.authenSrv.getUserApiTargetUrl() + '/interphones/' + id;
    return this.http.get<any>(url);
  }

  importEntries(interphoneId, fileData){
    const formData: FormData = new FormData();
    formData.append('file', fileData, fileData.name);
    formData.append('interphoneId', interphoneId);
    let url = environment.apiUrl + 'admin/interphoneEntries/import';
    let headers = new HttpHeaders();
    return this.http.post(url, formData, { headers, withCredentials: true });

  }
  saveInterphone(interphone){
    let url = environment.apiUrl + this.authenSrv.getUserApiTargetUrl() + '/interphones';
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    
    return this.http.put<any>(url, interphone, {headers , withCredentials: true});
  }
  createInterphone(interphone){
    let url = environment.apiUrl+ this.authenSrv.getUserApiTargetUrl() + '/interphones';
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(url, interphone, {headers , responseType: 'text' as 'json', withCredentials: true});
  }
  enableDisableInterphone(id: any, enable: boolean) {
    let url = environment.apiUrl + this.authenSrv.getUserApiTargetUrl()+ '/interphones/';
    if(enable){
      url += 'enable';
    } else {
      url += 'disable';
    }
    url +='?interphoneId='+id;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(url,null,{headers, responseType: 'text' as 'json', withCredentials: true});
  }

  getInterphoneEntries(interphoneId){
    let url = environment.apiUrl + this.authenSrv.getUserApiTargetUrl() + '/interphoneEntries/interphone/' + interphoneId;
    return this.http.get<any>(url);
  }
  createInterphoneEntry(interphonEntry: any) {
    let url = environment.apiUrl+ this.authenSrv.getUserApiTargetUrl() + '/interphoneEntries';
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(url, interphonEntry, {headers , responseType: 'text' as 'json', withCredentials: true});
  }
  updateInterphoneEntry(interphonEntry: any) {
    let url = environment.apiUrl+ this.authenSrv.getUserApiTargetUrl() + '/interphoneEntries';
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.put(url, interphonEntry, {headers , responseType: 'text' as 'json', withCredentials: true});
  }
  deleteInterphoneEntry(id: string){
    let url = environment.apiUrl + this.authenSrv.getUserApiTargetUrl() + '/interphoneEntries/removeEntry?interphoneEntryId=' + id;
    return this.http.delete<any>(url, {withCredentials: true});
  }
  delete(id: string){
    let url = environment.apiUrl + this.authenSrv.getUserApiTargetUrl() + '/interphones/' + id;
    return this.http.delete<any>(url, {withCredentials: true});
  }
  addContact(newContact){
    let url = environment.apiUrl+ this.authenSrv.getUserApiTargetUrl() + '/interphones/addContact';
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(url, newContact, {headers , withCredentials: true});
  }
  removeContact(interphoneEntryId,interphoneId){
    let url = environment.apiUrl + this.authenSrv.getUserApiTargetUrl() + '/interphones/removeContact?interphoneId=' + interphoneId +'&interphoneEntryId='+interphoneEntryId;
    return this.http.delete<any>(url, {withCredentials: true});
  }
  enable(interphoneId){
    let url = environment.apiUrl + this.authenSrv.getUserApiTargetUrl() + '/interphones/enable?interphoneId='+interphoneId;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(url, null, {headers , withCredentials: true});
  }
  disable(interphoneId){
    let url = environment.apiUrl + this.authenSrv.getUserApiTargetUrl() + '/interphones/disable?interphoneId='+interphoneId;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(url, null, {headers , withCredentials: true});
  }
}
