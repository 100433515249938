<div role="document">
  <div>
    <h2 id="prep-file-heading">Modification tenant</h2>
  </div>
  <div class=" justify-content-center">
    <form name="editForm" role="form" novalidate (ngSubmit)="f.form.valid && save()" [formGroup]="tenantUpdateForm"
      #f="ngForm">
      <fieldset class="border p-2">
        <legend class="w-auto">Raison sociale</legend>
        <div class="form-group row">
          <div class="col-12">
            <label class="form-control-label" for="companyName">Nom de l'entreprise</label>
            <input type="text" class="form-control" formControlName="companyName"
              [ngClass]="{ 'forminput-invalid': f.submitted && tenantUpdateForm?.controls?.companyName.errors }" />
            <div *ngIf="f.submitted && tenantUpdateForm?.controls?.companyName?.errors?.required" class="text-danger">
              champ Obligatoire
            </div>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-6">
            <label class="form-control-label" for="activity">Activité</label>
            <input type="text" class="form-control" formControlName="activity" />
          </div>
          <div class="col-6">
            <label class="form-control-label" for="siret">Siret</label>
            <input type="text" class="form-control" formControlName="siret" maxlength="14" minlength="14"
              pattern="^[0-9]*$"
              [ngClass]="{'forminput-invalid': f.submitted && tenantUpdateForm?.controls?.siret.errors }" />
            <div *ngIf="f.submitted && tenantUpdateForm?.controls?.siret.errors" class="text-danger">
              Champ obligatoire (14 caractères)
            </div>
          </div>
        </div>
      </fieldset>
      <fieldset class="border p-2">
        <legend class="w-auto">Adresse</legend>
        <div class="form-row mb-2">
          <div class="col-12">
            <label class="form-control-label" for="field_adress">Adresse</label>

            <input class="form-control"
              [ngClass]="{ 'forminput-invalid':f.submitted && tenantUpdateForm?.controls?.adress?.errors != null}"
              formControlName="adress" required />
            <div *ngIf="f.submitted && tenantUpdateForm?.controls?.adress?.errors?.required" class="text-danger">
              champ Obligatoire
            </div>
          </div>
        </div>
        <div class="form-row mb-2">
          <div class="col-6">
            <label class="form-control-label" for="cp">Code Postal</label>
            <input type="text" class="form-control" formControlName="cp" maxlength="5" minlength="5" pattern="^[0-9]*$"
              [ngClass]="{ 'forminput-invalid':f.submitted && tenantUpdateForm?.controls?.cp?.errors != null}" />
            <div *ngIf="f.submitted && tenantUpdateForm?.controls?.cp?.errors?.required" class="text-danger">
              champ Obligatoire
            </div>
            <div *ngIf="f.submitted && tenantUpdateForm?.controls?.cp?.errors?.maxLength" class="text-danger">Ce champ
              doit contenir 5 chiffres.</div>
          </div>
          <div class="col-6">
            <label class="form-control-label" for="city">Ville</label>
            <input type="text" class="form-control" formControlName="city"
              [ngClass]="{ 'forminput-invalid': f.submitted && tenantUpdateForm?.controls?.city?.errors?.required }" />
            <div *ngIf="f.submitted && tenantUpdateForm?.controls?.city?.errors?.required" class="text-danger">
              Champ obligatoire
            </div>
          </div>
        </div>
      </fieldset>
      <fieldset class="border p-2">
        <legend class="w-auto">Contact</legend>
        <div class="form-group row">
          <div class="col-3">
            <label class="form-control-label" for="field_email">Nom</label>
            <input type="text" class="form-control" formControlName="contactName" />
          </div>
          <div class="col-3">
            <label class="form-control-label" for="field_email">Fonction</label>
            <input type="text" class="form-control" formControlName="contactPosition" />
          </div>
          <div class="col-3">
            <label class="form-control-label" for="contactEmail">Email</label>
            <input type="text" class="form-control" formControlName="contactEmail"
              [ngClass]="{ 'forminput-invalid': f.submitted && tenantUpdateForm?.controls?.contactEmail.errors }" />
            <div *ngIf="f.submitted && tenantUpdateForm?.controls?.contactEmail?.errors?.required" class="text-danger">
              champ Obligatoire
            </div>
          </div>
        </div>
          <div class="form-group row  mb-2">
          <div class="col-3">
            <label class="form-control-label" for="tel1">Téléphone 1</label>
            <input type="text" class="form-control" formControlName="tel1" maxlength="10" minlength="10"
              pattern="^[0-9]*$"
              [ngClass]="{ 'forminput-invalid': f.submitted && tenantUpdateForm?.controls?.tel1.errors }" />
            <div *ngIf="f.submitted && tenantUpdateForm?.controls?.tel1.errors" class="text-danger">
              <div *ngIf="tenantUpdateForm?.controls?.tel1.errors?.pattern" class="text-danger">Chiffre uniquement
              </div>
              <div *ngIf="tenantUpdateForm?.controls?.tel1.errors?.minlength" class="text-danger">10 chiffres
                obligatoire</div>
            </div>
          </div>
          <div class="col-3">
            <label class="form-control-label" for="tel2">Portable</label>
            <input type="text" class="form-control" formControlName="tel2" maxlength="10" minlength="10"
              pattern="^[0-9]*$"
              [ngClass]="{ 'forminput-invalid': f.submitted && tenantUpdateForm?.controls?.tel2.errors }" />
            <div *ngIf="f.submitted && tenantUpdateForm?.controls?.tel2.errors" class="text-danger">

              <div *ngIf="tenantUpdateForm?.controls?.tel2.errors?.pattern" class="text-danger">Chiffre uniquement
              </div>
              <div *ngIf="tenantUpdateForm?.controls?.tel2.errors?.minlength" class="text-danger">10 chiffres
                obligatoire</div>
            </div>
          </div>
        </div>

      </fieldset>
      <!-- <fieldset class="border p-2">
          <legend class="w-auto">Rappel Automatique</legend>
          <div class="form-row mb-2">
            <div class="col-auto">
              <div class="form-check">
                <input class="form-check-input" formControlName="remindMailActive" type="checkbox"
                  (change)="checksRemaindMail()" name="remindMailActive" id="remindMailActive">
                <label class="form-check-label" for="remindMailActive">
                  Activer rappel email <i class="fa fa-info-circle" aria-hidden="true"
                    ngbTooltip="Email de rappel automatiquement envoyé au client en cas de retard de saisie"></i>
                </label>
              </div>
            </div>
          </div>
          <ng-container *ngIf="remidEmailActif()">
            <div class="col-12">
              <div class="form-group row">
                <label for="remindMailDay" class="col-auto col-form-label">Jour de déclenchement:</label>
                <div class="col-2">
                  <input type="number" max="31" min="1" class="form-control" id="remindMailDay" required [ngClass]="{ 'forminput-invalid':f.submitted &&
                this.remidEmailActif() &&
                tenantUpdateForm.controls?.remindMailDay?.errors != null}" formControlName="remindMailDay">
                  <div *ngIf="f.submitted && this.remidEmailActif() && tenantUpdateForm?.controls?.remindMailDay?.errors"
                    class="text-danger">
                    champ Obligatoire
                  </div>
                </div>

              </div>
            </div>
          </ng-container>

          <div class="form-row mb-2">
            <div class="col-auto">
              <div class="form-check">
                <input class="form-check-input" formControlName="remindSmsActive" type="checkbox"
                  (change)="openConfirmRemaindSMS($event)" name="remindSmsActive" id="remindSmsActive">
                <label class="form-check-label" for="remindSmsActive">
                  Activer rappel sms (0.50€ / sms) <i class="fa fa-info-circle" aria-hidden="true"
                    ngbTooltip="SMS de rappel automatique envoyé au client en cas de retard de saisie (option facturée)"></i>
                </label>
              </div>
            </div>
          </div>
          <ng-container *ngIf="remidSmsActif()">
            <div class="col-12">
              <div class="form-group row">
                <label for="remindSmsDay" class="col-auto col-form-label">Jour de déclenchement:</label>
                <div class="col-2">
                  <input type="number" max="31" min="1" class="form-control" id="remindSmsDay" required [ngClass]="{ 'forminput-invalid':f.submitted &&
              this.remidSmsActif() &&
              tenantUpdateForm.controls?.remindSmsDay?.errors != null}" formControlName="remindSmsDay">
                  <div *ngIf="f.submitted && this.remidSmsActif() && tenantUpdateForm?.controls?.remindSmsDay?.errors"
                    class="text-danger">
                    champ Obligatoire
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </fieldset> -->

      <!-- <fieldset class="border p-2">
        <legend class="w-auto"><br /> système de paie</legend>
        <div class="form-group row  mb-2">
          <label for="systemPaie" class="col-2 col-form-label">Système :</label>
          <div class="col-3">
            <select class="custom-select" name="selectedVar" formControlName="systemPaie">
              <option value="" disabled>Choisissez un type</option>
              <option *ngFor=" let type of keys(PayeSystemEnum)" [ngValue]="type">
                {{PayeSystemEnum[type]}}
              </option>
            </select>
          </div>
        </div>
        <ng-container *ngIf="PayeSystemEnum[tenantUpdateForm?.controls?.systemPaie.value] == PayeSystemEnum.SAGE.toString()">
        <div class="form-group row  mb-2">
          <label for="systemPaie" class="col-2 col-form-label">Adresse IP :</label>
          <div class="col-3">
            <input type="text" class="form-control" id="systemPaieBddHost"
              [ngClass]="{ 'forminput-invalid':f.submitted && tenantUpdateForm?.controls?.systemPaieBddHost?.errors }"
              [required]="tenantUpdateForm.controls?.systemSync?.value == true" formControlName="systemPaieBddHost">
            <div *ngIf="f.submitted && tenantUpdateForm?.controls?.systemPaieBddHost?.errors" class="text-danger">
              champ Obligatoire
            </div>
          </div>
          <label for="systemPaieVersion" class="col-2 col-form-label">Version :</label>
          <div class="col-3">
            <input type="text" class="form-control" id="systemPaieVersion" formControlName="systemPaieVersion">
          </div>
        </div>
        <div class="form-group row  mb-2">
          <label for="systemPaieVersion" class="col-2 col-form-label">Utilisateur :</label>
          <div class="col-3">
            <input type="text" class="form-control" id="systemPaieBddUser" name="systemPaieBddUser"
              [required]="tenantUpdateForm.controls?.systemPaieBddUser?.value == true" formControlName="systemPaieBddUser"
              [ngClass]="{ 'forminput-invalid':
                  f.submitted && tenantUpdateForm?.controls?.systemSync?.errors != null}" />
            <div *ngIf="f.submitted && tenantUpdateForm?.controls?.systemPaieBddUser?.errors" class="text-danger">
              champ Obligatoire
            </div>
          </div>
          <label for="systemPaieVersion" class="col-2 col-form-label">Mot de passe :</label>
          <div class="col-3">
            <input type="password" class="form-control" id="systemPaieBddPwd" name="systemPaieBddPwd"
              [required]="tenantUpdateForm.controls?.systemSync?.value == true" formControlName="systemPaieBddPwd"
              [ngClass]="{ 'forminput-invalid':
                  f.submitted && tenantUpdateForm?.controls?.systemPaieBddPwd?.errors != null}" />
            <div *ngIf="f.submitted && tenantUpdateForm?.controls?.systemPaieBddPwd?.errors" class="text-danger">
              champ Obligatoire
            </div>
          </div>
        </div>
        <div class="form-row mb-2">
          <div class="col-auto">
            <div class="form-check">
              <input class="form-check-input" formControlName="systemSync" type="checkbox" (change)="checksSystemSync()"
                name="systemSync" id="systemSync">
              <label class="form-check-label" for="systemSync">
                Activer la synchronisation (Lecture seule)
              </label>
            </div>
          </div>
        </div>
      </ng-container>
      </fieldset> -->

        <!-- <fieldset class="border p-2">
          <legend class="w-auto">Lecteur badge</legend>
          <div class="form-group row  mb-2">
            <label for="systemPaie" class="col-3 col-form-label">Nombre de Lecteurs :</label>
            <div class="col-3">
              <input type="number" class="form-control" id="nbReader"
                [ngClass]="{ 'forminput-invalid':f.submitted && tenantUpdateForm?.controls?.nbReader?.errors }"
                 formControlName="nbReader">
            </div>
            <label for="systemPaieVersion" class="col-3 col-form-label">Nombre de Badges :</label>
            <div class="col-3">
              <input type="number" class="form-control" id="nbBadge" formControlName="nbBadge">
            </div>
          </div>
        </fieldset> -->
      <div class="m-2">
        <button type="button" id="cancel-save" class="btn btn-secondary" data-dismiss="modal"
          [routerLink]="['/tenants']">
          &nbsp;<span>Annuler</span>
        </button>
        &nbsp;&nbsp;
        <button type="submit" id="save-entity" class="btn btn-primary">
          <span>Valider</span>
        </button>
      </div>
    </form>
  </div>
</div>
