import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import 'rxjs/add/operator/map';
import 'rxjs/Observable';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from '../../environments/environment';
import { AlertService } from './alert-service.service';
import { Role } from '../class/role';
import { roleEnum } from '../class/unit.enum';
@Injectable()
export class AuthenticationService {

  resetPassword(email: any) {
    let url = environment.apiUrl + 'users/resetPwd';
      url += '?login=' + email;
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    return this.http.post(url, null);
  }
  changePassword(oldPassword: any, newPassword: any) {
    let url = environment.apiUrl + 'users/changePwd';
      url += '?oldPassword=' + oldPassword+'&newPassword='+newPassword+'&passwordConfirm='+newPassword;
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    return this.http.post(url, null, { headers, withCredentials: true });
  }
  getUserLoggedIn() {
    return this.getCurrentUser();
  }
  jwtHelper: JwtHelperService = new JwtHelperService();
  public isAuthenticated(): boolean {
    const token = this.getToken();
    let tmp = !!token && !this.isTokenExpired();
    return tmp;
  }
  isTokenExpired (): boolean {
    const token = this.getToken();
    return this.jwtHelper.isTokenExpired(token);
  }
  getToken() {
    return JSON.parse(localStorage.getItem('jwttoken'));
  }
  removeToken() {
    localStorage.removeItem('jwttoken');
  }
    redirectUrl: string;

    constructor(private http: HttpClient, private router: Router,
      private alertService: AlertService) { }
    isLoggedIn = false;
    login(username: string, password: string) {
      let url = environment.serverUrl+"login";
      let formData= {};
        if (password && username) {
            formData['username']= username;
            formData['password']= password;
        }

        let headers = new HttpHeaders({'Content-Type': 'application/json'});

        this.http.post(url, formData,{ headers,withCredentials: true}).subscribe(
            data => {
              let jwtHelper = new JwtHelperService();

              let decodedData = jwtHelper.decodeToken(data['jwttoken']);
              
              let roles = decodedData.user.roles as Array<any>;
              if(roles.includes(Role.User.toString()) && !roles.includes(Role.Web.toString())){
                let msg = "accès refusé à utilisateur ";
                console.log(JSON.stringify('Profile User'));
                this.alertService.error(msg);
                this.router.navigate(['login']);
                return;
              };
            localStorage.setItem('jwttoken',JSON.stringify(data['jwttoken']));
            if(this.isUser){
              this.router.navigate(['/generate']);
            } else {
              this.router.navigate(['/accessLogs']);
            }
          },
          error => {
            let msg = "L'identifiant ou le mot de passe est incorrect";
              console.log(JSON.stringify(error));
            this.alertService.error(msg);

          });

    }

    logout() {
        // remove user from local storage to log user out
        let url =environment.apiUrl+"digicode2/logout";

        let headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
        this.http.post(url, null,{ headers,observe:"response"}).subscribe(
            data => {
            let res = data;

            localStorage.removeItem('jwttoken');
            this.isLoggedIn = false;
            //return callback && callback(true);
            this.router.navigate(['/login']);
          },
          error => {

            localStorage.removeItem('jwttoken');
            this.isLoggedIn = false;
            //return callback && callback(true);
            this.router.navigate(['/login']);
          }
        );

    }

   getCurrentUser() {
      let user;
      let token = localStorage.getItem('jwttoken');
        if(!this.jwtHelper.isTokenExpired(token)){
          let decoToken = this.jwtHelper.decodeToken(token)
          user = decoToken['user'];
        }
        return user;
    }
    get isAdmin() {
      let user = this.getUserLoggedIn();
      return user && (user.roles.includes(Role.Admin.toString()));
    }
  get hasInterphoneAccess(){
    let user = this.getUserLoggedIn();
    return user && (user.roles.includes(Role.Interphone.toString()));
  }

  get UserHasAdminPrevilege(){

    return this.isAdmin || this.isSupperAdmin;
  }
  get UserHasBadgePrevilege(){
    let user = this.getUserLoggedIn();
    return user && (user.roles.includes(Role.Badge.toString()));
  }
    get isSale() {
      let user = this.getUserLoggedIn();
      return user && (user.roles.includes(Role.Sale.toString()));
    }
    get isUser() {
      let user = this.getUserLoggedIn();

      return user && (user.roles.includes(Role.User.toString()));
    }
    get isLapi() {
      let user = this.getUserLoggedIn();

      return user && (user.roles.includes(Role.Lapi.toString()));
    }
    get isDeleveryNote() {
      let user = this.getUserLoggedIn();
      let tmp = Role.DeleverNote.toString();
      let res =  user && (user.roles.includes(Role.DeleverNote.toString()));
      return res;
    }
    get isSupperAdmin() {
      let user = this.getUserLoggedIn();
      return user && (user.roles.includes(Role.SupperAdmin.toString()));
    }
    getUserApiTargetUrl(){
      let subUrl;
      if(this.isUser){
        subUrl = 'user';
      }
      if(this.isSale){
        subUrl = 'sale';
      }
      if(this.isAdmin){
        subUrl = 'admin';
      }
      if(this.isSupperAdmin){
        subUrl = 'superadmin';
      }
      return subUrl;
    }
    userHasRole(role: string){
      let user = this.getCurrentUser();
      if(user){let userroles=   user.roles as Array<any>;
      if (userroles && userroles.indexOf(role) === -1) {
          return false;
      }
      return true;
    } else {
      return false;
    }
    }
}
