import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class CameraService {
  getById(id: string){
    let url=environment.apiUrl+ this.authenSrv.getUserApiTargetUrl() +'/cameras/'+id;
    return this.http.get<any>(url);
  }

  update(camera){
    let url=environment.apiUrl+ this.authenSrv.getUserApiTargetUrl() +'/cameras';
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.put<any>(url,camera,{headers,responseType: 'text' as 'json' ,withCredentials: true});
  }
  create(camera){
    let url=environment.apiUrl + this.authenSrv.getUserApiTargetUrl() +'/cameras';
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post<any>(url,camera,{headers,responseType: 'text' as 'json', withCredentials: true});
  }
  linkUser(userId: string, cameraId: string){
    let url = environment.apiUrl+ this.authenSrv.getUserApiTargetUrl() +'/cameras/linkUser?userId='+userId + '&cameraId=' + cameraId;
    return this.http.get<any>(url,{withCredentials: true});
  }
  unlinkUser(userId: string, cameraId: string){
    let url = environment.apiUrl+ this.authenSrv.getUserApiTargetUrl() +'/cameras/unlinkUser?userId='+userId + '&cameraId=' + cameraId;
    return this.http.get<any>(url,{withCredentials: true});
  }
  delete(id: string){
    let url = environment.apiUrl + this.authenSrv.getUserApiTargetUrl() +'/cameras/'+id;
    return this.http.delete<any>(url,{withCredentials: true});
  }
  constructor(private http: HttpClient, private authenSrv: AuthenticationService) { }

  get(){
    let url = environment.apiUrl + this.authenSrv.getUserApiTargetUrl() +'/cameras';
    return this.http.get<any>(url,{withCredentials: true});
  }
  getStreamUrl(camUrl){
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let url = environment.apiUrl + this.authenSrv.getUserApiTargetUrl() +'/cameras/stream/'+camUrl;
    return this.http.get<any>(url, {headers , responseType: 'text' as 'json', withCredentials: true});
  }
  
}
