import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { typeEvent } from '../class/unit.enum';
import { AlertService } from '../services/alert-service.service';
import { FormChangeService } from '../services/form-change.service';
import { DigiCodService } from '../services/digicod.service';
import { AccessService } from '../services/access.service';
import { ModalRASComponent } from '../modal/modalRAS.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-manage-access',
  templateUrl: './manage-access.component.html',
  styleUrls: ['./manage-access.component.scss']
})
export class ManageAccessComponent implements OnInit {
  accessLogs = new MatTableDataSource(new Array());
  accessId;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  displayedColumns = ['accessCode','date_creation','type','reference','dateEndEffect','dateStartEffect'
  ,'digicodeLabels','equipmentLabels','lastConsumedDate','reference'
  ,'uniqueUsage','Actions'
  ];
  typeEventEnum = typeEvent;
  ngOnInit() {
    this.accessLogs.sort = this.sort;
    this.subscribeformChange();
  }
  subscribeformChange() {
    this.formService.formAccessSearchChanged.subscribe(
      (param) => {

        this.accessLogs = new MatTableDataSource(param);

      });

  }
  openDeleteConfirm(id): void {
    const modalRef = this.modalService.open(ModalRASComponent, { backdrop: 'static' });
    modalRef.componentInstance.title = "Suppresion d'un Code d'accès";
    modalRef.componentInstance.message = "Confirmer la suppression ?";
    modalRef.componentInstance.withCancel = true;
    modalRef.result.then(result => {
      if (result == true) {
        this.delete(id);
      }
    });
  }

  delete(id) {
    this.accessService.deleteAccess(id).subscribe(
      () => this.onDeleteSuccess(),
      () => this.onDeleteError()
    );

  }
  protected onDeleteSuccess(): void {
    this.alertService.success('Suppression effectuée.', false);
    this.formService.raiseReloadAccessSearchRequestEvent();
  }


  protected onDeleteError(): void {
    this.alertService.warn('Erreur de suppression.', false);
  }

  onChange() {
    this.formService.raiseReloadAccessSearchRequestEvent();
  }

  constructor(
    private modalService: NgbModal,
    private alertService: AlertService,
  private formService: FormChangeService,
  private digicodServ: DigiCodService,
  private accessService: AccessService,
  ) { }

}
