<div role="document">
  <div>
    <h2 id="prep-file-heading">Modification Interphone</h2>
  </div>

  <div class=" justify-content-center">
    <form name="editForm" role="form" novalidate (ngSubmit)="f.form.valid && save()" [formGroup]="userUpdateForm"
      #f="ngForm">
      <ng-container >
        <fieldset class="border p-2">
          <legend class="w-auto">infos</legend>
          <div class="form-row mb-2">
            <div class="col-4">
              <label class="form-control-label" for="cp">Label</label>
              <input type="text"  class="form-control" id="label"
                name="label" formControlName="label"  />
            </div>
            <div class="col-8">
              <label for="callNumber">Telephone</label>
              <input type="text" class="form-control" id="callNumber" name="callNumber" formControlName="callNumber"
                [ngClass]="{ 'forminput-invalid':
              f.submitted && userUpdateForm?.controls?.callNumber?.errors != null}"  />
              <div *ngIf="f.submitted && userUpdateForm?.controls?.callNumber?.errors" class="text-danger">
                champ Obligatoire
              </div>
            </div>
        </div>
          <div class="form-row mb-2">
              <div class="col-12">
                <label class="form-control-label" for="adress">Adresse</label>
                <input type="text"  class="form-control" id="adress"
                  name="adress" formControlName="adress"  />
              </div>
          </div>
          <div class="form-row mb-2">
              <div class="col-4">
                <label class="form-control-label" for="cp">Code postal</label>
                <input type="text"  class="form-control" id="cp"
                  name="cp" formControlName="cp"  />
              </div>
              <div class="col-8">
                <label for="city">Ville</label>
                <input type="text" class="form-control" id="city" name="city" formControlName="city"
                  [ngClass]="{ 'forminput-invalid':
                f.submitted && userUpdateForm?.controls?.city?.errors != null}"  />
                <div *ngIf="f.submitted && userUpdateForm?.controls?.city?.errors" class="text-danger">
                  champ Obligatoire
                </div>
              </div>
          </div>
          <div class="form-row mb-2">
            <div class="col-4">
              <label class="form-control-label" for="hostUrl">Host</label>
              <input type="text"  class="form-control" id="hostUrl"
                name="hostUrl" formControlName="hostUrl"  />
            </div>
            <div class="col-4">
              <label for="username">username</label>
              <input type="text" class="form-control" id="username" name="username" formControlName="username"  />
            </div>
            <div class="col-4">
              <label for="password">Password</label>
              <input type="password" class="form-control" id="password" name="password" formControlName="password"  />
            </div>
        </div>
          <div class="m-2">
            <button type="button" id="cancel-save" class="btn btn-secondary" data-dismiss="modal"
              [routerLink]="['/interphones']">
              &nbsp;<span>Annuler</span>
            </button>
            &nbsp;&nbsp;
            <button type="submit" id="save-entity" class="btn btn-primary">
              <span>Valider</span>
            </button>
          </div>
        </fieldset>
      </ng-container>


      <fieldset class="border p-2">
        <legend class="w-auto">Contacts</legend>

        <div class="form-row mb-2">
              <table mat-table matSort [dataSource]="entries" multiTemplateDataRows class="mat-elevation-z8">
                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef> Nom </th>
                  <td mat-cell *matCellDef="let element">
                  <span >
                      {{element.name}}
                  </span>
                </td>
                </ng-container>
                <ng-container matColumnDef="tel1">
                  <th mat-header-cell *matHeaderCellDef> Telephone 1</th>
                  <td mat-cell *matCellDef="let element">
                  <span >
                      {{element.tel1}}
                  </span>
                </td>
                </ng-container>
                <ng-container matColumnDef="tel2">
                  <th mat-header-cell *matHeaderCellDef> Telephone 2</th>
                  <td mat-cell *matCellDef="let element">
                  <span >
                      {{element.tel2}}
                  </span>
                </td>
                </ng-container>
                <ng-container *ngIf="this.authenSrv.isAdmin ||this.authenSrv.isSupperAdmin" matColumnDef="Actions">
                  <th mat-header-cell *matHeaderCellDef>
                    <button id="create-variable" class="btn btn-primary float-right" type="button" (click)="addInterphoneEntry()">
                      <span>
                        Ajouter
                      </span>
                    </button>
                  </th>
                  <td mat-cell *matCellDef="let element">
                    <div class="buttons">
                      <div class="m-2">
                        <span (click)="editEntry(element)">
                            <i class="fa fa-pencil-square-o text-primary" style="cursor: pointer;"
                                aria-hidden="true" title="Editer"></i>
                        </span>
                        </div>
                        <div class="m-2">
                            <span (click)="openDeleteConfirm(element.id)">
                                <i class="fa fa-trash-o text-danger" style="cursor: pointer;" aria-hidden="true"
                                    title="Supprimer"></i>
                            </span>
                        </div>
                      </div>
                  </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let element; columns: displayedColumns;" class="example-element-row "
                  [class.example-expanded-row]="expandedElement === element"
                  (click)="expandedElement = expandedElement === element ? null : element">
                </tr>
              </table>
        </div>
        <ng-container *ngIf="this.authenSrv.isAdmin">
          <div class="m-2">
            <button type="button" id="cancel-save" class="btn btn-secondary" data-dismiss="modal"
            (click)="importEntry()">
              &nbsp;<span>Importer</span>
            </button>
            &nbsp;&nbsp;
            <button type="button" (click)="createEntry()" id="save-entity" class="btn btn-primary">
              <span>Ajouter</span>
            </button>
          </div>
      </ng-container>
      </fieldset>

    </form>
  </div>
</div>
