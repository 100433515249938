import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { DigiCodService } from 'src/app/services/digicod.service';
import { AlertService } from 'src/app/services/alert-service.service';
import { NotificationsService } from 'src/app/services/notifications.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormChangeService } from 'src/app/services/form-change.service';
import { Router } from '@angular/router';
import { MomentPipe } from 'src/app/moment-pipe.pipe';
import { ModalComponent } from 'src/app/modal/modal.component';
import { CameraService } from 'src/app/services/camera.service';

@Component({
  selector: 'app-notification-create',
  templateUrl: './notification-create.component.html',
  styleUrls: ['./notification-create.component.scss']
})
export class NotificationCreateComponent implements OnInit, OnDestroy {
  timerKeepAlive:any;
  employeesCreateForm: FormGroup;
  keys = Object.keys;
  degicods;
  cameras;
  selectedCustomer: string = null;
  selectedCivilite: string = null;
  notificationToSave: any;
  emailInvalid: boolean;
  firstContact;
  lastContact;
  typeContrat = [
    { id: 1, code: "PUSH", name: "Push" },
    { id: 2, code: "MAIL", name: "Email" },
    {id:3, code: "SMS", name: "SMS"},
    {id:4, code: "CALL", name: "Appel"},
  ];
  typeEvent = [
    { id: 1, code: "EMERGENCY", name: "Code d'urgence" },
    { id: 2, code: "ACCESS_SUCCESS", name: "Accés succés" },
    {id:3, code: "ACCESS_FAILED", name: "Accés refusé généré"},
    {id:4, code: "ALREADY_CONSUMED", name: "Code réutilisé"},
    {id:5, code: "GENERATE", name: "Code généré"}
  ];
  notification : any;
  constructor(
    protected digicodService: DigiCodService,
    private fb: FormBuilder,
    protected alertService: AlertService,
    private notificationServ: NotificationsService,
    public authenSer: AuthenticationService,
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private formService: FormChangeService,
    private route: Router,
    private camereServ: CameraService,
    private momentPipe: MomentPipe
  ) {
    this.notification = this.route.getCurrentNavigation().extras.state?this.route.getCurrentNavigation().extras.state.notification:null;
  }
  ngOnDestroy(): void {
    clearInterval(this.timerKeepAlive);
  }
  refreshDestinataire(){
    this.employeesCreateForm.patchValue(
      {
        destValue: "",
      }
    );
    
    let selectedType = this.employeesCreateForm.get(["type"])!.value;
    if(selectedType == 'SMS'){
      const modalRef = this.modalService.open(ModalComponent, {
        backdrop: "static",
      });
      modalRef.componentInstance.message = 'Attention la notification par SMS implique une facturation variable : <b> (0.50€ / sms)</b>';
      modalRef.componentInstance.title = 'Motification SMS';

      modalRef.result.then((result) => {
      });
    }
  }
  ngOnInit(): void {
    this.digicodService.getDigicodFromBack().subscribe(
      data => {
      this.degicods = data;
    },
    error => {
      let msg
      if(error.error.message){
        msg = error.error.message;
      }else if(error.message){
        msg =error.message;
      }else{
        console.log("erreur :");
        console.log(JSON.parse(error));
        msg = "erreur Non parsable, ouvez la console";
      }
      this.alertService.error(msg);

    });
    this.camereServ.get().subscribe(
      data =>{
        this.cameras = data;
      },
      error =>{

      }
    );

    this.initForm();
  }

  initForm() {
    this.employeesCreateForm = this.fb.group({
      accessCodeReference: new FormControl(""),
      destValue: new FormControl(""),
      digicodeIds: [],
      eachOccurence: [false],
      eventType: new FormControl(""),
      nbExecution: new FormControl(""),
      nbNextOccurence: new FormControl(""),
      type: new FormControl(""),
      userId: new FormControl(""),
      screenCameraId: new FormControl(""),
      waitBeforeShot: new FormControl(""),
    });

  }

  private createFromForm(): any {
    let tmp = {
      accessCodeReference: this.employeesCreateForm.get(["accessCodeReference"])!.value,
      destValue: this.employeesCreateForm.get(["destValue"]).value?this.employeesCreateForm.get(["destValue"]).value:null,
      digicodeIds: this.employeesCreateForm.get(["digicodeIds"]).value? this.employeesCreateForm.get(["digicodeIds"]).value: null,
      eachOccurence: this.employeesCreateForm.get(["eachOccurence"]).value ? this.employeesCreateForm.get(["eachOccurence"]).value: null,
      eventType: this.employeesCreateForm.get(["eventType"]).value?this.employeesCreateForm.get(["eventType"]).value:null,
      nbNextOccurence: this.employeesCreateForm.get(["nbNextOccurence"])!.value,
      type: this.employeesCreateForm.get(["type"])!.value,
      screenCameraId: this.employeesCreateForm.get(["screenCameraId"]).value? this.employeesCreateForm.get(["screenCameraId"]).value: null,
      waitBeforeShot: this.employeesCreateForm.get(["waitBeforeShot"])!.value,
    };
    return tmp;
  }
  save(): void {
    if(this.employeesCreateForm.invalid) {
      return;
    }
    this.notificationToSave = this.createFromForm();
    debugger
    this.notificationServ
      .saveNotification(this.notificationToSave)
      .subscribe(
        (data) => {
          this.alertService.success("Notification Sauvegardé !", true);
          this.formService.raiseReloadNotificationsSearchRequestEvent();
          this.route.navigateByUrl("/notifications");
        },
        (error) => {
          console.log(error);
          this.alertService.error("Erreur dans le formulaire !", true);
        }
      );
  }
}
