<div class="wrapperDiv">
  <div class="col-md-6 col-10 login-form">
    <form name="form" (ngSubmit)="f.form.valid && resetPassword()" #f="ngForm">
      <h2 class="text-center">Changer le mot de passe</h2>
      <div class="form-group titre">
        <input type="password" class="form-control" [ngClass]="{ 'forminput-invalid':f.submitted &&
      (!model.oldPassword ||model.oldPassword?.length == 0 )}" [(ngModel)]="model.oldPassword" #oldPassword="ngModel" name="oldPassword"
          placeholder="Mot de Passe actuel" required>
        <div *ngIf="f.submitted  && !model.oldPassword" class="text-danger">champ Obligatoire</div>
      </div>
      <div class="form-group titre">
        <input type="password" class="form-control" [ngClass]="{ 'forminput-invalid':f.submitted &&
    (!model.newPassword ||model.newPassword?.length == 0 )}" [(ngModel)]="model.newPassword" #newPassword="ngModel" name="newPassword"
          placeholder="Le nouveau Mot de passe" required>
        <div *ngIf="f.submitted  && !model.newPassword" class="text-danger">champ Obligatoire</div>
      </div>
      <div class="form-group titre">
        <input type="password" class="form-control" [ngClass]="{ 'forminput-invalid':f.submitted &&
  (!model.passwordConfirm ||model.passwordConfirm?.length == 0 )}"
  pattern="{{ newPassword.value }}"[(ngModel)]="model.passwordConfirm" #passwordConfirm="ngModel" name="passwordConfirm"
          placeholder="Confirmez Le nouveau Mot de passe" required>
        <div *ngIf="f.submitted  && !model.passwordConfirm" class="text-danger">champ Obligatoire</div>
        <div *ngIf="f.submitted  && model.passwordConfirm != model.newPassword" class="text-danger">Le mot de passe et sa confirmation ne correspondent pas</div>
      </div>
      <div class="text-center">
        <div class="form-group">
          <button type="submit" class="btn btn-primary btn-lg btn-block login-btn2">Valider</button><br/>
          <a [routerLink]="['/login']"><button _ngcontent-ttx-c10="" class="btn btn-secondary" data-dismiss="modal" type="button">Annuler </button></a>
        </div>
      </div>

    </form>
  </div>
</div>
