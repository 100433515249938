<app-form-min-delver-note></app-form-min-delver-note>
<div class="d-flex">
  <div class="col-12 my-1">
    <button id="create-variable" class="btn btn-primary float-right" type="submit" (click)="create()">
      <span>
        Ajouter un bon de livraison
      </span>
    </button>
  </div>
</div>

<div class="basic-container" style="margin-top: 10px;">
  <table mat-table matSort [dataSource]="lstVehicules"  class="mat-elevation-z8">
    <!-- <ng-container matColumnDef="expandIcon">
      <th mat-header-cell *matHeaderCellDef> </th>
      <td mat-cell *matCellDef="let element">
        <mat-icon (click)="expandedElement = expandedElement === element ? null : element">
          {{expandedElement === element ? 'expand_less' : 'expand_more'}}
        </mat-icon>
      </td>
    </ng-container> -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> Nom </th>
      <td mat-cell *matCellDef="let element">
      <span >
          {{element.name}}
      </span>
    </td>
    </ng-container>
    <ng-container matColumnDef="desc">
      <th mat-header-cell *matHeaderCellDef> Description </th>
      <td mat-cell *matCellDef="let element"> {{element.desc}} </td>
    </ng-container>
    <ng-container matColumnDef="immatriculation">
      <th mat-header-cell *matHeaderCellDef> Immatriculation </th>
      <td mat-cell *matCellDef="let element"> {{element.immatriculation}} </td>
    </ng-container>
    <ng-container matColumnDef="numBill">
        <th mat-header-cell *matHeaderCellDef> N° Facture </th>
        <td mat-cell *matCellDef="let element"> {{element.numBill}} </td>
    </ng-container>
    <ng-container matColumnDef="company">
      <th mat-header-cell *matHeaderCellDef> Entreprise </th>
      <td mat-cell *matCellDef="let element"> {{element.company}} </td>
  </ng-container>
  <ng-container matColumnDef="Actions">
    <th mat-header-cell *matHeaderCellDef> </th>
    <td mat-cell *matCellDef="let element">
      <div class="buttons">
        <div class="m-2">
          <span (click)="edit(element)">
            <i class="fa fa-pencil-square-o text-primary" style="cursor: pointer;" aria-hidden="true"
              title="Editer"></i>
          </span>
        </div>
        <div class="m-2" >
          <span (click)="invoice(element)">
            <i class="fa fas fa-file-invoice text-info" style="cursor: pointer;" aria-hidden="true"
              title="Attache à une facture"></i>
          </span>
        </div>
        <div class="m-2">
          <span (click)="openDeleteConfirm(element.id)">
            <i class="fa fa-trash-o text-danger" style="cursor: pointer;" aria-hidden="true" title="Supprimer"></i>
          </span>
        </div>
      </div>
    </td>
  </ng-container>
  
    <ng-container matColumnDef="expandedDetail">
      <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
        <div class="example-element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
          <div class="inner-table mat-elevation-z8" *ngIf="expandedElement">
          </div>
        </div>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  
    <!-- Group header -->
    <ng-container matColumnDef="groupHeader">
      <td colspan="6" mat-cell *matCellDef="let groupBy" class="col" style="border-bottom-style: none"><strong>{{groupBy.groupName}}</strong></td>
    </ng-container>
  
    <tr mat-row *matRowDef="let row; columns: ['groupHeader']; when: isGroup" > </tr>
  </table>
</div>


