import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { MaterialModule } from './material/material.module';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { NotifierModule } from 'angular-notifier';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FooterComponent } from './footer/footer.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import 'moment/locale/fr';
import localeFr from '@angular/common/locales/fr';
import { registerLocaleData, CommonModule } from '@angular/common';
import localeFrExtra from '@angular/common/locales/extra/fr';
import { NgbModule, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ChartsModule } from 'ng2-charts';
import { ModalComponent } from './modal/modal.component';
import { ModalRASComponent } from './modal/modalRAS.component';
import { AuthGuard } from './Guard/AuthGuard';
import { AlertService } from './services/alert-service.service';
import { AuthenticationService } from './services/authentication.service';
import { AlertComponent } from './alert/alert.component';
import { ShContextMenuModule } from 'ng2-right-click-menu'
import { MomentPipe } from './moment-pipe.pipe';
import { JwtInterceptorService } from './services/jwt-interceptor.service';
import { RessetLoginComponent } from './resset-login/resset-login.component';
import { PrettyprintPipe } from './prettyprint.pipe';
import { ChangePassComponent } from './change-pass/change-pass.component';
import { MonProfileComponent } from './mon-profile/mon-profile.component';
registerLocaleData(localeFr, 'fr-FR', localeFrExtra);
import { OwlDateTimeModule, OwlNativeDateTimeModule, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { DigicodsComponent } from './digicods/digicods.component';
import { FormMinDigicodsComponent } from './digicods/form-min-digicods/form-min-digicods.component';
import { NotifsDigicodComponent } from './digicods/notifs-digicod/notifs-digicod.component';
import { LogsDigicodComponent } from './digicods/logs-digicod/logs-digicod.component';
import { ReadersComponent } from './readers/readers.component';
import { FormMinReadersComponent } from './readers/form-min-readers/form-min-readers.component';
import { ReaderCreateComponent } from './readers/reader-create/reader-create.component';
import { ReaderUpdateComponent } from './readers/reader-update/reader-update.component';
import { DigicodUpdateComponent } from './digicods/digicod-update/digicod-update.component';
import { DigicodCreateComponent } from './digicods/digicod-create/digicod-create.component';
import { BadgesComponent } from './badges/badges.component';
import { FormMinBadgeComponent } from './badges/form-min-badge/form-min-badge.component';
import { BadgeCreateComponent } from './badges/badge-create/badge-create.component';
import { BadgeUpdateComponent } from './badges/badge-update/badge-update.component';
import { ManageBadgeComponent } from './badges/manage-badge/manage-badge.component';
import { EquipmentsComponent } from './equipments/equipments.component';
import { FormMinEquipementComponent } from './equipments/form-min-equipement/form-min-equipement.component';
import { EquipementUpdateComponent } from './equipments/equipement-update/equipement-update.component';
import { ManageEquipementComponent } from './equipments/manage-equipement/manage-equipement.component';
import { EquipementCreateComponent } from './equipments/equipement-create/equipement-create.component';
import { AccessGenerationComponent } from './access-generation/access-generation.component';
import { AccesLogComponent } from './services/acces-log/acces-log.component';
import { FormMinAccessLogComponent } from './services/acces-log/form-min-access-log/form-min-access-log.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { FormMinNotificationsComponent } from './notifications/form-min-notifications/form-min-notifications.component';
import { ManageAccessComponent } from './manage-access/manage-access.component';
import { FormMinManageAccessComponent } from './manage-access/form-min-manage-access/form-min-manage-access.component';
import { CheckAccessComponent } from './manage-access/check-access/check-access.component';
import { NotificationUpdateComponent } from './notifications/notification-update/notification-update.component';
import { NotificationCreateComponent } from './notifications/notification-create/notification-create.component';
import { CreditCardFormComponent } from './credit-card-form/credit-card-form.component';
import { AddCardModalComponent } from './credit-card-form/add-card-modal/add-card-modal.component';
import { AddSepaModalComponent } from './credit-card-form/add-sepa-modal/add-sepa-modal.component';
import { UserComponent } from './user/user.component';
import { UserUpdateComponent } from './user/user-update/user-update.component';
import { UserCreateComponent } from './user/user-create/user-create.component';
import { TenantsComponent } from './tenants/tenants.component';
import { CreateTenantComponent } from './tenants/create-tenant/create-tenant.component';
import { UpdateTentantComponent } from './tenants/update-tenant/update-tentant.component';
import { FormMinTenantComponent } from './tenants/form-min-tenant/form-min-tenant.component';
import { FormMinUserComponent } from './form-min-user/form-min-user.component';
import { InterphoneComponent } from './interphone/interphone.component';
import { InterphoneCreateComponent } from './interphone/interphone-create/interphone-create.component';
import { InterphoneUpdateComponent } from './interphone/interphone-update/interphone-update.component';
import { FormMinInterphoneComponent } from './interphone/form-min-interphone/form-min-interphone.component';
import { EntryCreateComponent } from './interphone/entry-create/entry-create.component';
import { EntryUpdateComponent } from './interphone/entry-update/entry-update.component';
import { EntriesImportComponent } from './interphone/entries-import/entries-import.component';
import { VideoPlayComponent } from './interphone/video-play/video-play.component';
import { InstallatationComponent } from './installatation/installatation.component';
import { FormMinInstallationComponent } from './installatation/form-min-installation/form-min-installation.component';
import { VehiculesComponent } from './vehicules/vehicules.component';
import { VehiculeUpdateComponent } from './vehicules/vehicule-update/vehicule-update.component';
import { VehiculeCreateComponent } from './vehicules/vehicule-create/vehicule-create.component';
import { FormMinVehiculeComponent } from './vehicules/form-min-vehicule/form-min-vehicule.component';
import { CamerasComponent } from './cameras/cameras.component';
import { FormMinCameraComponent } from './cameras/form-min-camera/form-min-camera.component';
import { CameraCreateComponent } from './cameras/camera-create/camera-create.component';
import { CameraUpdateComponent } from './cameras/camera-update/camera-update.component';
import { DeleveryNoteComponent } from './delevery-note/delevery-note.component';
import { FormMinDelverNoteComponent } from './delevery-note/form-min-delver-note/form-min-delver-note.component';
import { DeleveryNoteCreateComponent } from './delevery-note/delevery-note-create/delevery-note-create.component';
import { DeleveryNoteUpdateComponent } from './delevery-note/delevery-note-update/delevery-note-update.component';
import { DeleveryNoteFactureComponent } from './delevery-note/delevery-note-facture/delevery-note-facture.component';
import { CameraStreamComponent } from './cameras/camera-stream/camera-stream.component';
import { MapComponent } from './map/map.component';
import { AgmCoreModule } from '@agm/core';
import { GoogleMapsModule } from '@angular/google-maps';
@NgModule({
  declarations: [
    AppComponent,
    NavBarComponent,
    LoginComponent,
    HomeComponent,
    FooterComponent,
    ModalComponent,
    AlertComponent,
    MomentPipe,
    RessetLoginComponent,
    PrettyprintPipe,
    ChangePassComponent,
    MonProfileComponent,
    ModalRASComponent,
    DigicodsComponent,
    FormMinDigicodsComponent,
    NotifsDigicodComponent,
    LogsDigicodComponent,
    ReadersComponent,
    FormMinReadersComponent,
    ReaderCreateComponent,
    ReaderUpdateComponent,
    DigicodUpdateComponent,
    DigicodCreateComponent,
    BadgesComponent,
    FormMinBadgeComponent,
    BadgeCreateComponent,
    BadgeUpdateComponent,
    ManageBadgeComponent,
    EquipmentsComponent,
    FormMinEquipementComponent,
    EquipementUpdateComponent,
    ManageEquipementComponent,
    EquipementCreateComponent,
    AccessGenerationComponent,
    AccesLogComponent,
    FormMinAccessLogComponent,
    NotificationsComponent,
    FormMinNotificationsComponent,
    ManageAccessComponent,
    FormMinManageAccessComponent,
    CheckAccessComponent,
    NotificationUpdateComponent,
    NotificationCreateComponent,
    CreditCardFormComponent,
    AddCardModalComponent,
    FormMinUserComponent,
    AddSepaModalComponent,
    UserComponent,
    UserUpdateComponent,
    UserCreateComponent,
    TenantsComponent,
    CreateTenantComponent,
    UpdateTentantComponent,
    FormMinTenantComponent,
    InterphoneComponent,
    FormMinInterphoneComponent,
    InterphoneCreateComponent,
    InterphoneUpdateComponent,
    EntryCreateComponent,
    EntryUpdateComponent,
    EntriesImportComponent,
    VideoPlayComponent,
    InstallatationComponent,
    FormMinInstallationComponent,
    VehiculesComponent,
    VehiculeUpdateComponent,
    VehiculeCreateComponent,
    FormMinVehiculeComponent,
    CamerasComponent,
    FormMinCameraComponent,
    CameraCreateComponent,
    CameraUpdateComponent,
    DeleveryNoteComponent,
    FormMinDelverNoteComponent,
    DeleveryNoteCreateComponent,
    DeleveryNoteUpdateComponent,
    DeleveryNoteFactureComponent,
    CameraStreamComponent,
    MapComponent,
  ],
  imports: [
    MaterialModule,
    AppRoutingModule,
    BrowserModule,
    FontAwesomeModule,
    BrowserAnimationsModule,
    NotifierModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    HttpClientModule,
    NgbModule,
    ShContextMenuModule,
    ChartsModule,
    CommonModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBSU14c73kQYCbn8nPFsEVP0ZvyAxB-dmQ',
      libraries: ['places']
    }),
    GoogleMapsModule,
  ],
  providers: [
    NgbActiveModal,
    AuthGuard,
    AlertService,
    AuthenticationService,
    MomentPipe, PrettyprintPipe,
    {
      provide: HTTP_INTERCEPTORS,
        useClass: JwtInterceptorService,
        multi: true
    },
    [{ provide: LOCALE_ID, useValue: 'fr-FR' },
    {provide: OWL_DATE_TIME_LOCALE, useValue: 'fr'}],
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    ReaderUpdateComponent, EquipementUpdateComponent,VideoPlayComponent,
    BadgeCreateComponent, BadgeUpdateComponent,
    DeleveryNoteCreateComponent, DeleveryNoteUpdateComponent, DeleveryNoteFactureComponent,
    VehiculeCreateComponent,VehiculeUpdateComponent,
    CameraCreateComponent, CameraUpdateComponent, CameraStreamComponent,
    UserCreateComponent, InterphoneCreateComponent, InterphoneUpdateComponent,
    EntryCreateComponent, EntryUpdateComponent,EntriesImportComponent,
    ModalComponent,UserUpdateComponent, UserCreateComponent,
    ModalRASComponent,
    AddSepaModalComponent
  ]
})
export class AppModule { }
