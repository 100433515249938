import { HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { AlertService } from 'src/app/services/alert-service.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { DeleveryNoteService } from 'src/app/services/delevery-note.service';
import { FormChangeService } from 'src/app/services/form-change.service';
import { TenantService } from 'src/app/services/tenant.service';

@Component({
  selector: 'app-delevery-note-facture',
  templateUrl: './delevery-note-facture.component.html',
  styleUrls: ['./delevery-note-facture.component.scss']
})
export class DeleveryNoteFactureComponent implements OnInit {

  userUpdateForm: FormGroup;
  userForm: any;
  tenants;
  delevryNote: any;
  constructor(
    private fb: FormBuilder,
    private delevryNoteService: DeleveryNoteService,
    protected alertService: AlertService,
    private formService: FormChangeService,
    public authenSer: AuthenticationService,
    public activeModal: NgbActiveModal,
    private tenantServ: TenantService) { }

  ngOnInit(): void {
    this.initForm();
    this.updateForm(this.delevryNote);
  }

  initForm() {
    this.userUpdateForm = this.fb.group({
      numBill : [],
    });

  }

  getTenantsFromBackEnd() {
    return this.tenantServ.getTenants();
  }

  get f() { return this.userUpdateForm.controls; }



  private createFromForm(): any {
    return {
      numBill : this.userUpdateForm.get(["numBill"])!.value,
    };
  }
  // dans le cas de l'update on récupère les données pour les afficher
  updateForm(veh: any): void {
    this.userUpdateForm.patchValue({
      numBill : veh.numBill,
    });
  }


  onSubmit(): void {
    if (this.userUpdateForm.invalid) {
      return;
    } else {
      this.userForm = this.createFromForm();
      this.subscribeToSaveResponse(this.delevryNoteService.attachBill(this.delevryNote.id, this.userForm.numBill));
    }
  }

  close(): void {
    this.activeModal.close();
  }

  protected subscribeToSaveResponse(result: Observable<HttpResponse<any>>): void {
    result.subscribe(
      (result) => this.onSaveSuccess(result),
      (result) => this.onSaveError(result)
    );
  }



  protected onSaveSuccess(result): void {
    this.alertService.success('Camera sauvegardé !', false);
    this.close();
    this.formService.raiseReloadDelevryNoteSearchRequestEvent();
  }

  protected onSaveError(result): void {
    this.alertService.error('Erreur dans le formulaire !', false);
  }
}
