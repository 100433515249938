import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BadgeCreateComponent } from '../badges/badge-create/badge-create.component';
import { BadgeUpdateComponent } from '../badges/badge-update/badge-update.component';
import { ModalRASComponent } from '../modal/modalRAS.component';
import { ReaderUpdateComponent } from '../readers/reader-update/reader-update.component';
import { AlertService } from '../services/alert-service.service';
import { AuthenticationService } from '../services/authentication.service';
import { FormChangeService } from '../services/form-change.service';
import { ReaderService } from '../services/reader.service';
import { VehiculeService } from '../services/vehicule.service';
import { VehiculeCreateComponent } from './vehicule-create/vehicule-create.component';
import { VehiculeUpdateComponent } from './vehicule-update/vehicule-update.component';

@Component({
  selector: 'app-vehicules',
  templateUrl: './vehicules.component.html',
  styleUrls: ['./vehicules.component.scss']
})
export class VehiculesComponent implements OnInit {
  lstVehicules = new MatTableDataSource(new Array());
  displayedColumns = ['expandIcon','holder','immatriculation','desc','label','Actions'
  ];
  expandedElement: {} | null;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(private vehiculeService: VehiculeService,
    public alertService: AlertService,
    private formService: FormChangeService,
    public authenSer: AuthenticationService,
    private modalService: NgbModal,
    private router: Router) { }

  ngOnInit() {
    this.subscribeformChange();
    this.lstVehicules.sort = this.sort;
  }
  subscribeformChange() {
    this.formService.formVehiculesSearchChanged.subscribe(
      (param) => {

        this.lstVehicules = new MatTableDataSource(param);

      });
  }
  // gerer(equipment){
  //   this.router.navigate(['/gestionEquipment'],{ queryParams: {id:equipment.id}});
  // }
  // journal(equipment){
  //   this.router.navigate(['/accessLogs'],{ queryParams: { id:equipment.id}});
  // }
  // notification(equipment){
  //   this.router.navigate(['/notifications'],{ queryParams: { id:equipment.id}});
  // }

  create(): void {
    const modalRef = this.modalService.open(VehiculeCreateComponent, { backdrop: 'static' });
  }
  edit(veh) {
    const modalRef = this.modalService.open(VehiculeUpdateComponent, { backdrop: 'static' });
    modalRef.componentInstance.vehicule = veh;
  }
  openDeleteConfirm(id): void {
    const modalRef = this.modalService.open(ModalRASComponent, { backdrop: 'static' });
    modalRef.componentInstance.title = "Suppresion d'un Vehicule";
    modalRef.componentInstance.message = "Confirmer la suppression ?";
    modalRef.componentInstance.withCancel = true;
    modalRef.result.then(result => {
      if (result == true) {
        this.delete(id);
      }
    });
  }
  delete(id) {
    this.vehiculeService.delete(id).subscribe(
      () => this.onDeleteSuccess(),
      () => this.onDeleteError()
    );

  }
  protected onDeleteSuccess(): void {
    this.alertService.success('Suppression effectuée.', false);
    this.formService.raiseReloadVehiculesSearchRequestEvent();
  }


  protected onDeleteError(): void {
    this.alertService.warn('Erreur de suppression.', false);
  }

  onChange() {
    this.formService.raiseReloadVehiculesSearchRequestEvent();
  }
}
