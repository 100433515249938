import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class InstallationService {

  constructor(private http: HttpClient, private authenSrv: AuthenticationService) { }
  getGetways(){
    let url=environment.apiUrl+ this.authenSrv.getUserApiTargetUrl() +'/gateways';
    return this.http.get<any>(url,{withCredentials: true});
  }
  rebootGetway(gatewayId){
    let url=environment.apiUrl+ this.authenSrv.getUserApiTargetUrl() +'/gateways/reboot?gatewayId='+gatewayId;
    return this.http.post<any>(url,{withCredentials: true});

  }
}
