import { HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { AlertService } from 'src/app/services/alert-service.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { CameraService } from 'src/app/services/camera.service';
import { FormChangeService } from 'src/app/services/form-change.service';
import { TenantService } from 'src/app/services/tenant.service';
import { VehiculeService } from 'src/app/services/vehicule.service';

@Component({
  selector: 'app-camera-create',
  templateUrl: './camera-create.component.html',
  styleUrls: ['./camera-create.component.scss']
})
export class CameraCreateComponent implements OnInit {
  userCreateForm: FormGroup;
  submitted = false;
  searchText;
  customers: {};
  customerId;
  tenantId;
  selectedCivilite: string = null;
  emailInvalid: boolean;
  numSsInvalid: boolean;
  valeurAcorriger = [];
  userForm: any;
  selectedCustomer;
  roles = new Array();
  selectedRoles = new Array();
  selectedTenant: any[];
  tenants: Object;
  constructor(
    protected cameraService: CameraService,
    private fb: FormBuilder,
    private tenantServ: TenantService,
    protected alertService: AlertService,
    private formService: FormChangeService,
    public authenSer: AuthenticationService,
    public activeModal: NgbActiveModal) {

     }

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.userCreateForm = this.fb.group({
      active: [],
      ip: [],
      label: [],
      protocol: [],
      pwd: [],
      username: [],
      visible: [],
    });
  }
  get f() { return this.userCreateForm.controls; }

  private createFromForm() {
    let tmp = {
      label: this.userCreateForm.get(['label'])!.value,
      ip: this.userCreateForm.get(['ip'])!.value,
      protocol: this.userCreateForm.get(['protocol'])!.value,
      pwd: this.userCreateForm.get(['pwd'])!.value,
      username: this.userCreateForm.get(['username'])!.value,
      visible: this.userCreateForm.get(['visible'])!.value,
    };
    
    return tmp;
  }

  onSubmit(): void {
    this.submitted = true;

    if (this.userCreateForm.invalid) {
      return;
    } else {
      this.userForm = this.createFromForm();
      this.subscribeToSaveResponse(this.cameraService.create(this.userForm));
    }
  }

  rechercher() {
      this.getTenantsFromBackEnd()
      .subscribe(
        data => {
          let tmp = data as Array<any>;
          this.tenants = data;
        }
        , error => {
          console.log(error);
        });
  }

  getTenantsFromBackEnd() {
    return this.tenantServ.getTenants();
  }

  close(): void {
    this.activeModal.close();
  }

  protected subscribeToSaveResponse(result: Observable<HttpResponse<any>>): void {
    result.subscribe(
      (res) => this.onSaveSuccess(result),
      (res) => this.onSaveError(result)
    );
  }



  protected onSaveSuccess(result): void {
    
    this.alertService.success('Vehicule sauvegardé !', true);
    this.formService.raiseReloadCamerasSearchRequestEvent();
    this.close();
  }

  protected onSaveError(result): void {
    
    this.alertService.error('Erreur dans le formulaire !', true);
  }
}
