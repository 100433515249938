import { HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { AlertService } from 'src/app/services/alert-service.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { CameraService } from 'src/app/services/camera.service';
import { DeleveryNoteService } from 'src/app/services/delevery-note.service';
import { FormChangeService } from 'src/app/services/form-change.service';
import { TenantService } from 'src/app/services/tenant.service';

@Component({
  selector: 'app-delevery-note-update',
  templateUrl: './delevery-note-update.component.html',
  styleUrls: ['./delevery-note-update.component.scss']
})
export class DeleveryNoteUpdateComponent implements OnInit {

  userUpdateForm: FormGroup;
  tenantId;
  userForm: any;
  selectedCustomer;
  selectedTenant;
  tenants;
  vehicule: any;
  constructor(
    private fb: FormBuilder,
    private delevryNoteService: DeleveryNoteService,
    protected alertService: AlertService,
    private formService: FormChangeService,
    public authenSer: AuthenticationService,
    public activeModal: NgbActiveModal,
    private tenantServ: TenantService) { }

  ngOnInit(): void {
    this.initForm();
    this.updateForm(this.vehicule);
  }

  initForm() {
    this.userUpdateForm = this.fb.group({
      id: [],
      company : [],
      desc : [],
      immatriculation : [],
      name : [],
      numBill : [],
      tenantId : [],
    });

  }
  rechercher() {
    if(this.authenSer.isSupperAdmin){
      this.getTenantsFromBackEnd()
      .subscribe(
        data => {
          let tmp = data as Array<any>;
          this.tenants = data;
        }
        , error => {
          console.log(error);
        });
    }
  }

  getTenantsFromBackEnd() {
    return this.tenantServ.getTenants();
  }

  get f() { return this.userUpdateForm.controls; }



  private createFromForm(): any {
    return {
      id: this.userUpdateForm.get(['id']).value,
      company : this.userUpdateForm.get(["company"])!.value,
      desc : this.userUpdateForm.get(["desc"])!.value,
      immatriculation : this.userUpdateForm.get(["immatriculation"])!.value,
      name : this.userUpdateForm.get(["name"])!.value,
      numBill : this.userUpdateForm.get(["numBill"])!.value,
      tenantId: this.authenSer.isSupperAdmin?this.userUpdateForm.get(['tenantId']).value:null,
    };
  }
  // dans le cas de l'update on récupère les données pour les afficher
  updateForm(veh: any): void {
    
    this.userUpdateForm.patchValue({
      id: veh.id,
      company : veh.company,
      dateCreation : veh.dateCreation,
      desc : veh.desc,
      immatriculation : veh.immatriculation,
      name : veh.name,
      numBill : veh.numBill,
      tenantId : veh.tenantId,
    });
  }


  onSubmit(): void {
    if (this.userUpdateForm.invalid) {
      return;
    } else {
      this.userForm = this.createFromForm();
      this.subscribeToSaveResponse(this.delevryNoteService.update(this.userForm));
    }
  }

  close(): void {
    this.activeModal.close();
  }

  protected subscribeToSaveResponse(result: Observable<HttpResponse<any>>): void {
    result.subscribe(
      (result) => this.onSaveSuccess(result),
      (result) => this.onSaveError(result)
    );
  }



  protected onSaveSuccess(result): void {
    this.alertService.success('Camera sauvegardé !', false);
    this.close();
    this.formService.raiseReloadDelevryNoteSearchRequestEvent();
  }

  protected onSaveError(result): void {
    this.alertService.error('Erreur dans le formulaire !', false);
  }

}
