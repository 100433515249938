import { Component, OnInit, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { NgModel } from '@angular/forms';
import { AlertService } from 'src/app/services/alert-service.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { FormChangeService } from 'src/app/services/form-change.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { TenantService } from 'src/app/services/tenant.service';

@Component({
  selector: 'app-form-min-tenant',
  templateUrl: './form-min-tenant.component.html',
  styleUrls: ['./form-min-tenant.component.scss']
})
export class FormMinTenantComponent implements OnInit {
  subscriptions = new Array<Subscription>();
  selectedCustomer: string;
  tenants;
  searchText: string;
  searchTextCust: string;
  model: NgModel;
  constructor(
    private alertService: AlertService,
    private tenantServ: TenantService,
    public authenSer: AuthenticationService,
    private formService: FormChangeService,
    protected modalService: NgbModal,
    public router: Router) { }
    ngOnDestroy(): void {
      this.subscriptions.forEach(sub =>{
        sub.unsubscribe();
      });
    }

  ngOnInit() {
      this.rechercher();
      this.subscribeReloadSearchRequest();
  }

  subscribeReloadSearchRequest() {
    this.subscriptions.push(
    this.formService.reloadTenantSearchRequest.subscribe(
      () => {
        this.rechercher();
      }));
  }
  // efface le form de rechercher appelé depuis navBar
  clearForm() {
    this.rechercher();
  }

  onChange() {
    this.rechercher();
  }
  createCustomer(): void {
    this.router.navigateByUrl('/create-tenant', /* Removed unsupported properties by Angular migration: queryParams. */ {});

  }
  rechercher() {
    let filter = this.getFilter();

    this.subscriptions.push(
      this.getTenantsFromBackEnd()
      .subscribe(
        data => {
          let tmp = data as Array<any>;
          if (tmp) {
            if (filter) {
                tmp = this.findTextInTenants(tmp, filter);
              }
          }
          this.formService.raiseTenantsListEvent(tmp);
        }, error => {
          console.log(error);

        }));
  }

  private findTextInTenants(customers, text) {
    text = text?text.split(' '):'';
    return customers.filter(function (item) {

      return text.every(function (el) {
        return item['companyName'].toUpperCase().indexOf(el.toUpperCase()) > -1 ||
          item['siret'].toUpperCase().indexOf(el.toUpperCase()) > -1;
      });
    });
  }

  getTenantsFromBackEnd() {
    return this.tenantServ.getTenants();
  }

  private getFilter() {
    let filter = this.searchText ? this.searchText : null;
    return filter;
  }
}
