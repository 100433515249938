import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FormChangeService {

  formEmployeeSearchChanged: EventEmitter<Array<any>>;
  formEmployeeSearchRequest: EventEmitter<boolean>;
  formMinUserSearchEvent: EventEmitter<Array<any>>;
  formAllEmployeeChanged: EventEmitter<Array<any>>;

  formSubmissionSearchChanged: EventEmitter<Array<any>>;

  formCustomerSearchChanged: EventEmitter<Array<any>>;

  reloadSearchRequest: EventEmitter<boolean>;
  userMessagesChanged: EventEmitter<Array<any>>;
  //isolé
  formProspectSearchChanged: EventEmitter<Array<any>>;
  reloadProspectRequest: EventEmitter<boolean>;

  formDigicodsSearchChanged = new EventEmitter<Array<any>>();
  reloadDigicodsRequest = new EventEmitter<boolean>();

  formReadersSearchChanged = new EventEmitter<Array<any>>();
  reloadReadersRequest = new EventEmitter<boolean>();

  formInterphonesSearchChanged = new EventEmitter<Array<any>>();
  reloadInterphonesRequest = new EventEmitter<boolean>();

  formBadgeSearchChanged = new EventEmitter<Array<any>>();
  reloadBadgeRequest = new EventEmitter<boolean>();

  formVehiculesSearchChanged = new EventEmitter<Array<any>>();
  reloadVehiculesRequest = new EventEmitter<boolean>();

  formCamerasSearchChanged = new EventEmitter<Array<any>>();
  reloadCameraRequest = new EventEmitter<boolean>();

  formDelevryNoteSearchChanged = new EventEmitter<Array<any>>();
  reloadDelevryNoteRequest = new EventEmitter<boolean>();

  formEquipementSearchChanged = new EventEmitter<Array<any>>();
  reloadEquipementRequest = new EventEmitter<boolean>();

  formInstallationtSearchChanged = new EventEmitter<Array<any>>();
  reloadInstallationRequest = new EventEmitter<boolean>();

  formAccessLogSearchChanged = new EventEmitter<any>();
  reloadAccessLogRequest = new EventEmitter<any>();

  formAccessSearchChanged = new EventEmitter<Array<any>>();
  reloadAccessRequest = new EventEmitter<boolean>();

  formNotificationsSearchChanged = new EventEmitter<Array<any>>();
  reloadNotificationsRequest = new EventEmitter<boolean>();
  //isolé
    reloadTenantSearchRequest: EventEmitter<boolean>;
    formTenantListChanged: EventEmitter<Array<any>>;
  //isolé
  reloadVariablePaieSearchRequest: EventEmitter<boolean>;
  formVariablePaieSearchChanged: EventEmitter<Array<any>>;
  //isolé
  reloadSaisiesRequest: EventEmitter<boolean>;
  formSaisiesSearchChanged: EventEmitter<Array<any>>;
  formSaisiesListChanged: EventEmitter<Array<any>>;

  reloadAddDataPaieRequest: EventEmitter<boolean>;
  formAddDataPaieListChanged: EventEmitter<Array<any>>;
  //isolé
  reloadAbsencesEvent: EventEmitter<boolean>;
  formAbsencesListChanged: EventEmitter<Array<any>>;
    //isolé
  reloadCustomerEvent: EventEmitter<boolean>;
  // saisieSearchRequest: EventEmitter<boolean>;
  reloadSearchRequestUser: EventEmitter<boolean>;
  reloadSearchCustomer: EventEmitter<boolean>;
  periodSelectChanged: EventEmitter<any>;
  formEmployeeAbsCreate: EventEmitter<any>;
  constructor() {
    this.formEmployeeSearchChanged = new EventEmitter<Array<any>>();
    this.formEmployeeSearchRequest = new EventEmitter<boolean>();
    this.formMinUserSearchEvent = new EventEmitter<Array<any>>();
    this.formAllEmployeeChanged = new EventEmitter<Array<any>>();
    this.reloadAddDataPaieRequest = new EventEmitter<boolean>();
    this.formAddDataPaieListChanged = new EventEmitter<Array<any>>();
    this.formSubmissionSearchChanged = new EventEmitter<Array<any>>();
    this.formCustomerSearchChanged = new EventEmitter<Array<any>>();
    this.formSaisiesSearchChanged = new EventEmitter<Array<any>>();
    this.formSaisiesListChanged = new EventEmitter<Array<any>>();
    this.formVariablePaieSearchChanged = new EventEmitter<Array<any>>();
    this.reloadVariablePaieSearchRequest = new EventEmitter<boolean>();
    this.reloadTenantSearchRequest = new EventEmitter<boolean>();
    this.formTenantListChanged = new EventEmitter<Array<any>>();
    this.formProspectSearchChanged = new EventEmitter<Array<any>>();
    this.reloadProspectRequest = new EventEmitter<boolean>();
    this.reloadSearchRequest = new EventEmitter<boolean>();
    this.userMessagesChanged = new EventEmitter<Array<any>>();
    this.reloadSaisiesRequest = new EventEmitter<boolean>();
    this.reloadAbsencesEvent = new EventEmitter<boolean>();
    this.formAbsencesListChanged = new EventEmitter<Array<any>>();
    this.reloadSearchRequestUser = new EventEmitter<boolean>();
    this.reloadSearchCustomer = new EventEmitter<boolean>();
    this.periodSelectChanged = new EventEmitter<any>();
    this.formEmployeeAbsCreate = new EventEmitter<Array<any>>();
    this.reloadCustomerEvent = new EventEmitter<boolean>();
  }


  raiseEmployeeAbsForm(liste) {
    this.formEmployeeAbsCreate.emit(liste);
  }

  raiseEmployeeListEvent(liste): void {
    this.formEmployeeSearchChanged.emit(liste);
  }

  raiseFormEmployeeSearchRequest() {
    this.formEmployeeSearchRequest.emit(true);
  }

  raiseFormMinUserSearch(liste): void {
    this.formMinUserSearchEvent.emit(liste);
  }
  raiseAllEmployeeListEvent(liste): void {
    this.formAllEmployeeChanged.emit(liste);
  }

  raiseSubmissionListEvent(liste): void {
    this.formSubmissionSearchChanged.emit(liste);
  }
  raiseVariablePaieListEvent(liste): void {
    this.formVariablePaieSearchChanged.emit(liste);
  }
  reloadVariablePaieSearchRequestEvent(): void {
    this.reloadVariablePaieSearchRequest.emit(true);
  }

  raiseProspectListEvent(liste): void {
    this.formProspectSearchChanged.emit(liste);
  }
  raiseReloadProspectSearchRequestEvent(): void {
    this.reloadProspectRequest.emit(true);
  }

  raiseDigicodsListEvent(liste): void {
    this.formDigicodsSearchChanged.emit(liste);
  }
  raiseReloadReadersSearchRequestEvent(): void {
    this.reloadReadersRequest.emit(true);
  }
  raiseReadersListEvent(liste): void {
    this.formReadersSearchChanged.emit(liste);
  }
  raiseReloadBadgesSearchRequestEvent(): void {
    this.reloadBadgeRequest.emit(true);
  }
  raiseReloadVehiculesSearchRequestEvent(): void {
    this.reloadVehiculesRequest.emit(true);
  }

  raiseInterphonesListEvent(liste): void {
    this.formInterphonesSearchChanged.emit(liste);
  }
  raiseInterphonesSearchRequestEvent(): void {
    this.reloadInterphonesRequest.emit(true);
  }
  raiseBadgesListEvent(liste): void {
    this.formBadgeSearchChanged.emit(liste);
  }
  raiseVehiculesListEvent(liste): void {
    this.formVehiculesSearchChanged.emit(liste);
  }
  raiseReloadEquipementsSearchRequestEvent(): void {
    this.reloadEquipementRequest.emit(true);
  }
  
  raiseCameraListEvent(liste): void {
    this.formCamerasSearchChanged.emit(liste);
  }
  raiseReloadCamerasSearchRequestEvent(): void {
    this.reloadCameraRequest.emit(true);
  }

  raiseDelevryNoteListEvent(liste): void {
    this.formDelevryNoteSearchChanged.emit(liste);
  }
  raiseReloadDelevryNoteSearchRequestEvent(): void {
    this.reloadDelevryNoteRequest.emit(true);
  }
  
  raiseEquipementsListEvent(liste): void {
    this.formEquipementSearchChanged.emit(liste);
  }
  raiseReloadInstallationsSearchRequestEvent(): void {
    this.reloadInstallationRequest.emit(true);
  }
  raiseInstallationsListEvent(liste): void {
    this.formInstallationtSearchChanged.emit(liste);
  }
  raiseReloadAccessLogsSearchRequestEvent(paginatio): void {
    this.reloadAccessLogRequest.emit(paginatio);
  }
  raiseAccessLogsListEvent(liste): void {
    this.formAccessLogSearchChanged.emit(liste);
  }
  raiseReloadAccessSearchRequestEvent(): void {
    this.reloadAccessRequest.emit(true);
  }
  raiseAccessListEvent(liste): void {
    this.formAccessSearchChanged.emit(liste);
  }
  raiseReloadNotificationsSearchRequestEvent(): void {
    this.reloadNotificationsRequest.emit(true);
  }
  raiseNotificationsListEvent(liste): void {
    this.formNotificationsSearchChanged.emit(liste);
  }
  raiseReloadDigicodsSearchRequestEvent(): void {
    this.reloadDigicodsRequest.emit(true);
  }
  raiseUserMessagesListEvent(liste): void {
    this.userMessagesChanged.emit(liste);
  }
  raiseCustomerListEvent(liste): void {
    this.formCustomerSearchChanged.emit(liste);
  }
  raiseSaisiesSearchChangedEvent(liste): void {
    this.formSaisiesSearchChanged.emit(liste);
  }

  raiseSaisiesListEvent(liste): void {
    this.formSaisiesListChanged.emit(liste);
  }
  raiseReloadSaisiesRequestEvent(): void {
    this.reloadSaisiesRequest.emit(true);
  }
  raiseTenantsListEvent(liste): void {
    this.formTenantListChanged.emit(liste);
  }
  raiseReloadTenantsSearchRequest(): void {
    this.reloadTenantSearchRequest.emit(true);
  }
  raiseAddDataPaieListEvent(liste) {
    this.formAddDataPaieListChanged.emit(liste);
  }
  raiseReloadAddDataPaieRequestEvent(): void {
    this.reloadAddDataPaieRequest.emit(true);
  }
  raisereloadSearchRequestEvent(): void {
    this.reloadSearchRequest.emit(true);
  }

  raisereReloadAbsencesEvent(): void {
    this.reloadAbsencesEvent.emit(true);
  }
  raiseAbsencesListEvent(liste): void {
    this.formAbsencesListChanged.emit(liste);
  }

  raisereReloadCustomerEvent(): void {
    this.reloadCustomerEvent.emit(true);
  }
  raisereloadSearchCustomerEvent(): void {
    this.reloadSearchCustomer.emit(true);
  }
  raisereloadSearchRequestUserEvent(): void {
    this.reloadSearchRequestUser.emit(true);
  }


  raiserePeriodSelectChangedEvent(selectedPeriod): void {
    this.periodSelectChanged.emit(selectedPeriod);
  }

}
