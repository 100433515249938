<app-form-min-notifications [(installationId)]="this.installationId"></app-form-min-notifications>
<div class="d-flex">
  <div class="col-12 my-1">
    <button id="create-variable" class="btn btn-primary float-right" type="submit" (click)="create()">
      <span>
        Ajouter une Notification
      </span>
    </button>
  </div>
</div>
<div class="basic-container" style="margin-top: 10px;">
  <table mat-table matSort [dataSource]="notifications" multiTemplateDataRows
  (matSortChange)="sortData($event)"
  multiTemplateDataRows class="mat-elevation-z8">

    <ng-container matColumnDef="accessCode">
      <th mat-header-cell mat-sort-header *matHeaderCellDef> Code d'accès </th>
      <td mat-cell *matCellDef="let element">
      <span >
          {{element.accessCode}}
      </span>
    </td>
    </ng-container>
    <ng-container matColumnDef="dateCreation">
      <th mat-header-cell mat-sort-header *matHeaderCellDef> Date Creation </th>
      <td mat-cell *matCellDef="let element">
      <span >
          {{element.dateCreation| momentPipe:'DD/MM/YYYY HH:mm'}}
      </span>
    </td>
    </ng-container>
    <ng-container matColumnDef="type">
      <th mat-header-cell mat-sort-header  *matHeaderCellDef> Canal </th>
      <td mat-cell *matCellDef="let element"> {{element.type}} </td>
    </ng-container>
    <ng-container matColumnDef="eventType">
      <th mat-header-cell mat-sort-header *matHeaderCellDef> évenement </th>
      <td mat-cell *matCellDef="let element">
      <span >
          {{element.eventType}}
      </span>
    </td>
    </ng-container>
    <ng-container matColumnDef="eachOccurence">
      <th mat-header-cell mat-sort-header *matHeaderCellDef> Toutes les occurences </th>
      <td mat-cell *matCellDef="let element"> {{element.eachOccurence?'Oui':'non'}} </td>
    </ng-container>
    <ng-container matColumnDef="destValue">
      <th mat-header-cell mat-sort-header *matHeaderCellDef> Destinataire </th>
      <td mat-cell *matCellDef="let element"> {{element.destValue}} </td>
    </ng-container>
    <ng-container matColumnDef="nbNextOccurence">
      <th mat-header-cell mat-sort-header *matHeaderCellDef> Occurences </th>
      <td mat-cell *matCellDef="let element"> {{element.nbNextOccurence}} </td>
    </ng-container>
    <ng-container matColumnDef="nbExecution">
      <th mat-header-cell mat-sort-header *matHeaderCellDef> Nbr execution </th>
      <td mat-cell *matCellDef="let element"> {{element.nbExecution}} </td>
    </ng-container>
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>  </th>
      <td mat-cell *matCellDef="let element">
        <ng-container >
        <div class="buttons">
          <div class="m-2">
            <span (click)="edit(element)">
              <i class="fa fa-pencil-square-o text-primary" style="cursor: pointer;" aria-hidden="true"
                title="Editer"></i>
            </span>
          </div>
          <div class="m-2">
            <span (click)="openDeleteConfirm(element.id)">
              <i class="fa fa-trash-o text-danger" style="cursor: pointer;" aria-hidden="true" title="Supprimer"></i>
            </span>
          </div>
        </div>
      </ng-container>
      </td>

    </ng-container>
    <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
    <ng-container matColumnDef="expandedDetail">
      <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
        <div class="example-element-detail">

        </div>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let element; columns: displayedColumns;" class="example-element-row "
      >
    </tr>
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
  </table>
</div>

