import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(public router: Router, public authenService: AuthenticationService,private modalService: NgbModal) { }

  ngOnInit() {

    if (!this.authenService.isAuthenticated()) {
      this.router.navigate(['/login']);
    } else {
      // const modalRef = this.modalService.open(AbsencesCreateComponent, { backdrop: 'static' })
      // modalRef.componentInstance.employee = employee;
      // modalRef.componentInstance.period = this.period;
      // modalRef.result.then(result => {
      // });

    }
  }
  goto(route){
    if (route){
      this.router.navigate([route]);
    }
  }

}
