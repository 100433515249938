<div role="document" class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h2 id="prep-file-heading">Créer un Vehicule</h2>
      </div>
      <div class="modal-body">
        <div class="modal-body">
          <form class="col-10" name="userCreateForm" role="form" novalidate #f="ngForm" (ngSubmit)="f.form.valid && onSubmit()"
              [formGroup]="userCreateForm">
              <ng-container *ngIf="this.authenSer.isSupperAdmin">
                <div class="form-group row ">
                  <label class="form-control-label">Saisissez un tenant :</label>
                <ng-select name="selectedVar" class="form-control"
                  dropdownPosition='bottom' formControlName="tenantId" [items]="tenants"
                  [ngClass]="{ 'forminput-invalid':f.submitted && userCreateForm?.controls?.customerId?.errors }"
                  placeHolder="Tenant" [multiple]="false" [bindLabel]="'companyName'" [closeOnSelect]="true"
                  bindValue="id" [clearable]="false">
                </ng-select>
              </div>
              </ng-container>

              <div  class="form-group row">
                <label class="form-control-label">Label</label>
                <input type="text" formControlName="label" class="form-control"
                    [ngClass]="{ 'is-invalid': f.submitted && userCreateForm?.controls?.label?.errors }" />
                <div *ngIf="f.submitted && userCreateForm?.controls?.label?.errors" class="invalid-feedback">
                    <div *ngIf="f.submitted && userCreateForm?.controls?.label?.errors">Champ obligatoire</div>
                </div>
            </div>
              
              <div  class="form-group row">
                <label class="form-control-label">Immatriculation :</label>
                <input type="text" formControlName="immatriculation" class="form-control"
                    [ngClass]="{ 'is-invalid': f.submitted && userCreateForm?.controls?.immatriculation?.errors }" />
                <div *ngIf="f.submitted && userCreateForm?.controls?.immatriculation?.errors" class="invalid-feedback">
                    <div *ngIf="f.submitted && userCreateForm?.controls?.immatriculation?.errors">Champ obligatoire</div>
                </div>
              </div>
              <div  class="form-group row">
                  <label class="form-control-label" for="holderFirstName"
                      >Prénom conducteur</label>
                  <input type="text" class="form-control" id="holderFirstName" name="holderFirstName"
                  [ngClass]="{ 'is-invalid': f.submitted && userCreateForm?.controls?.holderFirstName?.errors }"
                   formControlName="holderFirstName" />
                  <div *ngIf="f.submitted && userCreateForm?.controls?.holderFirstName?.errors " class="text-danger"> champ
                      obligatoire
                  </div>
              </div>
  
              <div  class="form-group row">
                  <label class="form-control-label" for="holderName"
                    >Nom conducteur</label>
                  <input type="text" class="form-control" id="holderName" name="holderName"
                  [ngClass]="{ 'is-invalid': f.submitted && userCreateForm?.controls?.holderName?.errors }"
                   formControlName="holderName" />
                  <div *ngIf="f.submitted && userCreateForm?.controls?.holderName?.errors " class="text-danger"> champ
                    obligatoire
                </div>
              </div>
  
               <div  class="form-group row">
  
                  <label class="form-control-label">Description</label>
                  <input type="text" formControlName="desc" class="form-control"
                      [ngClass]="{ 'is-invalid': f.submitted && userCreateForm?.controls?.desc?.errors }" />
                  <div *ngIf="f.submitted && userCreateForm?.controls?.desc?.errors" class="invalid-feedback">
                      <div *ngIf="f.submitted && userCreateForm?.controls?.desc?.errors">champ obligatoire</div>
                  </div>
  
              </div>
              <div class="form-group row align-items-center">
                <div class="custom-control custom-switch">
                 <input type="checkbox" class="custom-control-input" id="switch1" formControlName="active">
                 <label class="custom-control-label" for="switch1" >Actif</label>
               </div>
             </div>
              <div class="m-2">
                  <button type="button" id="cancel-save" class="btn btn-secondary" data-dismiss="modal"
                      (click)="close();">
                      &nbsp;<span>Annuler</span>
                  </button>
  
                  &nbsp;&nbsp;
                  <button type="submit" id="save-entity" class="btn btn-primary button titre">
                      &nbsp;<span>Valider</span>
                  </button>
              </div>
          </form>
      </div>
  </div>
  </div>
  