import { Component, OnInit, ViewChild } from '@angular/core';
import { AlertService } from '../alert-service.service';
import { DigiCodService } from '../digicod.service';
import { ActivatedRoute } from '@angular/router';
import { AccessService } from '../access.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { FormChangeService } from '../form-change.service';
import { typeEvent } from 'src/app/class/unit.enum';
import { MatPaginator } from '@angular/material/paginator';
import { merge } from 'rxjs';
import { catchError, map, startWith, switchMap, tap } from 'rxjs/operators';
import { of as observableOf } from 'rxjs/observable/of';
import { CustomDataSource } from './custom-data-source';

@Component({
  selector: 'app-acces-log',
  templateUrl: './acces-log.component.html',
  styleUrls: ['./acces-log.component.scss']
})
export class AccesLogComponent implements OnInit {
  resultsLength: any;
  isLoadingResults: boolean;
  isRateLimitReached: boolean;

constructor(private alertService: AlertService,
            private formService: FormChangeService,
            private digicodServ: DigiCodService,
            private accessService: AccessService,
            private route: ActivatedRoute) { }
  givenInstallation;
  accessLogs;
  accessLogsDataSource: CustomDataSource;
  accessId;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  displayedColumns = ['accessCode', 'dateCreation', 'type', 'reference', 'readerId'
  ];
  typeEventEnum = typeEvent;
  ngOnInit() {
    this.accessId  = this.route.snapshot.queryParams.id;
    this.route.queryParams.subscribe(params => {
      this.accessId = params.id;
    });

    this.accessLogsDataSource = new CustomDataSource(this.accessService);
    this.paginator.pageSize = 500;
    // this.paginator.pageIndex = 0;

    // this.accessLogsDataSource.loadData(this.accessId, null, this.paginator.pageIndex, this.paginator.pageSize);
    this.loadPageData();
    // this.accessLogs.sort = this.sort;
    this.subscribeformChange();
  }

  subscribeformChange() {
    this.formService.formAccessLogSearchChanged.subscribe(
      (data) => {
        this.accessLogsDataSource.loadFiltredData(data);
        // this.accessLogsDataSource.loadFiltredData(data);
        // this.accessLogs.paginator = this.paginator;
      });

  }
  getServerData($event){
    
  }
  ngAfterViewInit() {
    this.accessLogsDataSource.counter$
      .pipe(
        tap((count) => {
          this.paginator.length = count;
        })
      )
      .subscribe();

    this.sort.sortChange.subscribe(() => {this.paginator.pageIndex = 0;
      this.loadPageData()});
    this.paginator.page
      .pipe(
        tap(() => this.loadPageData())
      )
      .subscribe();

  }

  loadPageData() {
    
    if(!this.sort['field']){
      this.sort['field'] = {active: 'dateCreation',_direction: 'desc'};
    }
    
    this.accessLogsDataSource.loadData(this.accessId, this.sort, null, this.paginator.pageIndex, this.paginator.pageSize);
  }
}
