<div role="document">
	<div>
		<h2 id="prep-file-heading">Créer un Accés</h2>
	</div>

	<div class=" justify-content-center">
		<form name="editForm" role="form"
			novalidate (ngSubmit)="f.form.valid && save(f.form)"
			[formGroup]="accessCreateForm" #f="ngForm">
			<fieldset class="border p-2">
				<legend class="w-auto">Configuration</legend>
				<div class="form-group row mb-2">
					<label for="accessMode" class="col-sm-2 col-form-label">Mode</label>
						<div class="col-sm-10 ">
							<div class="form-check form-check-inline">
								<input class="form-check-input" type="radio" value="code"
									formControlName="accessMode"> <label
									class="form-check-label" for="inlineRadio1">Code</label>
							</div>
							<div class="form-check form-check-inline">
								<input class="form-check-input" type="radio" value="phone"
									formControlName="accessMode"> <label
									class="form-check-label" for="inlineRadio1">Telephone</label>
							</div>
							<div class="form-check form-check-inline">
								<input class="form-check-input" type="radio" value="badge"
									formControlName="accessMode"> <label
									class="form-check-label" for="inlineRadio1">Badge</label>
							</div>
							<div class="form-check form-check-inline">
								<input class="form-check-input" type="radio" value="vehicule"
									formControlName="accessMode"> <label
									class="form-check-label" for="inlineRadio1">Véhicule</label>
							</div>
						</div>
				</div>
				<ng-container >
					<div class="form-group row mb-2">
						<label for="equipmentIds" class="col-sm-2 col-form-label">Equipment</label>
						<div class="col-4">
							<ng-select name="equipmentIds" dropdownPosition='bottom'
								formControlName="equipmentIds"
								
						[ngClass] ="{ 'forminput-invalid':f.submitted &&
						accessCreateForm['controls']?.equipmentIds?.errors != null}"
								[items]="equipments" [multiple]="true" [bindLabel]="'label'"
								[closeOnSelect]="false" bindValue="id"
								style="padding-left: 0px;
									padding-right: 0px;">
							</ng-select>
							<div
								*ngIf="f.submitted && accessCreateForm?.controls?.equipmentIds?.errors"
								class="text-danger">champ Obligatoire</div>
						</div>
					</div>
					<ng-container *ngIf="accessCreateForm?.controls?.accessMode.value =='code'" >
						<div class="form-group row mb-2">
							<label for="digicodeIds" class="col-sm-2 col-form-label">Digicode</label>
							<div class="col-4">
								<ng-select name="digicodeIds" dropdownPosition='bottom'
									formControlName="digicodeIds"
									
							[ngClass] ="{ 'forminput-invalid':f.submitted &&
							accessCreateForm['controls']?.digicodeIds?.errors != null}"
									[items]="degicods" [multiple]="true" [bindLabel]="'label'"
									[closeOnSelect]="true" bindValue="id"
									style="padding-left: 0px;
										padding-right: 0px;">
								</ng-select>
								<div
									*ngIf="f.submitted && accessCreateForm?.controls?.digicodeIds?.errors"
									class="text-danger">champ Obligatoire</div>
							</div>
						</div>
					</ng-container>
				</ng-container>
				<ng-container *ngIf="accessCreateForm?.controls?.accessMode.value =='vehicule'" >
					<div class="form-group row mb-2">
						<label for="cameraIds" class="col-sm-2 col-form-label">Camera</label>
						<div class="col-4">
							<ng-select name="cameraIds" dropdownPosition='bottom'
								formControlName="cameraIds"
								
						[ngClass] ="{ 'forminput-invalid':f.submitted &&
						accessCreateForm['controls']?.cameraIds?.errors != null}"
								[items]="cameras" [multiple]="true" [bindLabel]="'label'"
								[closeOnSelect]="false" bindValue="id"
								style="padding-left: 0px;
									padding-right: 0px;">
							</ng-select>
							<div
								*ngIf="f.submitted && accessCreateForm?.controls?.cameraIds?.errors"
								class="text-danger">champ Obligatoire</div>
						</div>
					</div>
					<div class="form-group row mb-2">
						<label for="vehiculeIds" class="col-sm-2 col-form-label">V�hicules</label>
						<div class="col-4">
							<ng-select name="vehiculeIds" dropdownPosition='bottom'
								formControlName="vehiculeIds"
								
					  [ngClass] ="{ 'forminput-invalid':f.submitted &&
					  accessCreateForm['controls']?.vehiculeIds?.errors != null}"
								[items]="vehicules" [multiple]="true" [bindLabel]="'label'"
								[closeOnSelect]="false" bindValue="id"
								style="padding-left: 0px;
								  padding-right: 0px;">
							</ng-select>
							<div
								*ngIf="f.submitted && accessCreateForm?.controls?.vehiculeIds?.errors"
								class="text-danger">champ Obligatoire</div>
						</div>
					</div>
				</ng-container>
				<ng-container *ngIf="accessCreateForm?.controls?.accessMode.value =='badge'" >
					<div class="form-group row mb-2">
						<label for="readerIds" class="col-sm-2 col-form-label">Lecteur</label>
						<div class="col-4">
							<ng-select name="readerIds" dropdownPosition='bottom'
								formControlName="readerIds"
								
						[ngClass] ="{ 'forminput-invalid':f.submitted &&
						accessCreateForm['controls']?.readerIds?.errors != null}"
								[items]="readers" [multiple]="true" [bindLabel]="'label'"
								[closeOnSelect]="false" bindValue="id"
								style="padding-left: 0px;
									padding-right: 0px;">
							</ng-select>
							<div
								*ngIf="f.submitted && accessCreateForm?.controls?.readerIds?.errors"
								class="text-danger">champ Obligatoire</div>
						</div>
					</div>
					<div class="form-group row mb-2">
						<label for="badgeIds" class="col-sm-2 col-form-label">Badges</label>
						<div class="col-4">
							<ng-select name="badgeIds" dropdownPosition='bottom'
								formControlName="badgeIds"
								
					  [ngClass] ="{ 'forminput-invalid':f.submitted &&
					  accessCreateForm['controls']?.badgeIds?.errors != null}"
								[items]="badges" [multiple]="true" [bindLabel]="'label'"
								[closeOnSelect]="false" bindValue="id"
								style="padding-left: 0px;
								  padding-right: 0px;">
							</ng-select>
							<div
								*ngIf="f.submitted && accessCreateForm?.controls?.badgeIds?.errors"
								class="text-danger">champ Obligatoire</div>
						</div>
					</div>
				</ng-container>
				<ng-container *ngIf="accessCreateForm?.controls?.accessMode.value =='phone'" >
					<div class="form-group row mb-2">
						<label for="phone" class="col-sm-2 col-form-label">Téléphone</label>
						<div class="col-4">
							<input type="text" class="form-control" id="tel"
								name="phone" formControlName="tel" />
						</div>
					</div>
				</ng-container>
				<div class="form-group row">
					<label for="inputPassword" class="col-sm-2 col-form-label">Usage</label>
					<div class="col-sm-10">
						<div class="form-check ">
							<input class="form-check-input" type="radio" value="yes"
								formControlName="uniqueUsage"> <label
								class="form-check-label" for="inlineRadio1">Unique</label>
						</div>
						<div class="form-check form-check-inline">
							<input class="form-check-input" type="radio" value="no"
								formControlName="uniqueUsage"> <label
								class="form-check-label" for="inlineRadio2">Illimité</label>
						</div>
					</div>
				</div>
	</fieldset>

	<div class="form-group row"></div>
	
		<fieldset class="border p-2">
			<legend class="w-auto">Options</legend>
			<ng-container  *ngIf="accessCreateForm?.controls?.accessMode.value =='code'">
				<div class="form-group row mb-2">
					<label for="accessCode" class="col-sm-2 col-form-label">Code
						accés</label>
					<div class="col-4">
						<input type="text" class="form-control" id="accessCode"
							name="accessCode" formControlName="accessCode" />
					</div>
				</div>
			</ng-container>
			<div class="form-group row mb-2">
				<label for="accessCode" class="col-sm-2 col-form-label">Reference</label>
				<div class="col-4">
					<input type="text" class="form-control" id="reference"
						name="reference" formControlName="reference" />
				</div>
			</div>
			<ng-container  *ngIf="accessCreateForm?.controls?.accessMode.value =='code'">
				<div class="form-group row">
					<label for="inputPassword" class="col-2 col-form-label">Code à</label>
					<div class="col-3">
						<input type="number" class="form-control" id="digit"
							(change)="onChangePeriodEffect($event)" name="digit"
							formControlName="digit" />
					</div>chiffres
				</div>
			</ng-container>
			<div class="form-group row">
				<label class="col-2 col-form-label">Date de validité</label>
				<label for="startEffect" class="col-form-label ">De :</label>
				<div class="col-3">
					<input [owlDateTime]="dt1"
						(ngModelChange)="onChangedateEffect($event)"
						class="form-control col" formControlName="startEffect"
						[owlDateTimeTrigger]="dt1">
	
					<owl-date-time #dt1></owl-date-time>
					<div
						*ngIf="f.submitted && accessCreateForm?.controls?.startEffect?.errors?.required"
						class="text-danger">champ Obligatoire</div>
				</div>
		  <span class="col-2" >
			<button class="btn btn-success text-white btn-lg btn-circle" type="button"  (click)="refrechDateS()">
				<i class="fa fa fa-refresh" aria-hidden="true"></i>
			</button>
		  </span>
		  <label for="endEffect" class="col-form-label ">Au :</label>
				<div class="col-3">
					<input [owlDateTime]="dt2"
						(ngModelChange)="onChangedateEffect($event)"
						class="form-control col" formControlName="endEffect"
						[owlDateTimeTrigger]="dt2">
					<owl-date-time #dt2></owl-date-time>
					<div
						*ngIf="f.submitted && accessCreateForm?.controls?.endEffect?.errors?.required"
						class="text-danger">champ Obligatoire</div>
				</div>
			</div>
			<div class="form-group row">
	
				<label for="inputPassword" class="col-2 col-form-label">Durée
					(Min) :</label>
				<div class="col-3">
					<input type="number" class="form-control" id="dureeEffect"
						(change)="onChangePeriodEffect($event)" name="dureeEffect"
						formControlName="dureeEffect" />
				</div>
			</div>
		</fieldset>
		<fieldset class="border p-2">
			<legend class="w-auto">Restrictions horaires</legend>
			
			<div class="form-group row align-items-center">
						<label  class="col-2 col-form-label" for="timeStart" > Heure debut </label>
					<div class="col-3">
						<input type="time" formControlName="timeStart">
					</div>
						<label class="col-2 col-form-label" for="timeEnd" > Heure Fin </label>
					<div class="col-3">
						<input type="time" formControlName="timeEnd">
					</div>
			</div>
		</fieldset>
	<div class="m-2">
		<button type="button" id="cancel-save" class="btn btn-secondary"
			data-dismiss="modal" [routerLink]="['/editAccess']">
			&nbsp;<span>Annuler</span>
		</button>
		&nbsp;&nbsp;
		<button type="submit" id="save-entity" class="btn btn-primary">
			<span>Valider</span>
		</button>
	</div>
	</form>
</div>
</div>
