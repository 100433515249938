import { Component, OnInit } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { ReaderService } from '../services/reader.service';
import { AlertService } from '../services/alert-service.service';
import { AuthenticationService } from '../services/authentication.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormChangeService } from '../services/form-change.service';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { ModalRASComponent } from '../modal/modalRAS.component';
import { EquipmentService } from '../services/equipement.service';
import { EquipementUpdateComponent } from './equipement-update/equipement-update.component';

@Component({
  selector: 'app-equipments',
  templateUrl: './equipments.component.html',
  styleUrls: ['./equipments.component.scss']
})
export class EquipmentsComponent implements OnInit {

  constructor(public equipementService: EquipmentService,
    public alertService: AlertService,
    public authenSrv: AuthenticationService,
    private modalService: NgbModal,
    private formService: FormChangeService,
    private router: Router) { }
  rowData: any;
  rowDataFiltred;
  gridOptions : any;
  columnDefs : any;

  lstEquipements = new MatTableDataSource(new Array());
  displayedColumns = ['label','gerer','lock','open','journal'
  ];
  // innerDisplayedColumns = ['dateModification','desc',];
  expandedElement: {} | null;
  ngOnInit() {

    this.subscribeformChange();
  }
  subscribeformChange(){
    this.formService.formEquipementSearchChanged.subscribe(
      (param) => {
        this.lstEquipements = new MatTableDataSource(param);
        
      });
  }
  openDeleteConfirm(id): void {
    const modalRef = this.modalService.open(ModalRASComponent, { backdrop: 'static' });
    modalRef.componentInstance.title = "Suppresion d'un Digicod";
    modalRef.componentInstance.message = "Confirmer la suppression ?";
    modalRef.componentInstance.withCancel = true;
    modalRef.result.then(result => {
      if (result == true) {
        this.delete(id);
      }
    });
  }

  journal(installation){
    this.router.navigate(['/accessLogs'],{queryParams: {id:installation.id}});
  }
  notification(installation){
    this.router.navigate(['/notifications'],{queryParams: {id:installation.id}});
  }
  createInstallation(){
    this.router.navigateByUrl('/createInstallation');
  }
  edit(equipement){
    const modalRef = this.modalService.open(EquipementUpdateComponent, { backdrop: 'static' });
    modalRef.componentInstance.digicode = equipement;
  }

  lock(equipment){
    this.equipementService.lock(equipment.id, !equipment.locked).subscribe(
      data =>{
        let msg = "Commande ";
        msg += equipment.locked?"de desactivation ": "d'activation "
        this.alertService.success(msg+ equipment.label + " envoyée");
        // this.alertService.
        this.formService.raiseReloadEquipementsSearchRequestEvent();
      },
      error=>{
        this.alertService.error(error);
      },
      ()=>{
      }
    );
  }
  forceOpen(equipment){
    this.equipementService.forceOpen(equipment.id).subscribe(
      data =>{
        this.alertService.success("Commande d'ouverture "+ equipment.label + " envoyée");
      },
      error=>{
        this.alertService.error(error);
      },
      ()=>{
      }
    );
  }
  delete(reader){
    // this.equipementService.delete(reader.id)
    // .subscribe(
    //   () => this.onDeleteSuccess(),
    //   () => this.onDeleteError()
    // );
  }
  protected onDeleteSuccess(): void {
    this.alertService.success('Suppression effectuée.', false);
    this.formService.raiseReloadEquipementsSearchRequestEvent();
  }


  protected onDeleteError(): void {
    this.alertService.warn('Erreur de suppression.', false);
  }
  create(){

  }
}
