import { HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { AlertService } from 'src/app/services/alert-service.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { CameraService } from 'src/app/services/camera.service';
import { FormChangeService } from 'src/app/services/form-change.service';
import { TenantService } from 'src/app/services/tenant.service';
import { VehiculeService } from 'src/app/services/vehicule.service';

@Component({
  selector: 'app-camera-update',
  templateUrl: './camera-update.component.html',
  styleUrls: ['./camera-update.component.scss']
})
export class CameraUpdateComponent implements OnInit {

  userUpdateForm: FormGroup;
  tenantId;
  userForm: any;
  selectedCustomer;
  selectedTenant;
  tenants;
  vehicule: any;
  constructor(
    private fb: FormBuilder,
    private cameraService: CameraService,
    protected alertService: AlertService,
    private formService: FormChangeService,
    public authenSer: AuthenticationService,
    public activeModal: NgbActiveModal,
    private tenantServ: TenantService) { }

  ngOnInit(): void {
    this.initForm();
    this.updateForm(this.vehicule);
  }

  initForm() {
    this.userUpdateForm = this.fb.group({
      id: [],
      active: [],
      ip: [],
      label: [],
      protocol: [],
      pwd: [],
      username: [],
      login: [],
      visible: [],
    });

  }
  rechercher() {
    if(this.authenSer.isSupperAdmin){
      this.getTenantsFromBackEnd()
      .subscribe(
        data => {
          let tmp = data as Array<any>;
          this.tenants = data;
        }
        , error => {
          console.log(error);
        });
    }
  }

  getTenantsFromBackEnd() {
    return this.tenantServ.getTenants();
  }

  get f() { return this.userUpdateForm.controls; }



  private createFromForm(): any {
    return {
      id: this.userUpdateForm.get(['id']).value,
      ip: this.userUpdateForm.get(['ip']).value,
      protocol: this.userUpdateForm.get(['protocol']).value,
      label: this.userUpdateForm.get(['label'])!.value,
      pwd: this.userUpdateForm.get(['pwd'])!.value,
      login: this.userUpdateForm.get(['login'])!.value,
      username: this.userUpdateForm.get(['username'])!.value,
      visible: this.userUpdateForm.get(['visible'])!.value,
    };
  }
  // dans le cas de l'update on récupère les données pour les afficher
  updateForm(veh: any): void {
    
    this.userUpdateForm.patchValue({
      id: veh.id,
      ip: veh.ip,
      label: veh.label,
      pwd: veh.pwd,
      protocol: veh.protocol,
      username: veh.username,
      visible: veh.visible,
    });
  }


  onSubmit(): void {
    if (this.userUpdateForm.invalid) {
      return;
    } else {
      this.userForm = this.createFromForm();
      this.subscribeToSaveResponse(this.cameraService.update(this.userForm));
    }
  }

  close(): void {
    this.activeModal.close();
  }

  protected subscribeToSaveResponse(result: Observable<HttpResponse<any>>): void {
    result.subscribe(
      (result) => this.onSaveSuccess(result),
      (result) => this.onSaveError(result)
    );
  }



  protected onSaveSuccess(result): void {
    this.alertService.success('Camera sauvegardé !', false);
    this.formService.raiseReloadCamerasSearchRequestEvent();
    this.close();
  }

  protected onSaveError(result): void {
    this.alertService.error('Erreur dans le formulaire !', false);
  }

}
