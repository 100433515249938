import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from 'src/app/services/alert-service.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { InterphoneService } from '../interphone.service';

@Component({
  selector: 'app-entries-import',
  templateUrl: './entries-import.component.html',
  styleUrls: ['./entries-import.component.scss']
})
export class EntriesImportComponent implements OnInit {
  model: any = {};
  fileToUpload: File;
  userCreateForm: FormGroup;
  interphoneId;
  constructor(public authenticationService: AuthenticationService,
    private employeeService: InterphoneService,
    public alertService: AlertService,
    public router: Router,
    private fb: FormBuilder,
    public authenSer: AuthenticationService,
    public activeModal: NgbActiveModal) { }

  ngOnInit() {
    this.initForm();
  }
  initForm() {
    this.userCreateForm = this.fb.group({

      file: ['', Validators.required],

    });
  }
  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
  }
  closeModal(): void {
    this.activeModal.close();
  }
  onSubmit(): void {
      // this.userForm = this.createFromForm();
      
      this.employeeService.importEntries(this.interphoneId, this.fileToUpload)
      .subscribe(
        (data) => {
          this.alertService.success('Fichier envoyé !', true);
          this.close();
        },
        (error) =>  {
          this.alertService.error('Erreur lors du traitement du fichier ', true);
          this.close();
        }
      );
  }
  close(): void {
    this.activeModal.close();
  }
}
