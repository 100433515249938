import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';
import { Router } from '@angular/router';
import { Role } from '../class/role';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit {

  constructor(public authenService: AuthenticationService,public router: Router) {
  }

  ngOnInit() {
  }

  userLoggedIn() {
    return this.authenService.getUserLoggedIn();
  }
  logout() {

    this.authenService.logout();
  }
    get currentUser() {
      let tmp = this.authenService.getUserLoggedIn();
      return tmp;
  }
  changePass(){
     this.router.navigate(['/loginChange']);
  }

  profil(){
     this.router.navigate(['/profil']);
  }

  params(){
     this.router.navigate(['/params']);
  }
}
