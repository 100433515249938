<div role="document" class="modal-dialog">
  <div class="modal-content">
    <div class="modal-header">
      <legend _ngcontent-myl-c4="" class="w-auto">Enregistrer une carte</legend>
    </div>
    <div class="modal-body ">
      <div class="panel panel-default">
        <ng-container *ngIf="!this.cardAdded">
        <form  #f="ngForm" novalidate (ngSubmit)=" f.form.valid && pay()">
          <div class="panel-body">
            <div class="row">
                <div class="form-group col-12">
                  <label>Numérro de carte</label>
                  <div class="input-group">
                    <div class="sr-input sr-card-element form-control" id="card-element"></div>

                  </div>
                  <div class="sr-field-error text-danger" id="card-errors" role="alert"></div>
                </div>
            </div>
        </div>
        <div class="panel-footer">
          <div class="d-flex flex-row-reverse">
            <div>
            <button class="btn btn-primary" id="submit-card" [disabled]="loading">
              <i id="spinner" class="fa fa-spinner fa-pulse fa-fw" [hidden]="!loading"></i>
              <span id="button-text">Enregistrer</span>
            </button>
          </div>
          </div>
        </div>
      </form>
    </ng-container>
    <ng-container *ngIf="this.cardAdded">
      <div class="panel-body">
        <div class="d-flex align-items-center flex-column">
          <div class="center-block">
            <i class="fa fa-4x fa-check-circle-o text-success" aria-hidden="true"></i>
          </div>
          <div class="center-block">Carte Ajoutée !</div>
        </div>
      </div>
    </ng-container>
    </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="close(this.cardAdded)">Fermer
      </button>
    </div>
  </div>

</div>
