<div class=" justify-content-center">
  <div class="d-flex mb-2">
    <label style="font-weight: bold;">Carte de Credit :</label>
    <div class="ml-auto">
          <button class="btn btn-primary" (click)= "addCard()">
            <span >Ajouter</span>
          </button>
    </div>
  </div>
  <ng-container *ngFor="let card of getcards()">
          <div class="d-flex   mb-2" style="align-items: center;" >
              <div class="flex-grow-1">
              <span>
              <i class="fa fa-credit-card" aria-hidden="true"></i> {{card.brand}}
              </span>
            </div>
            <div class="col text-nowrap">
              <span class="col">
              #### #### ####  {{card.last4}}
              </span>

              <span class="col">
                {{card.expMonth+'/'+card.expYear}}
              </span>
            </div>
        </div>
      </ng-container>
      <div class="d-flex mb-2">
        <label style="font-weight: bold;">IBAN :</label>
        <div class="ml-auto">
              <button class="btn btn-primary" (click)= "addIban()">
                <span >Ajouter</span>
              </button>
        </div>
      </div>

        <ng-container *ngFor="let iban of getIbans()">
          <div class="d-flex   mb-2" style="align-items: center;" >
              <div class="flex-grow-1">
              <span>
              <i class="fa fa-university" aria-hidden="true"></i> {{iban.country}}
              </span>
            </div>
            <div class="col text-nowrap">
              <span class="col">
              {{iban.country}} {{iban.bankCode}} #### #### ####  {{iban.last4}}
              </span>
            </div>
        </div>
      </ng-container>
  </div>
