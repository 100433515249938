<div role="document" class="modal-dialog">
  <div class="modal-content">
    <div class="modal-header">
      <h2 id="prep-file-heading">{{title}} </h2>
    </div>
    <div class="modal-body">
      <ng-container *ngIf="videoSrc">
        <div class="embed-responsive embed-responsive-16by9">
            <iframe class="embed-responsive-item" [src]="videoUrl" ></iframe>
        </div>
      </ng-container>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeModal()">Fermer
      </button>
    </div>
</div>
