import { Component, OnInit, ViewChild } from '@angular/core';
import { BadgeService } from '../services/badge.service';
import { AlertService } from '../services/alert-service.service';
import { Router } from '@angular/router';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { FormChangeService } from '../services/form-change.service';
import { ModalRASComponent } from '../modal/modalRAS.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from '../services/authentication.service';
import { BadgeUpdateComponent } from './badge-update/badge-update.component';
import { BadgeCreateComponent } from './badge-create/badge-create.component';

@Component({
  selector: 'app-badges',
  templateUrl: './badges.component.html',
  styleUrls: ['./badges.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0px'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class BadgesComponent implements OnInit {
  lstBadges = new MatTableDataSource(new Array());
  displayedColumns = ['expandIcon','holder','reference','desc','label','Actions'
  ];
  expandedElement: {} | null;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(private badgeService: BadgeService,
    public alertService: AlertService,
    private formService: FormChangeService,
    public authenSer: AuthenticationService,
    private modalService: NgbModal,
    private router: Router) { }

  ngOnInit() {
    this.subscribeformChange();
    this.lstBadges.sort = this.sort;
  }
  subscribeformChange() {
    this.formService.formBadgeSearchChanged.subscribe(
      (param) => {

        this.lstBadges = new MatTableDataSource(param);

      });
  }
  gerer(equipment){
    this.router.navigate(['/gestionEquipment'],{ queryParams: {id:equipment.id}});
  }
  journal(equipment){
    this.router.navigate(['/accessLogs'],{ queryParams: { id:equipment.id}});
  }
  notification(equipment){
    this.router.navigate(['/notifications'],{ queryParams: { id:equipment.id}});
  }

  create(): void {
    const modalRef = this.modalService.open(BadgeCreateComponent, { backdrop: 'static' });
  }
  edit(badge) {
    const modalRef = this.modalService.open(BadgeUpdateComponent, { backdrop: 'static' });
    modalRef.componentInstance.badge = badge;
  }
  openDeleteConfirm(id): void {
    const modalRef = this.modalService.open(ModalRASComponent, { backdrop: 'static' });
    modalRef.componentInstance.title = "Suppresion d'un Badge";
    modalRef.componentInstance.message = "Confirmer la suppression ?";
    modalRef.componentInstance.withCancel = true;
    modalRef.result.then(result => {
      if (result == true) {
        this.delete(id);
      }
    });
  }
  delete(id) {
    this.badgeService.delete(id).subscribe(
      () => this.onDeleteSuccess(),
      () => this.onDeleteError()
    );

  }
  protected onDeleteSuccess(): void {
    this.alertService.success('Suppression effectuée.', false);
    this.formService.raiseReloadBadgesSearchRequestEvent();
  }


  protected onDeleteError(): void {
    this.alertService.warn('Erreur de suppression.', false);
  }

  onChange() {
    this.formService.raiseReloadBadgesSearchRequestEvent();
  }
}
