
<h2 id="page-heading">
  <span>Lecteur</span>
</h2>
<div class="searchForm flex-row" #myForm id="formMinAnnonce">
    <div class="flex-row">
        <div class="form-row">
            <div class="col-5">
                <label class="sr-only" for="text">Rechercher</label>
                <input (input)="onFormChange();" type="text" [(ngModel)]="searchText" class="form-control mb-2" id="text"
                    placeholder="Rechercher ">
            </div>
        </div>
    </div>
</div>
