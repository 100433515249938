import { Component, OnInit, OnChanges, Input, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, NgModel } from '@angular/forms';
import { AlertService } from '../../alert-service.service';
import { ProspectService } from '../../prospect.service';
import { FormChangeService } from '../../form-change.service';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { AccessService } from '../../access.service';
import { DigiCodService } from '../../digicod.service';

@Component({
  selector: 'app-form-min-access-log',
  templateUrl: './form-min-access-log.component.html',
  styleUrls: ['./form-min-access-log.component.scss']
})
export class FormMinAccessLogComponent implements OnInit,OnChanges {
  dateRange = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });
  typeFilter = "ALL";
  @Input() accessId: any;
  pageIndex =0;
  pageSize = 500;
  typeEvent = [
    { id: 0, code: "ALL", name: "Tout type" ,default:true},
    { id: 1, code: "EMERGENCY", name: "Code d'urgence" },
    { id: 2, code: "ACCESS_SUCCESS", name: "Accés succés" },
    {id:3, code: "ACCESS_FAILED", name: "Accés refusé généré"},
    {id:4, code: "ALREADY_CONSUMED", name: "Code réutilisé"},
    {id:5, code: "GENERATE", name: "Code généré"}
  ];
  searchText: string;
  model: NgModel;
  constructor(
    private alertService: AlertService,
    private formService: FormChangeService,
    private accessServ: AccessService,
    private digicodServ: DigiCodService,
    public router: Router) {
  }
  export(){
    // let start = this.dateRange? this.dateRange.begin: null;
    let start = moment(this.dateRange.get('start').value);
    // let end = this.dateRange? this.dateRange!.end: null;
    let end = moment(this.dateRange.get('end').value);
    this.accessServ.export(start, end).subscribe(
      resp =>{
        
        let header  = resp.headers.get('content-disposition');
        let fileName = this.parseFilenameFromContentDisposition(header);
        let data = resp.body;
        let blob = new Blob([data], {type: data.type});
        var downloadURL = window.URL.createObjectURL(blob);
        var link = document.createElement('a');
        link.href = downloadURL;
        link.download = fileName;
        link.click();
      },
      (error) => {
        console.log(error);
      }
    )
  }
  parseFilenameFromContentDisposition(contentDisposition) {
    if (!contentDisposition) return null;
    let matches = /filename="(.*?)"/g.exec(contentDisposition);

    return matches && matches.length > 1 ? matches[1] : null;
}
  ngOnChanges(changes: SimpleChanges): void {

    this.rechercher();
  }
  ngOnInit() {
    // this.rechercher();
    this.subscribeReloadSearchRequest();
  }

  subscribeReloadSearchRequest() {
    this.formService.reloadAccessLogRequest.subscribe(
      ([pageIndex, pageSize]) => {
        this.rechercher();
      });
  }
  // efface le formulaire de rechercher appelé depuis navBar
  clearForm() {
    this.rechercher();
  }
  private findTextInProspect(prospects) {
    let filter = this.getTextFilter();
    let prospectFiltred = prospects;

    if (filter) {
      let text = filter.split(' ');
      prospectFiltred = prospects.filter(function (item) {
        let flt = text.every(function (el) {
          let tmp = (item['accessCode'] && item['accessCode'].trim().toUpperCase().indexOf(el.toUpperCase()) > -1)
            || (item['reference'] && item['reference'].trim().toUpperCase().indexOf(el.toUpperCase()) > -1)
            || (item['reference'] && item['reference'].trim().toUpperCase().indexOf(el.toUpperCase()) > -1)
            || (item['readerId'] && item['readerId'].trim().toUpperCase().indexOf(el.toUpperCase()) > -1)
            || (item['type'] && item['type'].trim().toUpperCase().indexOf(el.toUpperCase()) > -1);
            return tmp;
        });
        return flt;
      });

    }
    prospectFiltred = this.filterPrspectDateRange(prospectFiltred);
    prospectFiltred = this.filterPrspectType(prospectFiltred);
    return prospectFiltred;
  }
  filterPrspectDateRange(prospectFiltred: Array<any>): any {
    let tmp = prospectFiltred;
    if(this.dateRange.get('start').value){
    let start = moment(this.dateRange.get('start').value).startOf('day');
      tmp = tmp.filter(element => {
        let dateCreation = element.dateCreation?moment(element.dateCreation): null;
        return dateCreation && dateCreation.startOf('day').isSameOrAfter(start);
      });
    }
    if(this.dateRange.get('end').value){
      let end = moment(this.dateRange.get('end').value).startOf('day');
        tmp = tmp.filter(element => {
          let dateCreation = element.dateCreation?moment(element.dateCreation): null;
          return dateCreation && dateCreation.startOf('day').isSameOrBefore(end);
        });
    }
    return tmp;
  }
  filterPrspectType(prospectFiltred: Array<any>): any {
    let tmp = prospectFiltred;
    if(this.typeFilter != "ALL"){
      tmp = tmp.filter(element => {
        return (element['type'] && element['type'].trim().toUpperCase() == this.getTypeFilter().toUpperCase())
      });

    }
    return tmp;
  }
  private getTextFilter() {
    return this.searchText;
  }
  private getTypeFilter() {
    return this.typeFilter;
  }
  rechercher() {
    this.getLogsBackEnd(this.accessId);
  }

  onFormChange() {
    this.rechercher();
  }
  compare(a: number | string, b: number ) {
    return (a < b ? -1 : 1);
  }
  getLogsBackEnd(accessId) {
    this.accessServ.getAccesLogs(accessId)
      .subscribe(
        (data) => {
          let tmpUsers = data.content as Array<any> ;
          tmpUsers = tmpUsers.sort((a, b) => {
            return this.compare(a.dateCreation, b.dateCreation);
          });
           tmpUsers = this.findTextInProspect(tmpUsers);
          this.formService.raiseAccessLogsListEvent(tmpUsers);
        }
        , (error) => {
          this.formService.raiseAccessLogsListEvent([]);
        });
  }
}
