import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class ReaderService {
  saveReader(reader: any){
    let url=environment.apiUrl+this.authenSrv.getUserApiTargetUrl()+'/readers';
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.put<any>(url,reader,{headers ,withCredentials: true});
  }
  deleteReader(readerId: any) {
    let url=environment.apiUrl+this.authenSrv.getUserApiTargetUrl()+'/readers/'+readerId;
    return this.http.delete<any>(url,{withCredentials: true});
  }
  constructor(private http: HttpClient, private authenSrv: AuthenticationService) { }
  getReadersFromBack(){
    let url=environment.apiUrl+this.authenSrv.getUserApiTargetUrl()+'/readers';
    return this.http.get<any>(url,{withCredentials: true});
  }
  getReaderByIdFromBack(id: string){
    let url=environment.apiUrl+this.authenSrv.getUserApiTargetUrl()+'/readers/'+id;
    return this.http.get<any>(url,{withCredentials: true});
  }
}
