import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';
import { Router, ActivatedRoute, Params} from '@angular/router';
import { AlertService } from '../services/alert-service.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormChangeService } from '../services/form-change.service';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  model: any = {};
  loginForm;
  constructor(
    public alertService: AlertService,
    private modalService: NgbModal,
    public authenSer: AuthenticationService,
    private formService: FormChangeService,
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder) {
    }

  ngOnInit() {
    this.loginForm = this.fb.group({
      username: new FormControl('',[Validators.required]),
      password: new FormControl('',[Validators.required]),
    });
    if (this.authenSer.isAuthenticated()) {
      if(this.authenSer.isUser){
        this.router.navigate(['/generate']);
      } else {
        this.router.navigate(['/accessLogs']);
      }

    }
  }
  login() {

    this.authenSer.login(this.loginForm.value.username, this.loginForm.value.password);
  }
}
