import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { ProspectService } from 'src/app/services/prospect.service';
import { AlertService } from 'src/app/services/alert-service.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormChangeService } from 'src/app/services/form-change.service';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { MomentPipe } from 'src/app/moment-pipe.pipe';
import { BadgeService } from 'src/app/services/badge.service';
import { TenantService } from 'src/app/services/tenant.service';
import { Observable } from 'rxjs';
import { HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-badge-update',
  templateUrl: './badge-update.component.html',
  styleUrls: ['./badge-update.component.scss']
})
export class BadgeUpdateComponent implements OnInit {

  userUpdateForm: FormGroup;
  tenantId;
  userForm: any;
  selectedCustomer;
  selectedTenant;
  tenants;
  badge: any;
  constructor(
    private fb: FormBuilder,
    private badgeSrv: BadgeService,
    protected alertService: AlertService,
    private formService: FormChangeService,
    public authenSer: AuthenticationService,
    public activeModal: NgbActiveModal,
    private tenantServ: TenantService) { }

  ngOnInit(): void {
    this.initForm();
    this.updateForm(this.badge);
    this.rechercher();
  }

  initForm() {
    this.userUpdateForm = this.fb.group({
      id: [],
      customerId: [],
      tenantId: [],
      reference: ['', Validators.required],
      label: ['', Validators.required],
    });

  }
  rechercher() {
    // let filter = this.getFilter();

    if(this.authenSer.isSupperAdmin){
      this.getTenantsFromBackEnd()
      .subscribe(
        data => {
          let tmp = data as Array<any>;
          this.tenants = data;
          // if (tmp) {
          //   if (filter) {
          //       tmp = this.findTextInCustomers(tmp, filter);
          //     }
          // }
          // this.selectedTenant = tmp;
        }
        , error => {
          console.log(error);
        });
    }
  }

  getTenantsFromBackEnd() {
    return this.tenantServ.getTenants();
  }

  get f() { return this.userUpdateForm.controls; }



  private createFromForm(): any {
    return {
      reference: this.userUpdateForm.get(['reference']).value,
      id: this.userUpdateForm.get(['id']).value,
      tenantId: this.userUpdateForm.get(['tenantId']).value,
      label: this.userUpdateForm.get(['label'])!.value,
    };
  }
  // dans le cas de l'update on récupère les données pour les afficher
  updateForm(user: any): void {
    
    this.userUpdateForm.patchValue({
      id: user.id,
      tenantId: user.tenantId,
      label: user.label,
      reference: user.reference,
    });
    // this.customerId = user.customerId;
    // this.selectedTenant = user.tenantId;
    this.tenantId = user.tenantId;
  }


  onSubmit(): void {
    if (this.userUpdateForm.invalid) {
      return;
    } else {
      this.userForm = this.createFromForm();
      this.subscribeToSaveResponse(this.badgeSrv.update(this.userForm));

    }

  }

  private findTextInCustomers(customers, text) {
    text = text?text.split(' '):'';
    return customers.filter(function (item) {

      return text.every(function (el) {
        return item['companyName'].toUpperCase().indexOf(el.toUpperCase()) > -1
          || item['siret'].toUpperCase().indexOf(el.toUpperCase()) > -1;
      });
    });
  }

  close(): void {
    this.activeModal.close();
  }

  protected subscribeToSaveResponse(result: Observable<HttpResponse<any>>): void {
    result.subscribe(
      (result) => this.onSaveSuccess(result),
      (result) => this.onSaveError(result)
    );
  }



  protected onSaveSuccess(result): void {
    this.alertService.success('Badge sauvegardé !', false);
    this.close();
    this.formService.raisereloadSearchRequestUserEvent();
  }

  protected onSaveError(result): void {
    this.alertService.error('Erreur dans le formulaire !', false);
  }

}


