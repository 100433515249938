import { HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { AlertService } from 'src/app/services/alert-service.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { DigiCodService } from 'src/app/services/digicod.service';
import { FormChangeService } from 'src/app/services/form-change.service';
import { ReaderService } from 'src/app/services/reader.service';

@Component({
  selector: 'app-reader-update',
  templateUrl: './reader-update.component.html',
  styleUrls: ['./reader-update.component.scss']
})
export class ReaderUpdateComponent implements OnInit {

  readerUpdateForm: FormGroup;
  submitted = false;
  userForm: any;
  digicode: any;
  constructor(
    protected readerServ: ReaderService,
    private fb: FormBuilder,
    protected alertService: AlertService,
    private formService: FormChangeService,
    public authenSer: AuthenticationService,
    public activeModal: NgbActiveModal,) { }

  ngOnInit(): void {
    this.initForm();
    this.updateForm(this.digicode);
  }

  initForm() {
    this.readerUpdateForm = this.fb.group({
      id: [],
      label: [],
      tenantId: [],
      active: [],
      gatewayId: [],
    });

  }

  get f() { return this.readerUpdateForm.controls; }

  private createFromForm(): any {
    return {
      label: this.readerUpdateForm.get(['label']).value,
      // desc: this.readerUpdateForm.get(['desc']).value,
      active: this.readerUpdateForm.get(['active']).value,
      gatewayId: this.digicode.gatewayId,
      id: this.digicode.id,
    };
  }
  updateForm(digic: any): void {

    this.readerUpdateForm.patchValue({
      id: digic.id,
      // desc: digic.desc,
      label: digic.label,
      active: digic.active,
      gatewayId: digic.gatewayId,
    });

  }

  onSubmit(): void {
      this.userForm = this.createFromForm();
      this.subscribeToSaveResponse(this.readerServ.saveReader(this.userForm));
  }

  close(): void {
    this.activeModal.close();
  }

  protected subscribeToSaveResponse(result: Observable<HttpResponse<any>>): void {
    result.subscribe(
      (result) => this.onSaveSuccess(result),
      (result) => this.onSaveError(result)
    );
  }



  protected onSaveSuccess(result): void {
    this.alertService.success('Lecteur sauvegardé !', true);
    this.formService.raiseReloadReadersSearchRequestEvent();
    this.close();
  }

  protected onSaveError(result): void {
    this.alertService.error('Erreur lors de la modification lecteur !', true);
  }

}
