import { HttpResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { MomentPipe } from 'src/app/moment-pipe.pipe';
import { AlertService } from 'src/app/services/alert-service.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { FormChangeService } from 'src/app/services/form-change.service';
import { TenantService } from 'src/app/services/tenant.service';
import { UserService } from 'src/app/services/user-service';
import { InterphoneService } from '../interphone.service';
import { ModalRASComponent } from 'src/app/modal/modalRAS.component';
import { EntryCreateComponent } from '../entry-create/entry-create.component';
import { EntryUpdateComponent } from '../entry-update/entry-update.component';
import { EntriesImportComponent } from '../entries-import/entries-import.component';
import { elementAt } from 'rxjs-compat/operator/elementAt';

@Component({
  selector: 'app-interphone-update',
  templateUrl: './interphone-update.component.html',
  styleUrls: ['./interphone-update.component.scss']
})
export class InterphoneUpdateComponent implements OnInit {

  userUpdateForm: FormGroup;
  submitted = false;
  searchText;
  customers: {};
  customerId;
  tenantId;
  selectedCivilite: string = null;
  emailInvalid: boolean;
  numSsInvalid: boolean;
  valeurAcorriger = [];
  userForm: any;
  selectedCustomer;
  selectedTenant;
  selectedRoles = new Array();
  roles = new Array();
  tenants: Object;
  interphone: any;
  displayedColumns;
  entries;
  expandedElement: {} | null;
  constructor(protected interphoneSrv: InterphoneService,
    protected userService: UserService,
    private fb: FormBuilder,
    public authenSrv: AuthenticationService,
    private modalService: NgbModal,
    protected alertService: AlertService,
    private formService: FormChangeService,
    public authenSer: AuthenticationService,
    public activeModal: NgbActiveModal,
    private route: Router,
    private tenantServ: TenantService,) {
      this.interphone = this.route.getCurrentNavigation().extras.state?this.route.getCurrentNavigation().extras.state.interphone:null;

    }

  ngOnInit(): void {
    this.displayedColumns = ['name','tel1','tel2', 'Actions'];
    if(this.authenSer.isAdmin){
      this.displayedColumns.push('Actions');
    }
    this.initForm();
    this.updateForm(this.interphone);
    this.rechercherTenants();
    this.rechercherEntries();
  }

  initForm() {
    this.userUpdateForm = this.fb.group({
      id: [],
      tenantId: ['', Validators.required],
      label: ['', Validators.required],
      cp: ['', Validators.required],
      city: ['', Validators.required],
      callNumber: ['', Validators.required],
      adress: ['', [Validators.required]],
      username: [''],
      password:[''],
      hostUrl:['']
    });

  }
  rechercherEntries(){
    this.interphoneSrv.getInterphoneEntries(this.interphone.id).subscribe(
      (data) =>{
        this.entries = new MatTableDataSource(data);
      },
      (error) => {
        this.entries = new MatTableDataSource([]);
      }
    );

  }

  rechercherTenants() {
    if(this.authenSer.isSupperAdmin){
      this.getTenantsFromBackEnd()
      .subscribe(
        data => {
          let tmp = data as Array<any>;
          this.tenants = data;
        }
        , error => {
          console.log(error);
        });
    }
  }

  getTenantsFromBackEnd() {
    return this.tenantServ.getTenants();
  }
  get f() { return this.userUpdateForm.controls; }

  private createFromForm(): any {
    return {
      label: this.userUpdateForm.get(['label']).value,
      id: this.userUpdateForm.get(['id']).value,
      tenantId: this.userUpdateForm.get(['tenantId']).value,
      cp: this.userUpdateForm.get(['cp'])!.value,
      city: this.userUpdateForm.get(['city'])!.value,
      callNumber: this.userUpdateForm.get(['callNumber'])!.value,
      adress: this.userUpdateForm.get(['adress'])!.value,
      username: this.userUpdateForm.get(['username'])!.value,
      password: this.userUpdateForm.get(['password'])!.value,
      hostUrl: this.userUpdateForm.get(['hostUrl'])!.value
    };
  }
  // dans le cas de l'update on récupère les données pour les afficher
  updateForm(interphone: any): void {
    this.userUpdateForm.patchValue({
      id: interphone.id,
      label: interphone.label,
      tenantId: interphone.tenantId,
      cp: interphone.cp,
      city: interphone.city,
      callNumber: interphone.callNumber,
      adress: interphone.adress,
      active: interphone.active,
      username: interphone.username,
      password: interphone.password,
      hostUrl: interphone.hostUrl
    });
  }

  importEntry(){
    const modalRef = this.modalService.open(EntriesImportComponent, { backdrop: 'static' });
    modalRef.componentInstance.interphoneId = this.interphone.id;
    modalRef.result.then(result => {
      if (result == true) {
        this.formService.raiseInterphonesSearchRequestEvent();
      }
    });
  }
  createEntry(){
    const modalRef = this.modalService.open(EntryCreateComponent, { backdrop: 'static' });
    modalRef.componentInstance.interphone = this.interphone;
    modalRef.result.then(result => {
        this.interphoneSrv.getInterphoneEntries(this.interphone.id).subscribe(
          data => {
            this.entries = new MatTableDataSource(data);

          },
          error => {
            console.log('error'+ error);
          }
        );
    });
  }
  save(): void {
    this.submitted = true;
    if (this.userUpdateForm.invalid) {
      return;
    } else {
      this.userForm = this.createFromForm();
      debugger
      this.subscribeToSaveResponse(this.interphoneSrv.saveInterphone(this.userForm));
      this.route.navigateByUrl('/interphones');
    }
  }
  close(): void {
    this.activeModal.close();
  }

  protected subscribeToSaveResponse(result: Observable<HttpResponse<any>>): void {
    result.subscribe(
      (result) => this.onSaveSuccess(result),
      (result) => this.onSaveError(result)
    );
  }



  protected onSaveSuccess(result): void {
    this.alertService.success('Interphone sauvegardé !', false);
    this.close();
    this.formService.raisereloadSearchRequestUserEvent();
  }

  protected onSaveError(result): void {
    this.alertService.error('Erreur dans le formulaire !', false);
  }

  openDeleteConfirm(id): void {
    const modalRef = this.modalService.open(ModalRASComponent, { backdrop: 'static' });
    modalRef.componentInstance.title = "Suppression d'entrée  interphone";
    modalRef.componentInstance.message = "Confirmer la suppression ?";
    modalRef.componentInstance.withCancel = true;
    modalRef.result.then(result => {
      if (result == true) {
        this.deleteInterphoneEntry(id);
      }
    });
  }
  editEntry(element){
    const modalRef = this.modalService.open(EntryUpdateComponent, { backdrop: 'static' });
    modalRef.componentInstance.interphoneEntry = element;
    modalRef.result.then(result => {
        this.interphoneSrv.getInterphoneEntries(this.interphone.id).subscribe(
          data => {
            this.entries = new MatTableDataSource(data);

          },
          error => {
            console.log('error'+ error);
          }
        );
    });
  }
  addInterphoneEntry(){
    const modalRef = this.modalService.open(EntryCreateComponent, { backdrop: 'static' });
    modalRef.componentInstance.interphone = this.interphone;
    modalRef.result.then(result => {
        // this.interphoneSrv.createInterphoneEntry(result).subscribe(
          this.interphoneSrv.getInterphoneEntries(this.interphone.id).subscribe(
            data => {
              this.entries = new MatTableDataSource(data);

            },
            error => {
              console.log('error'+ error);
            }
        );
    });
  }
  deleteInterphoneEntry(idTenant) {
      this.interphoneSrv.deleteInterphoneEntry(idTenant)
      .subscribe(
        (result) => {
          this.alertService.success('Contact supprimé !', true);
          this.interphoneSrv.getInterphoneEntries(this.interphone.id).subscribe(
            data => {
              this.entries = new MatTableDataSource(data);

            },
            error => {
              console.log('error'+ error);
            }
          );
        },
        (result) => {
          this.alertService.error('Erreur', true);
        }
      );

  }
}
