import { Component, OnInit } from '@angular/core';
import { AlertService } from '../services/alert-service.service';
import { Router } from '@angular/router';
import { ReaderService } from '../services/reader.service';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { MatTableDataSource } from '@angular/material/table';
import { ModalRASComponent } from '../modal/modalRAS.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from '../services/authentication.service';
import { FormChangeService } from '../services/form-change.service';
import { ReaderUpdateComponent } from './reader-update/reader-update.component';

@Component({
  selector: 'app-readers',
  templateUrl: './readers.component.html',
  styleUrls: ['./readers.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0px'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class ReadersComponent implements OnInit {

  constructor(public readerService: ReaderService,
    public alertService: AlertService,
    public authenSrv: AuthenticationService,
    private modalService: NgbModal,
    private formService: FormChangeService,
    private router: Router) { }
  rowData: any;
  rowDataFiltred;
  gridOptions : any;
  columnDefs : any;
  displayedColumns: Array<string>;
  lstReaders = new MatTableDataSource(new Array());

  expandedElement: {} | null;
  ngOnInit() {
    this.displayedColumns = ['label'];
    if (this.authenSrv.isSupperAdmin){
      this.displayedColumns.push('gerer');
    }
    this.displayedColumns.push('notification','journal');
    this.subscribeformChange();
  }
  edit(digicod){
    const modalRef = this.modalService.open(ReaderUpdateComponent, { backdrop: 'static' });
    modalRef.componentInstance.digicode = digicod;
  }

  subscribeformChange(){
    this.formService.formReadersSearchChanged.subscribe(
      (param) => {

        this.lstReaders = new MatTableDataSource(param);

      });
  }
  openDeleteConfirm(id): void {
    const modalRef = this.modalService.open(ModalRASComponent, { backdrop: 'static' });
    modalRef.componentInstance.title = "Suppresion d'un Digicod";
    modalRef.componentInstance.message = "Confirmer la suppression ?";
    modalRef.componentInstance.withCancel = true;
    modalRef.result.then(result => {
      if (result == true) {
        this.delete(id);
      }
    });
  }

  openNotifications(installation){
    this.router.navigate(['/notifsDigicod'],{queryParams: {id:installation.id}});
  }

  journal(installation){
    this.router.navigate(['/accessLogs'],{queryParams: {id:installation.id}});
  }
  notification(installation){
    this.router.navigate(['/notifications'],{queryParams: {id:installation.id}});
  }
  createInstallation(){
    this.router.navigateByUrl('/createInstallation');
  }

  forceOpen(installation){

  }
  delete(reader){
    this.readerService.deleteReader(reader.id)
    .subscribe(
      () => this.onDeleteSuccess(),
      () => this.onDeleteError()
    );
  }
  protected onDeleteSuccess(): void {
    this.alertService.success('Suppression effectuée.', false);
    this.formService.raiseReloadReadersSearchRequestEvent();
  }


  protected onDeleteError(): void {
    this.alertService.warn('Erreur de suppression.', false);
  }
  create(){

  }
}
